import React from 'react';

import { toAbsoluteUrl } from '../../../../../../_metronic/_helpers';

export function CashWithdrawResult(props) {
    return (
        <div className='row justify-content-center mb-7'>
            <div className='col-lg-8 col-12 p-0'>
                {Object.keys(props.withdrawres).length > 0 ? (
                    <div>
                        {props.withdrawres.responsecode === 0 ? (
                            <div className='col-12 p-0'>
                                <div className='text-center'>
                                    <img
                                        className='max-h-200px'
                                        alt='success-icon'
                                        src={toAbsoluteUrl('/media/assets/register/success-icon.png')}
                                    />
                                    <h2 className='mt-7'>ดำเนินการถอนเงินเรียบร้อย</h2>
                                    {/* <p className='m-0'>ขอบคุณที่เข้าร่วมเป็นสมาชิกกลุ่มเครดิตยูเนี่ยนครอบครัวซิมปันสุข</p>
                                <p>
                                    ท่านสามารถเข้าสู่ระบบได้ที่<Link to='/auth/login'>หน้าแรก</Link>
                                </p> */}
                                </div>
                            </div>
                        ) : (
                            <div className='col-12 p-0'>
                                <div className='text-center'>
                                    <img
                                        className='max-h-200px'
                                        alt='failed-icon'
                                        src={toAbsoluteUrl('/media/assets/register/failed-icon.png')}
                                    />
                                    <h2 className='mt-7'>ไม่สามารถถอนเงินได้</h2>
                                    <p className='m-0'>
                                        {props.withdrawres.responsecode === 104 ? (
                                            'จำนวนเงินไม่เพียงพอสำหรับการถอน'
                                        ) : props.withdrawres.responsecode === 105 ? (
                                            'จำนวนเงินเกินกำหนดสำหรับการถอน'
                                        ) : (
                                            'มีบางอย่างผิดพลาด'
                                        )}
                                    </p>
                                    <p>
                                        กรุณาติดต่อเจ้าหน้าที่ ที่
                                        <a
                                            href={`https://line.me/ti/p/~@dealersim`}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            เจ้าหน้าที่
                                        </a>
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className='col-12 p-0'>
                        <div className='text-center'>
                            <img
                                className='max-h-200px'
                                alt='failed-icon'
                                src={toAbsoluteUrl('/media/assets/register/failed-icon.png')}
                            />
                            <h2 className='mt-7'>ไม่สามารถถอนเงินได้</h2>
                            <p className='m-0'>มีบางอย่างผิดพลาด</p>
                            <p>
                                กรุณาติดต่อเจ้าหน้าที่ ที่
                                <a href={`https://line.me/ti/p/~@dealersim`} target='_blank' rel='noopener noreferrer'>
                                    เจ้าหน้าที่
                                </a>
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
