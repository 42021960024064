import React from 'react';

export function CashWithdrawForm(props) {
    return (
        <div>
            <div className='text-center mb-10 mb-lg-20'>
                <h3 className='font-size-h1'>ถอนเงิน</h3>
            </div>
            <div className='row justify-content-center m-0 mb-10'>
                <div className='col-lg-8 bg-white p-5 rounded-lg'>
                    <div className='form-group row mx-0'>
                        <label className='col-form-label col-lg-3'>จำนวนเงิน</label>
                        <div className='col-lg-9'>
                            <div className='input-group'>
                                <input
                                    type='text'
                                    inputMode='tel'
                                    className='form-control'
                                    value={props.amount}
                                    onChange={(e) => props.setAmount(e.target.value)}
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            if (event.key !== 'Enter') {
                                                event.preventDefault();
                                            }
                                        }
                                    }}
                                />
                                <div className='input-group-append'>
                                    <span className='input-group-text'>บาท</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='title p-2 font-size-h6 text-center'>ข้อมูลธนาคารของคุณ</div>
                    <div className='form-group row mx-0'>
                        <label className='col-form-label col-3'>ธนาคาร</label>
                        <div className='col-9 form-control border-0 text-right'>
                            {props.banklist[props.info.info.bank_code]}
                        </div>
                    </div>
                    <div className='form-group row mx-0'>
                        <label className='col-form-label col-3'>เลขที่บัญชี</label>
                        <div className='col-9 form-control border-0 text-right'>{props.info.info.bank_acc_no}</div>
                    </div>
                    <div className='form-group row mx-0'>
                        <label className='col-form-label col-3'>ชื่อบัญชี</label>
                        <div className='col-9 form-control border-0 text-right'>{props.info.info.bank_acc_name}</div>
                    </div>
                    <div className='form-group d-flex flex-wrap flex-center mt-10'>
                        <button
                            type='button'
                            className='btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4 rounded-lg'
                            onClick={props.btnNext}
                            disabled={props.btnloading}
                        >
                            {props.btnloading && <i className='fas fa-circle-notch fa-spin mr-3' />}
                            <span>ถัดไป</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
