import React from 'react';

import Countdown from 'react-countdown';

import OtpInput from 'react-otp-input';

export function CremationRegisterOtp(props) {
    // console.log(props.expiretime);
    return (
        <>
            <div className='row'>
                <div className='col-12 text-center'>
                    <h4>กรุณากรอกรหัส OTP ที่ส่งไปยังเบอร์ที่ต้องการสมัครสมาชิก</h4>
                    <h6>(Ref : {props.refcode})</h6>
                </div>
                <div className='col-12 row justify-content-center m-0 p-0 py-15'>
                    <OtpInput
                        value={props.otpcode}
                        onChange={props.handleOtp}
                        numInputs={6}
                        isInputNum={true}
                        containerStyle='col-12 row justify-content-center form-group digit-group'
                    />
                    {/* <p>กรุณาลองใหม่ในอีก</p> */}
                    <Countdown date={props.expiretime} renderer={props.renderer} />
                    {/* {!props.loading && <Countdown date={props.expiretime} renderer={props.renderer} />} */}
                </div>
            </div>
            <div className='form-group d-flex flex-wrap flex-center mt-10'>
                <button
                    type='button'
                    className='btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4 rounded-lg'
                    onClick={props.btnNext}
                    disabled={props.disable}
                >
                    <span>ชำระเงิน</span>
                </button>
            </div>
        </>
    );
}
