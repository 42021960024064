import React from 'react';

export function FundOrderForm(props) {
    return (
        <div>
            <div className='row justify-content-center mb-10'>
                <div className='col-lg-5'>
                    <div className='form-group'>
                        <label>จำนวนเงิน</label>
                        <div className='input-group'>
                            <input
                                type='text'
                                inputMode='tel'
                                className='form-control'
                                value={props.amount}
                                onChange={(e) => props.setAmount(e.target.value)}
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        if (event.key !== 'Enter') {
                                            event.preventDefault();
                                        }
                                    }
                                }}
                            />
                            <div className='input-group-append'>
                                <span className='input-group-text'>บาท</span>
                            </div>
                        </div>
                    </div>
                    {/* <div className='d-flex col-xl-12 p-0'>
                                            <div className='p-2'>ราคาหุ้น</div>
                                            <div className='ml-auto p-2 text-right'>
                                                <NumberFormat
                                                    value={parseFloat(price * amount)}
                                                    displayType={'text'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}
                                                    suffix={' บาท'}
                                                />
                                            </div>
                                        </div> */}
                    <div className='title p-2'>วิธีการชำระเงิน</div>
                    <div className='row d-flex justify-content-center text-center mx-auto mb-5'>
                        <div className='payment-icon col-xl-5 col-6 d-inline'>
                            <label>
                                <img
                                    alt='thaiqr-logo'
                                    src='https://cdn.simk4.com/payment/thatqr.png'
                                    className={`payment-img${props.paymenttype === '1'
                                        ? '-active'
                                        : ''} max-h-120px max-w-120px`}
                                />
                                <input
                                    type='radio'
                                    name='payment-method'
                                    value='1'
                                    checked={props.paymenttype === '1'}
                                    onChange={() => props.setPaymenttype('1')}
                                    // className="ng-untouched ng-pristine ng-valid"
                                />
                            </label>
                        </div>
                        <div className='payment-icon col-xl-5 col-6 d-inline'>
                            <label>
                                <img
                                    alt='wallet-logo'
                                    src='https://cdn.simk4.com/payment/credit_card.png'
                                    className={`payment-img${props.paymenttype === '3'
                                        ? '-active'
                                        : ''} max-h-120px max-w-120px`}
                                />
                                <input
                                    type='radio'
                                    name='payment-method'
                                    value='3'
                                    checked={props.paymenttype === '3'}
                                    onChange={() => props.setPaymenttype('3')}
                                />
                            </label>
                        </div>
                    </div>
                    {/* <div className='form-group d-flex flex-wrap flex-center mt-10'>
                        <button
                            type='button'
                            className='btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4 rounded-lg'
                            onClick={props.btnNext}
                        >
                            <span>ชำระเงิน</span>
                        </button>
                    </div> */}
                    <div className='form-group d-flex flex-wrap flex-center mt-10'>
                        ไม่สามารถซื้อขายได้ในขณะนี้
                    </div>
                </div>
            </div>
        </div>
    );
}
