/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers';

export function AsideMenuList({ layoutProps }) {
    const { info } = useSelector((state) => state.auth);
    const [ disable, setDisable ] = useState(false);
    const [ morefunc, setMorefunc ] = useState(false);
    const [ bank, setBank ] = useState(true);
    const location = useLocation();
    useEffect(
        () => {
            if (info.group.role_id === 3 || info.group.role_id === 4) {
                setDisable(true);
                if (info.group.role_id === 4) {
                    setMorefunc(true);
                }
            }
            if (info.info.bank_code !== '' && info.info.bank_acc_no !== '' && info.info.bank_acc_name !== '') {
                setBank(false);
            }
        },
        [ location ]
    );

    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url) ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open ` : '';
    };

    const isToday = (date) => {
        const today = new Date();
        // console.log(today.toDateString());
        // console.log(date.toDateString());
        if (today.getFullYear() >= date.getFullYear() ||
        today.getMonth() >= date.getMonth() ||
        today.getDate() >= date.getDate()) {
            // console.log('true');
            return true;
        }
        // console.log('false');
        return false;
    }

    return (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {/*begin::1 Level*/}
            <li className={`menu-item ${getMenuItemActive('/user/information', false)}`} aria-haspopup='true'>
                <NavLink className='menu-link' to='/user/information'>
                    <span className='svg-icon menu-icon'>
                        <SVG src={toAbsoluteUrl('/media/svg/icons/General/User.svg')} />
                    </span>
                    <span className='menu-text'>ข้อมูลสมาชิก</span>
                </NavLink>
            </li>
            {/*end::1 Level*/}

            {/*begin::1 Level*/}
            <li
                className={`menu-item menu-item-submenu ${getMenuItemActive('/cooperative', true)} ${getMenuItemActive(
                    '/fund',
                    true
                )}`}
                aria-haspopup='true'
                data-menu-toggle='hover'
            >
                <NavLink className='menu-link menu-toggle' to='/cooperative'>
                    <span className='svg-icon menu-icon'>
                        <SVG title='สหกรณ์' src={toAbsoluteUrl('/media/svg/icons/Home/Building.svg')} />
                    </span>
                    <span className='menu-text'>สหกรณ์</span>
                    <i className='menu-arrow' />
                </NavLink>
                <div className='menu-submenu '>
                    <i className='menu-arrow' />
                    <ul className='menu-subnav'>
                        <li className='menu-item  menu-item-parent' aria-haspopup='true'>
                            <span className='menu-link'>
                                <span className='menu-text'>สหกรณ์</span>
                            </span>
                        </li>

                        {/*begin::2 Level*/}
                        <li
                            className={`menu-item ${getMenuItemActive('/cooperative/stockorder')}`}
                            aria-haspopup='true'
                        >
                            <NavLink className='menu-link' to='/cooperative/stockorder'>
                                <span className='svg-icon menu-icon'>
                                    <SVG title='ซื้อหุ้น' src={toAbsoluteUrl('/media/svg/icons/Shopping/Money.svg')} />
                                </span>
                                <span className='menu-text'>ซื้อหุ้น</span>
                            </NavLink>
                        </li>
                        {/*end::2 Level*/}

                        {/*begin::2 Level*/}
                        <li
                            className={`menu-item ${getMenuItemActive('/cooperative/stockreport')}`}
                            aria-haspopup='true'
                        >
                            <NavLink className='menu-link' to='/cooperative/stockreport'>
                                <span className='svg-icon menu-icon'>
                                    <SVG
                                        title='รายละเอียดหุ้น'
                                        src={toAbsoluteUrl('/media/svg/icons/Communication/Clipboard-list.svg')}
                                    />
                                </span>
                                <span className='menu-text'>รายละเอียดหุ้น</span>
                            </NavLink>
                        </li>
                        {/*end::2 Level*/}

                        {/*begin::2 Level*/}
                        <li
                            className={`menu-item ${getMenuItemActive('/cooperative/orderreport')}`}
                            aria-haspopup='true'
                        >
                            <NavLink className='menu-link' to='/cooperative/orderreport'>
                                <span className='svg-icon menu-icon'>
                                    <SVG
                                        title='ข้อมูลการทำรายการ'
                                        src={toAbsoluteUrl('/media/svg/icons/Text/Bullet-list.svg')}
                                    />
                                </span>
                                <span className='menu-text'>ข้อมูลการทำรายการ</span>
                            </NavLink>
                        </li>
                        {/*end::2 Level*/}

                        {/*begin::2 Level*/}
                        <li className={`menu-item ${getMenuItemActive('/fund/deposit')}`} aria-haspopup='true'>
                            <NavLink className='menu-link' to='/fund/deposit'>
                                <span className='svg-icon menu-icon'>
                                    <SVG
                                        title='ซื้อหน่วยลงทุน'
                                        src={toAbsoluteUrl('/media/svg/icons/Shopping/Money.svg')}
                                    />
                                </span>
                                <span className='menu-text'>ซื้อหน่วยลงทุน</span>
                            </NavLink>
                        </li>
                        {/*end::2 Level*/}

                        {/*begin::2 Level*/}
                        <li
                            className={`menu-item ${getMenuItemActive('/fund/report')}`}
                            aria-haspopup='true'
                        >
                            <NavLink className='menu-link' to='/fund/report'>
                                <span className='svg-icon menu-icon'>
                                    <SVG
                                        title='รายละเอียดหน่วยลงทุน'
                                        src={toAbsoluteUrl('/media/svg/icons/Communication/Clipboard-list.svg')}
                                    />
                                </span>
                                <span className='menu-text'>รายละเอียดหน่วยลงทุน</span>
                            </NavLink>
                        </li>
                        {/*end::2 Level*/}

                        {/*begin::2 Level*/}
                        <li
                            className={`menu-item ${getMenuItemActive('/fund/orderreport')}`}
                            aria-haspopup='true'
                        >
                            <NavLink className='menu-link' to='/fund/orderreport'>
                                <span className='svg-icon menu-icon'>
                                    <SVG
                                        title='ข้อมูลการทำรายการหน่วยลงทุน'
                                        src={toAbsoluteUrl('/media/svg/icons/Text/Bullet-list.svg')}
                                    />
                                </span>
                                <span className='menu-text'>ข้อมูลการทำรายการหน่วยลงทุน</span>
                            </NavLink>
                        </li>
                        {/*end::2 Level*/}

                        {/*begin::2 Level*/}
                        <li className={`menu-item ${getMenuItemActive('/fund2206001/deposit')}`} aria-haspopup='true'>
                            <NavLink className='menu-link' to='/fund2206001/deposit'>
                                <span className='svg-icon menu-icon'>
                                    <SVG
                                        title='ซื้อหน่วยลงทุนพิเศษ'
                                        src={toAbsoluteUrl('/media/svg/icons/Shopping/Money.svg')}
                                    />
                                </span>
                                <span className='menu-text'>ซื้อหน่วยลงทุนพิเศษ</span>
                            </NavLink>
                        </li>
                        {/*end::2 Level*/}

                        {/*begin::2 Level*/}
                        <li
                            className={`menu-item ${getMenuItemActive('/fund2206001/report')}`}
                            aria-haspopup='true'
                        >
                            <NavLink className='menu-link' to='/fund2206001/report'>
                                <span className='svg-icon menu-icon'>
                                    <SVG
                                        title='รายละเอียดหน่วยลงทุนพิเศษ'
                                        src={toAbsoluteUrl('/media/svg/icons/Communication/Clipboard-list.svg')}
                                    />
                                </span>
                                <span className='menu-text'>รายละเอียดหน่วยลงทุนพิเศษ</span>
                            </NavLink>
                        </li>
                        {/*end::2 Level*/}

                        {/*begin::2 Level*/}
                        <li
                            className={`menu-item ${getMenuItemActive('/fund2206001/orderreport')}`}
                            aria-haspopup='true'
                        >
                            <NavLink className='menu-link' to='/fund2206001/orderreport'>
                                <span className='svg-icon menu-icon'>
                                    <SVG
                                        title='ข้อมูลการทำรายการหน่วยลงทุนพิเศษ'
                                        src={toAbsoluteUrl('/media/svg/icons/Text/Bullet-list.svg')}
                                    />
                                </span>
                                <span className='menu-text'>ข้อมูลการทำรายการหน่วยลงทุนพิเศษ</span>
                            </NavLink>
                        </li>
                        {/*end::2 Level*/}
                    </ul>
                </div>
            </li>
            {/*end::1 Level*/}

            {/*begin::1 Level*/}
            <li
                className={`menu-item menu-item-submenu ${getMenuItemActive('/cremation', true)}`}
                aria-haspopup='true'
                data-menu-toggle='hover'
            >
                <NavLink className='menu-link menu-toggle' to='/cremation'>
                    <span className='svg-icon menu-icon'>
                        <SVG title='ฌาปนกิจ' src={toAbsoluteUrl('/media/svg/icons/Communication/Group.svg')} />
                    </span>
                    <span className='menu-text'>ฌาปนกิจ</span>
                    <i className='menu-arrow' />
                </NavLink>
                <div className='menu-submenu '>
                    <i className='menu-arrow' />
                    <ul className='menu-subnav'>
                        <li className='menu-item  menu-item-parent' aria-haspopup='true'>
                            <span className='menu-link'>
                                <span className='menu-text'>ฌาปนกิจ</span>
                            </span>
                        </li>

                        {/*begin::2 Level*/}
                        {disable ? (
                            <>
                                {info.group.role_id === 3 && (
                                    // <li
                                    //     className={`menu-item ${getMenuItemActive("/cremation/qrcode")}`}
                                    //     aria-haspopup="true"
                                    // >
                                    //     <NavLink className="menu-link" to="/cremation/qrcode">
                                    //         <span className="svg-icon menu-icon">
                                    //             <SVG title="สมัครสมาชิก" src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}/>
                                    //         </span>
                                    //         <span className="menu-text">สมัครสมาชิก</span>
                                    //         <span className="menu-label">
                                    //             <span className="label label-warning label-inline">
                                    //                 รอการชำระเงิน
                                    //             </span>
                                    //         </span>
                                    //     </NavLink>
                                    // </li>
                                    <li className='menu-item disabled-link cursor-disable' aria-haspopup='true'>
                                        <div className='menu-link disable-target cursor-disable'>
                                            <span className='svg-icon menu-icon'>
                                                <SVG
                                                    title='สมัครสมาชิก'
                                                    src={toAbsoluteUrl('/media/svg/icons/Communication/Write.svg')}
                                                />
                                            </span>
                                            <span className='menu-text'>สมัครสมาชิก</span>
                                            <span className='menu-label'>
                                                <span className='label label-warning label-inline'>รอการชำระเงิน</span>
                                            </span>
                                        </div>
                                    </li>
                                )}
                            </>
                        ) : (
                            <li
                                className={`menu-item ${getMenuItemActive('/cremation/register')}`}
                                aria-haspopup='true'
                            >
                                <NavLink className='menu-link' to='/cremation/register'>
                                    <span className='svg-icon menu-icon'>
                                        <SVG
                                            title='สมัครสมาชิก'
                                            src={toAbsoluteUrl('/media/svg/icons/Communication/Write.svg')}
                                        />
                                    </span>
                                    <span className='menu-text'>สมัครสมาชิก</span>
                                </NavLink>
                            </li>
                        )}
                        {/*end::2 Level*/}

                        {morefunc && (
                            <>
                                {/*begin::2 Level*/}
                                {!isToday(new Date('2022-09-01')) && (
                                    <li
                                        className={`menu-item ${getMenuItemActive('/cremation/reservation')}`}
                                        aria-haspopup='true'
                                    >
                                        <NavLink className='menu-link' to='/cremation/reservation'>
                                            <span className='svg-icon menu-icon'>
                                                <SVG
                                                    title='จองแท็บเล็ต'
                                                    src={toAbsoluteUrl('/media/svg/icons/Communication/Write.svg')}
                                                />
                                            </span>
                                            <span className='menu-text'>จองแท็บเล็ต</span>
                                        </NavLink>
                                    </li>
                                )}
                                {/*begin::2 Level*/}
                                <li
                                    className={`menu-item ${getMenuItemActive('/cremation/deposit')}`}
                                    aria-haspopup='true'
                                >
                                    <NavLink className='menu-link' to='/cremation/deposit'>
                                        <span className='svg-icon menu-icon'>
                                            <SVG
                                                title='ฝากเงิน'
                                                src={toAbsoluteUrl('/media/svg/icons/Communication/Incoming-box.svg')}
                                            />
                                        </span>
                                        <span className='menu-text'>ฝากเงิน</span>
                                    </NavLink>
                                </li>
                                {/*end::2 Level*/}

                                {/*begin::2 Level*/}
                                <li
                                    className={`menu-item ${getMenuItemActive('/cremation/report')}`}
                                    aria-haspopup='true'
                                >
                                    <NavLink className='menu-link' to='/cremation/report'>
                                        <span className='svg-icon menu-icon'>
                                            <SVG
                                                title='รายละเอียดบัญชี'
                                                src={toAbsoluteUrl('/media/svg/icons/Communication/Clipboard-list.svg')}
                                            />
                                        </span>
                                        <span className='menu-text'>รายละเอียดบัญชี</span>
                                    </NavLink>
                                </li>
                                {/*end::2 Level*/}

                                {/*begin::2 Level*/}
                                <li
                                    className={`menu-item ${getMenuItemActive('/cremation/orderreport')}`}
                                    aria-haspopup='true'
                                >
                                    <NavLink className='menu-link' to='/cremation/orderreport'>
                                        <span className='svg-icon menu-icon'>
                                            <SVG
                                                title='ข้อมูลการทำรายการ'
                                                src={toAbsoluteUrl('/media/svg/icons/Text/Bullet-list.svg')}
                                            />
                                        </span>
                                        <span className='menu-text'>ข้อมูลการทำรายการ</span>
                                    </NavLink>
                                </li>
                                {/*end::2 Level*/}
                            </>
                        )}
                    </ul>
                </div>
            </li>
            {/*end::1 Level*/}

            {/*begin::1 Level*/}
            {bank && (
                <li className={`menu-item ${getMenuItemActive('/bank/setting', false)}`} aria-haspopup='true'>
                    <NavLink className='menu-link' to='/bank/setting'>
                        <span className='svg-icon menu-icon'>
                            <SVG src={toAbsoluteUrl('/media/svg/icons/Shopping/Credit-card.svg')} />
                        </span>
                        <span className='menu-text'>ข้อมูลธนาคาร</span>
                    </NavLink>
                </li>
            )}
            {/*end::1 Level*/}

            {/*begin::1 Level*/}
            {!bank && (
                <li className={`menu-item ${getMenuItemActive('/cash/withdraw', false)}`} aria-haspopup='true'>
                    <NavLink className='menu-link' to='/cash/withdraw'>
                        <span className='svg-icon menu-icon'>
                            <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Outgoing-box.svg')} />
                        </span>
                        <span className='menu-text'>ถอนเงิน</span>
                    </NavLink>
                </li>
            )}
            {/*end::1 Level*/}

            {/*begin::1 Level*/}
            {/* <li
                    className={`menu-item ${getMenuItemActive("/stock", false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/stock">
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
                        </span>
                        <span className="menu-text">หุ้น</span>
                    </NavLink>
                </li> */}
            {/*end::1 Level*/}

            {/*begin::1 Level*/}
            <li className={`menu-item ${getMenuItemActive('/logout', false)}`} aria-haspopup='true'>
                <NavLink className='menu-link' to='/logout'>
                    <span className='svg-icon menu-icon'>
                        <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Sign-out.svg')} />
                    </span>
                    <span className='menu-text'>ออกจากระบบ</span>
                </NavLink>
            </li>
            {/*end::1 Level*/}
        </ul>
    );
}
