import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';

import Select, { components } from 'react-select';

import { connect } from 'react-redux';

import { injectIntl } from 'react-intl';

import * as auth from '../../../../modules/Auth/_redux/authRedux';

import Swal from 'sweetalert2/dist/sweetalert2.js';

import config from '../../../../config';

const SwalBs = Swal.mixin({
    customClass: {
        popup: 'rounded-lg',
        confirmButton: 'btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto',
        cancelButton: 'btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto'
    },
    buttonsStyling: false
});

const controlStyles = {
    borderRadius: '0.42rem',
    // padding: '5px',
    background: '#F3F6F9',
    color: 'white'
};

const ControlComponent = (props) => (
    <div style={controlStyles}>
        {/* {<p>Custom Control</p>} */}
        <components.Control {...props} />
    </div>
);

const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        backgroundColor: '#F3F6F9'
    }),
    menuPortal: (base) => ({
        ...base,
        zIndex: 9999
    }),
    control: (provided, state) => ({
        ...provided,
        borderStyle: 'none',
        backgroundColor: 'transparent',
        boxShadow: 'none'
    })
    // singleValue: (provided, state) => {
    //     const opacity = state.isDisabled ? 0.5 : 1;
    //     const transition = "opacity 300ms";
    //     return { ...provided, opacity, transition };
    // },
};

function BankSettingMain(props) {
    const { info } = useSelector((state) => state.auth);
    let history = useHistory();

    const [ loading, setLoading ] = useState(false);
    const [ bankloading, setBankLoading ] = useState(true);
    const [ banklist, setBankList ] = useState([]);
    const [ bank, setBank ] = useState('');
    const [ banknumber, setBankNumber ] = useState('');
    const [ bankname, setBankName ] = useState('');

    const selectBank = (item) => {
        if (item) {
            setBank(item);
        } else {
            setBank('');
        }
    };

    useEffect(() => {
        getBank();
    }, []);

    const getBank = () => {
        setBankLoading(true);
        fetch(config.url + 'bank/bank-list', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: info.info.token
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setBankLoading(false);
                if (result.responsecode === 0) {
                    let initbanklist = [];
                    for (const index in result.bank) {
                        let thisbank = {};
                        for (const key in result.bank[index]) {
                            console.log(key);
                            if (key === 'bank_code') {
                                thisbank['value'] = result.bank[index]['bank_code'];
                            } else if (key === 'bank_name') {
                                thisbank['label'] = result.bank[index]['bank_name'];
                            }
                        }
                        initbanklist.push(thisbank);
                        // console.log(result.bank[index]);
                    }
                    setBankList(initbanklist);
                }
            })
            .catch((error) => {
                setBankLoading(false);
            });
    };

    const updateBank = () => {
        if (bank === '') {
            SwalBs.fire({
                // title: 'ยกเลิกคำสั่ง',
                html: 'กรุณาเลือกธนาคารของคุณให้เรียบร้อย',
                icon: 'warning',
                // showCancelButton: true,
                confirmButtonText: 'รับทราบ',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
            return false;
        }
        if (banknumber === '') {
            SwalBs.fire({
                // title: 'ยกเลิกคำสั่ง',
                html: 'กรุณากรอกเลขที่บัญชีของคุณให้เรียบร้อย',
                icon: 'warning',
                // showCancelButton: true,
                confirmButtonText: 'รับทราบ',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
            return false;
        }
        if (bankname === '') {
            SwalBs.fire({
                // title: 'ยกเลิกคำสั่ง',
                html: 'กรุณากรอกชื่อบัญชีของคุณให้เรียบร้อย',
                icon: 'warning',
                // showCancelButton: true,
                confirmButtonText: 'รับทราบ',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
            return false;
        }
        setLoading(true);
        fetch(config.url + 'bank/update', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: info.info.token,
                update: {
                    bank_code: bank.value,
                    bank_acc_no: banknumber,
                    bank_acc_name: bankname
                }
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `ดำเนินการแก้ไขเรียบร้อย`,
                        icon: 'success',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            let userinfo = info;
                            userinfo['info']['bank_code'] = bank.value;
                            userinfo['info']['bank_acc_no'] = banknumber;
                            userinfo['info']['bank_acc_name'] = bankname;
                            props.login(userinfo);
                            history.push('/user/information');
                            // setPassword('');
                            // setConfirmPassword('');
                        }
                    });
                } else {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `${result.responsecode}! มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง`,
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    icon: 'error',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
            });
    };
    return (
        <div>
            {loading ? (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <div className='text-center mb-10 mb-lg-20'>
                        <h3 className='font-size-h1'>ข้อมูลธนาคาร</h3>
                    </div>
                    <div className='row justify-content-center m-0 mb-10'>
                        <div className='col-lg-8 bg-white p-5 rounded-lg'>
                            <div className='form-group row mx-0'>
                                <label className='col-form-label col-lg-3'>ธนาคาร</label>
                                <div className='col-lg-9'>
                                    <Select
                                        components={{
                                            Control: ControlComponent
                                        }}
                                        noOptionsMessage={() => 'ไม่พบข้อมูล'}
                                        loadingMessage={() => 'กรุณารอสักครู่'}
                                        value={bank}
                                        options={banklist}
                                        placeholder='เลือก'
                                        id='bank'
                                        onChange={selectBank}
                                        isLoading={bankloading}
                                        // isClearable={true}
                                        menuPortalTarget={document.body}
                                        styles={customStyles}
                                    />
                                </div>
                            </div>
                            <div className='form-group row mx-0'>
                                <label className='col-form-label col-lg-3'>เลขที่บัญชี</label>
                                <div className='col-lg-9'>
                                    <input
                                        type='text'
                                        inputMode='tel'
                                        className='form-control'
                                        value={banknumber}
                                        onChange={(e) => setBankNumber(e.target.value)}
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                if (event.key !== 'Enter') {
                                                    event.preventDefault();
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='form-group row mx-0'>
                                <label className='col-form-label col-lg-3'>ชื่อบัญชี</label>
                                <div className='col-lg-9'>
                                    <input
                                        type='text'
                                        className='form-control'
                                        value={bankname}
                                        onChange={(e) => setBankName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='form-group d-flex flex-wrap flex-center mt-10'>
                                <button
                                    type='button'
                                    className='btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4 rounded-lg'
                                    onClick={updateBank}
                                >
                                    <span>ยืนยัน</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(BankSettingMain));
