import React from 'react';

import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

export function RegisterStep1(props) {
    return (
        <div>
            <OverlayScrollbarsComponent className='h-300px'>
                <div className='row-inner p-5 bg-code-editor'>
                    <div className='story-head text-center'>
                        <h1>ระเบียบกลุ่มเครดิตยูเนี่ยนครอบครัวซิมปันสุข</h1>
                        <h1>ว่าด้วยสมาชิกภาพ</h1>
                        <h1>พ.ศ. 2564</h1>
                        <div className='separator separator-dashed separator-gray separator-border-3 my-5 mx-15 mx-md-30' />
                    </div>
                    <div className='mb-7'>
                        <div className='row-panel '>
                            <div className='row-inner '>
                                <div>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        อาศัยอำนาจตามความในข้อบังคับของกลุ่มเครดิตยูเนี่ยนครอบครัวซิมปันสุข ข้อ. 105
                                        และข้อ. 76 (7) ที่ประชุมคณะกรรมการดำเนินการ ครั้งที่ <b>1/2564</b>{' '}
                                        <b>วันที่ 2 สิงหาคม พ.ศ. 2564</b> ได้กำหนดระเบียบว่าด้วยสมาชิกภาพดังต่อไปนี้
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        ข้อ 1) ระเบียบนี้เรียกว่า{' '}
                                        <b>“ระเบียบกลุ่มเครดิตยูเนี่ยนครอบครัวซิมปันสุขว่าด้วยสมาชิกภาพ พ.ศ. 2564”</b>
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        ข้อ 2) ระเบียบนี้ใช้บังคับตั้งแต่วันที่ <b>2 สิงหาคม พ.ศ. 2564</b> เป็นต้นไป
                                    </p>
                                    <h3 className='text-center m-0'>หมวด 1</h3>
                                    <h3 className='text-center'>คุณสมบัติของสมาชิก</h3>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ข้อ
                                        3) คุณสมบัติของผู้สมัครเข้าเป็นสมาชิก
                                        ผู้สมัครเป็นสมาชิกต้องมีคุณสมบัติตามที่กำหนดไว้ในข้อบังคับ รวมทั้งในข้อต่อไปนี้
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1)
                                        เป็นบุคคลธรรมดา บรรลุนิติภาวะ และมีสัญชาติไทย
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2)
                                        เป็นตัวแทนจำหน่ายของ บริษัท เคโฟร์ คอมมูนิเคชั่น จำกัด
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(3)
                                        เป็นตัวแทนจำหน่ายของ บริษัท ฟีล เทเลคอม คอร์ปอเรชั่น จำกัด
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(4)
                                        เป็นพนักงานของ บริษัท เคโฟร์ คอมมูนิเคชั่น จำกัด
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(5)
                                        เป็นพนักงานของ บริษัท ฟีล เทเลคอม คอร์ปอเรชั่น จำกัด
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(6)
                                        เป็นเจ้าหน้าที่กลุ่มเครดิตยูเนี่ยนครอบครัวซิมปันสุข
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(7)
                                        เป็นผู้มีความประพฤติและนิสัยดีงาม
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(8)
                                        เป็นผู้เห็นชอบในวัตถุประสงค์ของกลุ่มฯ มีกิจการร่วมกัน
                                        และพร้อมที่จะปฏิบัติตามระเบียบข้อบังคับของกลุ่มฯ
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(9)
                                        มิได้เป็นสมาชิกในกลุ่มฯ หรือกลุ่มฯ เครดิตยูเนี่ยนอื่น
                                    </p>
                                    <h3 className='text-center m-0'>หมวด 2</h3>
                                    <h3 className='text-center'>วิธีรับสมาชิก</h3>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ข้อ
                                        4)
                                        การยื่นใบสมัครให้ผู้สมัครเข้าเป็นสมาชิกต้องยื่นใบสมัครถึงคณะกรรมการอำนวยการของกลุ่มฯ
                                        ตามแบบที่กลุ่มฯ กำหนด และให้เหรัญญิกหรือผู้จัดการของกลุ่มฯ
                                        สอบสวนพิจารณาเบื้องต้นก่อน
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ในกรณีที่มีกลุ่มฯ
                                        สมาชิก ผู้สมัครต้องเลือกเข้าสังกัดอยู่กลุ่มฯ
                                        สมาชิกที่มีอยู่หรือซึ่งจะจัดตั้งขึ้นใหม่ตามที่ตนประกอบอาชีพหลักอยู่ใกล้เคียงโดยยื่นใบสมัครผ่านหัวหน้ากลุ่มฯ
                                        และให้หัวหน้ากลุ่มฯ สอบสวนพิจารณาเบื้องต้นก่อน
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ข้อ
                                        5) ค่าธรรมเนียมแรกเข้าและเงินสะสม
                                        ผู้สมัครเข้าเป็นสมาชิกต้องชำระค่าธรรมเนียมแรกเข้าให้แก่กลุ่มฯ คนละ 100 บาท
                                        และเงินสะสมตามวรรค 2 พร้อมกับยื่นใบสมัคร
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ผู้สมัครเข้าเป็นสมาชิกต้องฝึกหัดสะสมเงินออมของตนไว้ในกลุ่มฯ
                                        ตามความสามารถที่แสดงไว้ในใบสมัครเป็นประจำสม่ำเสมอเป็นเวลาไม่น้อยกว่า <b>6</b>{' '}
                                        เดือน
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;เมื่อกลุ่มฯ
                                        พิจารณารับผู้สมัครเข้าเป็นสมาชิก ให้กลุ่มฯ
                                        จ่ายโอนเงินสะสมของผู้สมัครไปเป็นค่าหุ้นตามที่กำหนดไว้ในข้อบังคับของกลุ่มฯ ข้อ
                                        31)
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ในกรณีที่กลุ่มฯ
                                        มีมติไม่รับผู้สมัครคนใดเข้าเป็นสมาชิก ให้กลุ่มฯ จ่ายคืนเงินสะสมของผู้สมัครนั้น ๆ
                                        โดยไม่ชักช้า
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ค่าธรรมเนียมแรกเข้าถือเป็นรายได้ของกลุ่มฯ
                                        ผู้สมัครจะเรียกคืนไม่ได้ไม่ว่าด้วยกรณีใด ๆ
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ข้อ
                                        6) การให้การศึกษาอบรม
                                        ผู้สมัครต้องเข้ารับการศึกษาอบรมตามหลักสูตรหรือวิธีการที่กลุ่มฯ กำหนด
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ให้คณะกรรมการศึกษาให้การศึกษาอบรมแก่ผู้สมัครเข้าเป็นสมาชิก
                                        ให้ทราบถึงวัตถุประสงค์ ระเบียบ ข้อบังคับของกลุ่มฯ เครดิตยูเนี่ยน และเรื่องอื่น ๆ
                                        ที่เห็นสมควร
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ข้อ
                                        7) การเลือกเข้าเป็นสมาชิก
                                        เมื่อคณะกรรมการศึกษาได้รับรองว่าผู้สมัครเข้าเป็นสมาชิกมีความรู้ความเข้าใจในวัตถุประสงค์
                                        ระเบียบ ข้อบังคับของกลุ่มฯ เครดิตยูเนี่ยน
                                        และเมื่อคณะกรรมการอำนวยการสอบสวนพิจารณาเห็นว่าผู้สมัครมีคุณสมบัติตามที่กำหนดไว้ในข้อ
                                        3) ทั้งเห็นเป็นการสมควรแล้วก็ให้รับผู้สมัครเข้าเป็นสมาชิกได้
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;เมื่อคณะกรรมการอำนวยการมีมติรับผู้สมัครเข้าเป็นสมาชิกแล้วให้แจ้งให้ผู้สมัครทราบ
                                        ในกรณีที่มีกลุ่มฯ สมาชิกให้แจ้งให้หัวหน้ากลุ่มฯ ที่ผู้นั้นสังกัดทราบโดยเร็ว
                                        และจัดให้ผู้สมัครลงลายมือชื่อในทะเบียนสมาชิก
                                        และชำระค่าหุ้นตามที่กำหนดไว้ในข้อบังคับของกลุ่มฯ ตามระยะเวลาที่กำหนด
                                        เมื่อผู้สมัครได้ปฏิบัติดังกล่าวแล้วจึงจะได้สิทธิเป็นสมาชิกโดยสมบูรณ์
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ถ้าคณะกรรมการอำนวยการมีมติไม่รับผู้สมัครคนใดเข้าเป็นสมาชิกด้วยเหตุผลใด
                                        ๆ ก็ดีให้รีบแจ้งมตินั้นให้ผู้สมัครทราบโดยไม่ชักช้า ในกรณีที่มีกลุ่มฯ
                                        สมาชิกให้แจ้งหัวหน้ากลุ่มฯ ทราบด้วย
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ถ้าคณะกรรมการอำนวยการไม่ยอมรับผู้สมัครเข้าเป็นสมาชิกด้วยเหตุใด
                                        ๆ
                                        เมื่อผู้สมัครร้องขอก็ให้คณะกรรมการดำเนินการนำเรื่องเสนอที่ประชุมใหญ่เพื่อวินิจฉัยชี้ขาดมติแห่งที่ประชุมใหญ่ให้รับเข้าเป็นสมาชิกในกรณีดังว่านี้
                                        ต้องมีคะแนนเสียงไม่น้อยกว่าสองในสามแห่งจำนวนสมาชิกที่มาประชุม
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ข้อ
                                        8) การเปลี่ยนชื่อ นามสกุล สัญชาติ และที่อยู่ สมาชิกคนใดมีการเปลี่ยนแปลงชื่อ
                                        ชื่อสกุล สัญชาติ และที่อยู่ต้องแจ้งให้กลุ่มฯ
                                        ทราบภายในสิบห้าวันนับแต่วันที่เปลี่ยนแปลง
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ข้อ
                                        9) การตั้งผู้รับโอนประโยชน์
                                        ให้สมาชิกทำหนังสือตั้งบุคคลหนึ่งหรือหลายคนเพื่อให้เป็นผู้รับประโยชน์ซึ่งตนมีอยู่ในกลุ่มฯ
                                        เมื่อตนตายนั้นมอบให้กลุ่มฯ ถือไว้
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;เมื่อสมาชิกตาย
                                        กลุ่มฯ จะจ่ายเงินค่าหุ้น เงินรับฝาก เงินปันผล เงินเฉลี่ยคืน และเงินผลประโยชน์
                                        หรือเงินอื่นใดบรรดาที่สมาชิกผู้ตายมีอยู่ในกลุ่มฯ
                                        ให้แก่ผู้รับโอนประโยชน์ที่ได้ตั้งไว้
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ให้ผู้รับโอนประโยชน์ตามความในวรรคก่อน
                                        ยื่นคำขอรับเงินผลประโยชน์ต่อกลุ่มฯ ภายในกำหนดหนึ่งปีนับแต่วันที่สมาชิกตาย
                                        โดยให้แนบสำเนามรณะบัตรที่ทางราชการออกให้แสดงว่าสมาชิกนั้น ๆ
                                        ได้ถึงแก่ความตายมาประกอบการพิจารณาด้วย
                                        เมื่อคณะกรรมการอำนวยการได้พิจารณาและอนุมัติแล้ว กลุ่มฯ
                                        จะจ่ายเงินผลประโยชน์ดังกล่าวภายในสี่สิบห้าวัน
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ในกรณีผู้มีสิทธิรับเงินผลประโยชน์ไม่ยื่นคำขอรับเงินผลประโยชน์
                                        หรือผู้มีชื่อเป็นผู้รับโอนประโยชน์ที่สมาชิกได้จัดทำให้กลุ่มฯ
                                        ถือไว้ไม่มีตัวตนอยู่ก็ดี เมื่อพ้นกำหนดอายุความฟ้องคดี ให้กลุ่มฯ
                                        โอนจำนวนเงินดังกล่าวไปสมทบเป็นทุนสำรองของกลุ่มฯ ทั้งสิ้น
                                    </p>
                                    <h3 className='text-center m-0'>หมวด 3</h3>
                                    <h3 className='text-center'>การขาดจากสมาชิกภาพ</h3>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ข้อ
                                        10) การขาดจากสมาชิกภาพ สมาชิกขาดจากสมาชิกภาพเพราะเหตุหนึ่งเหตุใด ดังต่อไปนี้
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1)
                                        ตาย
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2)
                                        วิกลจริต หรือจิตฟั่นเฟือน หรือถูกศาลสั่งให้เป็นคนไร้ความสามารถ
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(3)
                                        ต้องคำพิพากษาให้ล้มละลาย หรือต้องคำพิพากษาถึงที่สุดให้จำคุก เว้นแต่ความผิดลหุโทษ
                                        หรือความผิดอันกระทำโดยประมาท
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(4)
                                        ลาออกและได้รับอนุมัติแล้ว
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(5)
                                        ถูกให้ออกจากกลุ่มฯ
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ข้อ
                                        11) การลาออกจากกลุ่มฯ สมาชิกผู้ไม่มีพันธะต่อกลุ่มฯ
                                        ในฐานะผู้กู้หรือผู้ค้ำประกันเงินกู้หรือหนี้สินอื่นที่ผูกพันจะต้องชำระต่อกลุ่มฯ
                                        อาจขอลาออกจากกลุ่มฯ ได้โดยแสดงความจำนงเป็นหนังสือต่อคณะกรรมการอำนวยการ
                                        เมื่อคณะกรรมการอำนวยการได้สอบสวนพิจารณาเห็นว่าเป็นการเห็นชอบด้วยข้อบังคับ
                                        ระเบียบ และอนุมัติแล้วจึงให้ถือว่าออกจากกลุ่มฯ ได้
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ข้อ
                                        12) การให้ออกจากกลุ่มฯ สมาชิกอาจถูกให้ออกจากกลุ่มฯ
                                        เพราะเหตุหนึ่งเหตุใดดังต่อไปนี้
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1)
                                        ไม่ชำระค่าธรรมเนียมแรกเข้า
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2)
                                        นำเงินกู้ไปใช้ผิดวัตถุประสงค์ และโครงการที่คณะกรรมการเงินกู้ได้อนุมัติ
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(3)
                                        ไม่จัดการแก้ไข
                                        หรือเพิ่มเติมหลักประกันสำหรับเงินกู้ที่เกิดบกพร่องให้คืนดีภายในระยะเวลาที่คณะกรรมการเงินกู้กำหนด
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(4)
                                        ผิดนัดชำระหนี้เงินกู้ตามที่กำหนดถึงสามคราวติดต่อกัน หรือเกินกว่าหกสิบวัน
                                        ในกรณีที่ผิดนัดชำระหนี้เป็นรายเดือน
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(5)
                                        จงใจปิดบังความจริงอันควรแจ้งให้ทราบในใบสมัครเข้าเป็นสมาชิก
                                        เฉพาะเกี่ยวข้องกับหนี้สินของตน
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(6)
                                        จงใจฝ่าฝืนข้อบังคับ ระเบียบ หรือมติของกลุ่มฯ หรือของที่ประชุมกลุ่มฯ
                                        สมาชิกซึ่งตนสังกัด หรือประพฤติ หรือกระทำการใดๆ
                                        อันเป็นเหตุให้เห็นว่าไม่ซื่อสัตย์สุจริต หรือแสดงตนเป็นปฏิปักษ์
                                        หรือเสื่อมเสียต่อกลุ่มฯ หรือกลุ่มฯ สมาชิก ไม่ว่าโดยประการใด ๆ
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;เมื่อคณะกรรมการอำนวยการได้สอบสวนพิจารณาปรากฏว่าสมาชิกมีเหตุใด
                                        ๆ ดังกล่าวข้างต้น ให้เสนอคณะกรรมการดำเนินการพิจารณา
                                        เมื่อคณะกรรมการดำเนินการได้ลงมติให้สมาชิกออกโดยคะแนนเสียงไม่น้อยกว่าสองในสามแห่งจำนวนกรรมการดำเนินการทั้งหมดแล้ว
                                        ก็เป็นอันถือว่าสมาชิกรายนั้นถูกให้ออกจากกลุ่มฯ
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;คณะกรรมการอำนวยการมีหน้าที่แจ้งมติของคณะกรรมการดำเนินการให้สมาชิกนั้นทราบโดยมิชักช้า
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;สมาชิกที่ถูกให้ออกจากกลุ่มฯ
                                        มีสิทธิอุทธรณ์ต่อที่ประชุมใหญ่ภายในสามสิบวันนับแต่วันที่ทราบมติให้ออกจากกลุ่มฯ
                                        คำวินิจฉัยของที่ประชุมใหญ่ให้ถือเป็นที่สิ้นสุด
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ข้อ
                                        13) การจำหน่ายชื่อสมาชิกออกจากทะเบียนสมาชิก ในกรณีที่สมาชิกออกจากกลุ่มฯ
                                        ไม่ว่าเพราะเหตุหนึ่งเหตุใด
                                        ให้คณะกรรมการอำนวยการจัดให้จำหน่ายชื่อออกจากทะเบียนสมาชิกกับให้ปิดประกาศที่สำนักงานของกลุ่มฯ
                                        เป็นเวลาไม่น้อยกว่าสี่สิบห้าวันแล้วเสนอเรื่องสมาชิกออกให้ที่ประชุมใหญ่สามัญคราวถัดไปทราบ
                                        โดยเฉพาะการให้ออกต้องชี้แจงเหตุผลด้วย
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;อนึ่ง
                                        ให้กลุ่มฯ แจ้งเรื่องสมาชิกออกให้หัวหน้ากลุ่มฯ ซึ่งเกี่ยวข้องเสนอที่ประชุมกลุ่มฯ
                                        ทราบโดยเร็ว
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ข้อ
                                        14) การจ่ายคืนจำนวนเงินของสมาชิกผู้ออกจากกลุ่มฯ
                                        ในกรณีที่สมาชิกพ้นจากสมาชิกภาพเพราะเหตุตามข้อบังคับข้อ 36(1), (2) และ (4) กลุ่มฯ
                                        จะจ่ายคืนเงินบรรดาที่สมาชิกนั้นมีอยู่ในกลุ่มฯ
                                        ให้แก่ผู้มีสิทธิได้รับภายในกำหนดสี่สิบห้าวันนับแต่วันที่กลุ่มฯ
                                        ได้รับคำร้องขอรับเงิน
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;เฉพาะเงินค่าหุ้น
                                        ถ้าผู้มีสิทธิประสงค์จะได้รับเงินปันผล
                                        และเงินเฉลี่ยคืนสำหรับปีทางบัญชีออกด้วยก็จะต้องรอการถอนคืนไว้จนถึงวันขึ้นปีทางบัญชีใหม่
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;สำหรับเงินฝากและดอกเบี้ย
                                        กลุ่มฯ จะจ่ายคืนให้ตามระเบียบว่าด้วยการรับฝากเงิน
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ถ้าในปีใดจำนวนค่าหุ้นทั้งที่ถอนคืนเนื่องจากสมาชิกลาออกจากกลุ่มฯ
                                        ตามข้อ 10(4) จะเกินร้อยละสิบแห่งทุนเรือนหุ้นของกลุ่มฯ ตามที่อยู่ในวันต้นปีนั้น
                                        คณะกรรมการดำเนินการมีอำนาจให้รอจ่ายคืนค่าหุ้นของสมาชิกที่ลาออกรายต่อไปในปีนั้นไว้จนถึงปีทางบัญชีใหม่
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ในกรณีที่สมาชิกออกจากกลุ่มฯ
                                        เพราะเหตุต้องคำพิพากษาให้ล้มละลายตามข้อ 10(3) กลุ่มฯ
                                        จะจ่ายคืนเงินบรรดาที่สมาชิกนั้นมีอยู่ในกลุ่มฯ คืนให้ตามกฎหมายล้มละลาย
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ในกรณีที่สมาชิกออกจากกลุ่มฯ
                                        เพราะเหตุตามข้อ 10(5) กลุ่มฯ จะจ่ายคืนเงินบรรดาที่สมาชิกนั้นมีอยู่ในกลุ่มฯ
                                        คืนให้ภายในที่กำหนดสี่สิบห้าวันที่ได้รับคำร้องขอเงินคืนโดยไม่มีเงินปันผล
                                        หรือเงินเฉลี่ยคืนสำหรับปีทางบัญชีออกแต่อย่างใดเลย ส่วนเงินฝากและดอกเบี้ยกลุ่มฯ
                                        จะจ่ายคืนให้ตามระเบียบว่าด้วยการรับฝากเงิน
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ข้อ
                                        15) การหักจำนวนเงินซึ่งสมาชิกจะต้องรับผิดต่อกลุ่มฯ
                                        ในการจ่ายคืนจำนวนเงินดังกล่าวในข้อ 14) นั้น ให้กลุ่มฯ
                                        หักจำนวนเงินซึ่งสมาชิกต้องรับผิดชอบต่อกลุ่มฯ ออกก่อน
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ข้อ
                                        16) ความรับผิดของสมาชิกเพื่อหนี้สินของกลุ่มฯ
                                        สมาชิกต้องรับผิดเพื่อหนี้สินของกลุ่มฯ จำกัด
                                        เพียงไม่เกินค่าหุ้นที่ยังส่งใช้ไม่ครบมูลค่าหุ้นที่ตนถือ
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='story-head text-center'>
                        <div className='separator separator-dashed separator-gray separator-border-3 my-5' />
                        <h1>ระเบียบกลุ่มเครดิตยูเนี่ยนครอบครัวซิมปันสุข</h1>
                        <h1>ว่าด้วยสมุดประจำตัวสมาชิก</h1>
                        <h1>พ.ศ. 2564</h1>
                        <div className='separator separator-dashed separator-gray separator-border-3 my-5 mx-15 mx-md-30' />
                    </div>
                    <div>
                        <div className='row-panel '>
                            <div className='row-inner '>
                                <div>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        อาศัยอำนาจตามความในข้อบังคับของกลุ่มฯ ข้อ 6 และข้อ 103(6)
                                        ที่ประชุมคณะกรรมการดำเนินการ ครั้งที่ <b>1/2564</b> วันที่{' '}
                                        <b>2 สิงหาคม พ.ศ. 2564</b> ได้กำหนดระเบียบว่าด้วยสมุดประจำตัวสมาชิก ดังต่อไปนี้
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        ข้อ 1. ระเบียบนี้เรียกว่า{' '}
                                        <b>
                                            “ระเบียบกลุ่มเครดิตยูเนี่ยนครอบครัวซิมปันสุขว่าด้วยสมุดประจำตัวสมาชิก พ.ศ.
                                            2564”
                                        </b>
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        ข้อ 2. ระเบียบนี้ให้ใช้บังคับตั้งแต่วันที่ <b>2 สิงหาคม พ.ศ. 2564</b> เป็นต้นไป
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ข้อ
                                        3. การออกสมุด ให้กลุ่มฯ
                                        ออกสมุดประจำตัวสมาชิกให้แก่สมาชิกและผู้ที่สะสมเงินกับกลุ่มฯ ทุกคน
                                        ตามแบบที่กลุ่มฯ กำหนด
                                        สมาชิกจะต้องเก็บรักษาสมุดประจำตัวสมาชิกของตนไว้ในที่ปลอดภัย
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;เมื่อสมุดประจำตัวสมาชิกได้ลงรายการเต็มแล้ว
                                        ให้นำส่งมอบต่อกลุ่มฯ เพื่อกลุ่มฯ
                                        จะได้โอนเงินคงเหลือเข้าสมุดประจำตัวสมาชิกเล่มใหม่ให้ยึดถือไว้ต่อไป
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ในกรณีที่กลุ่มฯ
                                        ออกสมุดประจำตัวสมาชิกให้ครั้งแรกก็ดี
                                        หรือออกเล่มใหม่ให้ต่อจากเล่มก่อนที่ลงรายการเต็มแล้ว กลุ่มฯ จะไม่คิดค่าธรรมเนียม
                                        แต่ในกรณีที่สมุดประจำตัวสมาชิกของผู้ใดสูญหายหรือชำรุด กลุ่มฯ
                                        จะออกสมุดประจำตัวสมาชิกเล่มใหม่ให้ โดยคิดค่าธรรมเนียมเล่มละ <b>100</b> บาท
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ข้อ
                                        4. การลงรายการในสมุด ให้สมาชิกนำสมุดประจำตัวสมาชิกมาให้กลุ่มฯ
                                        ลงรายการให้ทุกคราวที่มีรายการเปลี่ยนแปลง หรือมีรายการเพิ่มเติม
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;การลงบันทึกรายการต่าง
                                        ๆ ในสมุดประจำตัวสมาชิกนั้น จะกระทำได้แต่โดยฝ่ายกลุ่มฯ ซึ่งประธานกรรมการ
                                        หรือรองประธานกรรมการ หรือเลขานุการ หรือเหรัญญิก หรือผู้จัดการคนหนึ่งคนใด
                                        เป็นผู้ลงลายมือชื่อกำกับไว้เป็นสำคัญ การบันทึกรายการในสมุดประจำตัวสมาชิก
                                        โดยไม่เป็นไปตามที่กล่าวข้างต้น ย่อมไม่มีผลผูกพันกลุ่มฯ
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ในกรณีที่ลงบันทึกรายการสมุดประจำตัวสมาชิกผิดพลาด
                                        ห้ามขูดลงหรือเขียนทับรายการเดิม ให้แก้ไขโดยวิธีขีดฆ่าแล้วเขียนใหม่
                                        ซึ่งประธานกรรมการ หรือรองประธานกรรมการ หรือเลขานุการ หรือเหรัญญิก
                                        หรือผู้จัดการคนหนึ่งคนใดต้องเป็นผู้ลงลายมือชื่อย่อกำกับไว้ทุกแห่ง
                                        หากไม่เป็นไปตามที่กล่าวข้างต้นย่อมไม่มีผลผูกพันกลุ่มฯ
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ให้เป็นหน้าที่ของสมาชิกที่จะต้องตรวจสอบความถูกต้องทุกคราว
                                        เมื่อทางกลุ่มฯ ได้ลงบันทึกรายการในสมุดประจำตัวสมาชิกให้แล้ว
                                        หากพบว่ารายการใดคลาดเคลื่อนต้องแจ้งต่อกลุ่มฯ เพื่อแก้ไขให้ และจะแก้ไข
                                        โดยประการอื่นไม่ได้
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ข้อ
                                        5. การส่งคืนสมุด เมื่อสมาชิกต้องออกจากกลุ่มฯ ไม่ว่ากรณีใด ๆ
                                        ให้นำสมุดประจำตัวสมาชิกส่งคืนกลุ่มฯ โดยเร็ว
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;หากผู้ใดไม่มีสมุดประจำตัวสมาชิกส่งคืนกลุ่มฯ
                                        ตามวรรคแรก ให้ทำเป็นบันทึกแจ้งให้กลุ่มฯ ทราบพร้อมด้วยเหตุ
                                        และให้ถือว่าสมุดประจำตัวสมาชิกของผู้นั้นเป็นอันยกเลิก
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div>
                        <div className='row-panel '>
                            <div className='row-inner '>
                                <div>
                                    <p>
                                        ข้อกำหนดและเงื่อนไขในการสมัครสมาชิก กลุ่มเครดิตยูเนี่ยนครอบครัวซิมปันสุข ฉบับนี้
                                        ("ข้อกำหนดและเงื่อนไขฯ")
                                        ได้ระบุถึงข้อกำหนดและเงื่อนไขในการใช้งานผลิตภัณฑ์และบริการใดๆ (เรียกรวมกันว่า
                                        “บริการฯ”) ของ บริษัท เคโฟร์ คอมมูนิเคชั่น จำกัด แก่ผู้ใช้บริการ
                                        (โดยแต่ละรายเรียกว่า "ผู้ใช้" หรือ “ผู้ใช้รายต่างๆ” ขึ้นอยู่กับเนื้อหา)
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='row-panel '>
                            <div className='row-inner '>
                                <h2>1) ว่าด้วยสมุดประจำตัวสมาชิก (พ.ศ. 2564)</h2>
                                <div>
                                    <ul className='aticle-txt '>
                                        <li>
                                            <p className='mT15'>เป็นผู้เห็นชอบในวัตถุประสงค์ของสหกรณ์</p>
                                        </li>
                                        <li>
                                            <p className='mT15'>
                                                เป็นบุคคลธรรมดาและบรรลุนิติภาวะ และเป็นตัวแทนจำหน่ายของ บริษัท เคโฟร์
                                                คอมมูนิเคชั่น จำกัด
                                            </p>
                                        </li>
                                        <li>
                                            <p className='mT15'>เป็นผู้มีความประพฤติและนิสัยดีงาม</p>
                                        </li>
                                        <li>
                                            <p className='mT15'>มิได้เป็นสมาชิกในสหกรณ์เครดิตยูเนี่ยนอื่น</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='row-panel '>
                            <div className='row-inner '>
                                <h2>2) หุ้น</h2>
                                <div>
                                    <ul className='aticle-txt '>
                                        <li>
                                            <p className='mT15'>หุ้นละ 10 บาท</p>
                                        </li>
                                        <li>
                                            <p className='mT15'>
                                                การซื้อหุ้นเพิ่มสมาชิกจะซื้อหุ้นเพิ่มอีกเมื่อใดและจำนวนเท่าใดก็ทำได้แต่สมาชิก
                                                1 ท่านมีหุ้นได้ไม่เกิน 1 ใน 5 ของจำนวนหุ้นทั้งหมด
                                            </p>
                                        </li>
                                        <li>
                                            <p className='mT15'>
                                                สมาชิกแต่ละท่านต้องถือหุ้นในสหกรณ์เมื่อแรกเข้าอย่างน้อย 10 หุ้น
                                                และถือหุ้นเป็นประจำทุกเดือน
                                            </p>
                                        </li>
                                        <li>
                                            <p className='mT15'>
                                                สมาชิกทุกคนมีหน้าที่ส่งค่าหุ้นให้บริษัทเดือนละ 10 หุ้น
                                            </p>
                                        </li>
                                        <li>
                                            <p className='mT15'>
                                                ในกรณีที่ขาดชำระค่าหุ้นรายเดือนหรือชำระไม่ครบจำนวน 4
                                                งวดระบบจะตัดสิทธิการเป็นสมาชิก
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='row-panel '>
                            <div className='row-inner'>
                                <h2>3) วิธีการชำระเงินค่าหุ้นรายเดือน</h2>
                                <div>
                                    <ul className='aticle-txt'>
                                        <li>
                                            <p className='mT15'>หักเงินผ่านระบบกระเป๋าเงินซิมปันสุข</p>
                                        </li>
                                        <li>
                                            <p className='mT15'>ชำระเงินด้วยตนเองหรือโอนเงินเข้าบัญชีเงินฝาก</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='row-panel '>
                            <div className='row-inner '>
                                <h2>4) การเข้าเป็นสมาชิก</h2>
                                <div>
                                    <ul className='aticle-txt '>
                                        <li>
                                            <p className='mT15'>
                                                ยื่นใบสมัคร
                                                ผู้สมัครเข้าเป็นสมาชิกต้องชำระค่าธรรมเนียมแรกเข้าในวันยื่นใบสมัครจำนวน{' '}
                                                <strong>100 บาท</strong> และค่าหุ้นในอัตราอย่างน้อย 10 หุ้น{' '}
                                                <strong>(100 บาท) รวมค่าใช้จ่ายเบื้องต้นแรกเข้าจ านวน 200 บาท</strong>
                                            </p>
                                        </li>
                                        <li>
                                            <p className='mT15'>
                                                ค่าธรรมเนียมแรกเข้าให้ถือเป็นรายได้ของสหกรณ์จะเรียกคืนไม่ได้
                                                ไม่ว่าด้วยกรณีใด ๆ
                                            </p>
                                        </li>
                                        <li>
                                            <p className='mT15'>
                                                เริ่มฝึกหัดสะสมเงินออมไว้ในสหกรณ์ตามความสามารถที่แจ้งไว้ในใบสมัคร
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </OverlayScrollbarsComponent>

            <div className='row mt-7 px-5'>
                <div className='text-center'>
                    <div className='radio-inline'>
                        <label className='radio radio-success'>
                            <input
                                type='radio'
                                name='agree-btn'
                                checked={props.agreement}
                                onClick={(e) => props.setAgreement(!props.agreement)}
                            />
                            <span />
                            ยอมรับข้อกำหนดและเงื่อนไข
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
}
