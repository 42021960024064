import React from 'react';

import { QRCode } from 'react-qrcode-logo';

import Moment from 'moment';

import html2canvas from 'html2canvas';

import { toAbsoluteUrl } from '../../../../../../_metronic/_helpers';

export function FundOrderResult(props) {
    const handleDownload = () => {
        let d = new Date();
        html2canvas(document.querySelector('#react-qrcode-logo')).then(function(canvas) {
            const link = document.createElement('a');
            link.download = `payment-${Moment(d).format('YYYYMMDDhhmmss')}.png`;
            link.href = canvas.toDataURL();
            link.click();
        });
    };

    return (
        <div className='row justify-content-center mb-7'>
            <div className='col-lg-10 col-12 p-0'>
                {props.orderres.responsecode === 0 ? (
                    <div className='col-12 p-0'>
                        <div className='text-center'>
                            <QRCode
                                className='border'
                                value={props.orderres.qr}
                                size={256}
                                eyeRadius={10}
                                logoImage={toAbsoluteUrl('/media/logos/cooperative-logo-bg.png')}
                                logoWidth={100}
                            />
                            <div className='mt-7'>
                                <button type='button' className='btn btn-primary rounded-lg' onClick={handleDownload}>
                                    บันทึกคิวอาร์โค้ด
                                </button>
                            </div>
                            <h2 className='mt-7'>รายการสั่งซื้อถูกสร้าง</h2>
                            <p className='m-0'>
                                กรุณานำคิวอาร์โค้ดนี้ไปชำระเงินให้เรียบร้อยเพื่อดำเนินการยืนยันการสั่งซื้อ
                            </p>
                            <p>
                                คิวอาร์โค้ดนี้มีอายุ 1 วัน หากเกิน 1 วันกรุณาติดต่อเจ้าหน้าที่เพื่อดำเนินการให้เรียบร้อย
                            </p>
                        </div>
                    </div>
                ) : (
                    <div className='col-12 p-0'>
                        <div className='text-center'>
                            <img
                                className='max-h-200px'
                                alt='qr-failed'
                                src={toAbsoluteUrl('/media/assets/register/qr-failed.png')}
                            />
                            <h2 className='mt-7'>ไม่สามารถสร้างคิวอาร์โค้ดได้</h2>
                            <p className='m-0'>กรุณาลองใหม่อีกครั้ง</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
