import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import DatePicker, { utils } from 'react-modern-calendar-datepicker';

import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import { Modal } from 'react-bootstrap';

import { QRCode } from 'react-qrcode-logo';

import Moment from 'moment';

import html2canvas from 'html2canvas';

import Swal from 'sweetalert2/dist/sweetalert2.js';

import NumberFormat from 'react-number-format';

import { myCustomLocale, minimumDate } from './components';

import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';

import config from '../../../../config';

const SwalBs = Swal.mixin({
    customClass: {
        popup: 'rounded-lg',
        confirmButton: 'btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto',
        cancelButton: 'btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto'
    },
    buttonsStyling: false
});

export function FundOrderReportMain() {
    const { info } = useSelector((state) => state.auth);
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState(false);
    const [ date, setDate ] = useState({
        from: null,
        to: null
    });
    const [ orderlist, setOrderlist ] = useState({});
    const [ accountid, setAccountid ] = useState('');
    // const [ qrcode, setQrcode ] = useState('');
    const [ modal, setModal ] = useState(false);
    const [ orderdetail, setOrderdetail ] = useState({});

    useEffect(() => {
        getAccountid();
    }, []);

    const handleModalClose = () => setModal(false);
    const handleModalShow = (orderid) => {
        getOrderDetail(orderid);
    };

    const handleDownload = () => {
        let d = new Date();
        html2canvas(document.querySelector('#react-qrcode-logo')).then(function(canvas) {
            const link = document.createElement('a');
            link.download = `payment-${Moment(d).format('YYYYMMDDhhmmss')}.png`;
            link.href = canvas.toDataURL();
            link.click();
        });
    };

    const btnSearch = () => {
        let param = {
            token: info.info.token
        };
        let filter = {
            account_number: accountid.toString(),
            item_id: '6'
        };
        if (date.from !== null && date.to !== null) {
            filter['start'] =
                date.from.year + '-' + ('0' + date.from.month).slice(-2) + '-' + ('0' + date.from.day).slice(-2);
            filter['end'] = date.to.year + '-' + ('0' + date.to.month).slice(-2) + '-' + ('0' + date.to.day).slice(-2);
        }
        if (Object.keys(filter).length > 0) {
            param['filter'] = filter;
        }
        getOrder(param);
    };

    const getOrderDetail = (orderid) => {
        setLoading(true);
        fetch(config.url + 'order/get', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: info.info.token,
                id: orderid.toString()
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    setOrderdetail(result);
                    setModal(true);
                } else {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `${result.responsecode}! ไม่สามารถดึงรายละเอียดเพิ่มเติมได้ กรุณาลองใหม่อีกครั้ง`,
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return false;
                }
            })
            .catch((error) => {
                setLoading(false);
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: 'ไม่สามารถดึงรายละเอียดเพิ่มเติมได้ กรุณาลองใหม่อีกครั้ง',
                    icon: 'error',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return false;
            });
    }

    const getAccountid = () => {
        setLoading(true);
        fetch(config.url + 'account/get-balance', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: info.info.token
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    // setAccount(Object.values(result.account));
                    for (const thisindex in Object.values(result.account)) {
                        if (Object.values(result.account)[thisindex]['type'] === '1') {
                            setAccountid(Object.values(result.account)[thisindex]['number']);
                            break;
                        }
                    }
                } else {
                    setError(true);
                }
            })
            .catch((error) => {
                setLoading(false);
                setError(true);
                // setInit(true);
            });
    }

    const getOrder = (param) => {
        setLoading(true);
        fetch(config.url + 'order/find', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify(param)
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                // console.log(result);
                setOrderlist(result);
                // setTransaction(result);
            })
            .catch((error) => {
                setLoading(false);
                setError(true);
            });
    };

    const renderCustomInput = ({ ref }) => (
        <input
            readOnly
            ref={ref}
            value={
                date.from && date.to ? (
                    date.from.year +
                    '/' +
                    ('0' + date.from.month).slice(-2) +
                    '/' +
                    ('0' + date.from.day).slice(-2) +
                    ' - ' +
                    date.to.year +
                    '/' +
                    ('0' + date.to.month).slice(-2) +
                    '/' +
                    ('0' + date.to.day).slice(-2)
                ) : (
                    ''
                )
            }
            className='col-12 form-control h-100 border-0 rounded-lg rounded-right-0'
            type='text'
            id='dateperiod'
            name='dateperiod'
        />
    );

    return (
        <>
            {loading ? (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='card card-custom rounded-lg mb-7'>
                    <div className='card-header'>
                        <div className='card-title'>
                            <h3 className='card-label'>ข้อมูลการทำรายการ</h3>
                        </div>
                        <div className='card-toolbar col-12 col-md-4 my-5 my-md-0'>
                            <div className='input-group'>
                                <DatePicker
                                    value={date}
                                    onChange={setDate}
                                    renderInput={renderCustomInput}
                                    minimumDate={minimumDate}
                                    maximumDate={utils().getToday()}
                                    locale={myCustomLocale}
                                    wrapperClassName='col-12 p-0 form-control rounded-lg rounded-right-0'
                                    calendarClassName='responsive-calendar'
                                    calendarPopperPosition='left'
                                    shouldHighlightWeekends
                                />
                                <div className='input-group-append'>
                                    <button
                                        className='btn btn-secondary rounded-lg rounded-left-0'
                                        type='button'
                                        onClick={btnSearch}
                                        disabled={accountid === ''}
                                    >
                                        ค้นหา
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card-body'>
                        {error ? (
                            <div className='balance-info-height'>
                                <div className='row justify-content-center align-items-center min-h-450px'>
                                    <h1>มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง</h1>
                                </div>
                            </div>
                        ) : (
                            <>
                                {orderlist.responsecode === 0 ? (
                                    <OverlayScrollbarsComponent className='h-450px'>
                                        <div className='timeline timeline-3'>
                                            <div className='timeline-items'>
                                                {orderlist.orders.map((object, index) => {
                                                    return (
                                                        <div className='timeline-item' key={index}>
                                                            <div className='timeline-media'>
                                                                <i
                                                                    className={`icon-lg fas ${object.status === 'paid'
                                                                        ? 'fa-check-circle text-success'
                                                                        : 'fa-clock text-muted'}`}
                                                                />
                                                            </div>
                                                            <div className='timeline-content'>
                                                                <div className='d-flex align-items-center justify-content-between mb-3'>
                                                                    <div className='text-dark'>{object.type === 'REG' ? 'สมัครสมาชิก' : 'ซื้อหุ้น'}ราคา {object.total_gross_price} บาท</div>
                                                                    <div className='ml-auto'>
                                                                        <span className='text-muted'>{object.created_date}</span>
                                                                    </div>
                                                                </div>
                                                                <div className='d-flex align-items-center justify-content-between mb-3'>
                                                                    <p className='p-0 m-0'>
                                                                        ผ่านช่องทาง <b>{object.payment === 'QR-Code' ? 'คิวอาร์โค้ด' : 'กระเป๋าปันสุข'}</b>
                                                                    </p>
                                                                    <div className='ml-auto'>
                                                                        <button type='button' className='btn btn-primary btn-sm rounded-lg' onClick={(e) => handleModalShow(object.id)}>
                                                                            รายละเอียดเพิ่มเติม
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </OverlayScrollbarsComponent>
                                ) : (
                                    <div className='balance-info-height'>
                                        <div className='row justify-content-center align-items-center min-h-450px'>
                                            <h1>ไม่พบข้อมูลการทำรายการ</h1>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            )}

            {Object.keys(orderdetail).length > 0 && (
                <Modal
                    show={modal}
                    onHide={handleModalClose}
                    size='lg'
                    centered
                    aria-labelledby='qrcode-modal'
                >
                    <Modal.Header closeButton>
                        <Modal.Title id='qrcode-modal' className='col-11 p-0'>
                            รายละเอียด
                        </Modal.Title>
                        <button type='button' className='close col-1 text-right pr-3' onClick={handleModalClose}>
                            <i className='ki ki-close' />
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row justify-content-center'>
                            {orderdetail.orders.payment === 'QR-Code' && orderdetail.orders.status === 'pending' && (
                                <div className='col-12 p-0'>
                                    <div className='text-center'>
                                        <QRCode
                                            className='border'
                                            value={orderdetail.orders.qr}
                                            size={256}
                                            eyeRadius={10}
                                            logoImage={toAbsoluteUrl('/media/logos/cooperative-logo-bg.png')}
                                            logoWidth={100}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="col-12 p-0">
                                <div className="bg-light rounded-lg d-flex flex-column p-3">
                                    <div className="p-2 d-flex">
                                        <div className="col-6">
                                            ชำระเงินผ่านช่องทาง
                                        </div>
                                        <div className="ml-auto text-right">
                                            {orderdetail.orders.payment === 'QR-Code' ? 'คิวอาร์โค้ด' : 'กระเป๋าปันสุข'}
                                        </div>
                                    </div>
                                    <div className="p-2 d-flex">
                                        <div className="col-6">
                                            สถานะการทำรายการ
                                        </div>
                                        <div className="ml-auto text-right">
                                            {orderdetail.orders.status === 'paid' ? 'ดำเนินการเรียบร้อย' : 'รอการชำระเงิน'}
                                        </div>
                                    </div>
                                    <div className="p-2 d-flex">
                                        <div className="col-6">
                                            ยอดรวม
                                        </div>
                                        <div className="ml-auto text-right">
                                            <NumberFormat
                                                value={parseFloat(orderdetail.orders.total_gross_price)}
                                                displayType={'text'}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                                suffix={' บาท'}
                                            />
                                        </div>
                                    </div>
                                    <div className="p-2 d-flex">
                                        <div className="col-12">
                                            รายละเอียดค่าบริการ
                                        </div>
                                    </div>
                                    <div className="p-2 d-flex">
                                        <div className="col-12">
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th className="pl-0 font-weight-bold text-muted text-uppercase">ชื่อบริการ</th>
                                                            <th className="text-right font-weight-bold text-muted text-uppercase">จำนวน</th>
                                                            <th className="text-right font-weight-bold text-muted text-uppercase">ราคาต่ออัน</th>
                                                            <th className="text-right pr-0 font-weight-bold text-muted text-uppercase">ราคารวม</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {orderdetail.orders.order_item.map((object, index) => {
                                                            return(
                                                                <tr className="font-weight-boldest font-size-lg" key={index}>
                                                                    <td className="pl-0 pt-7">{object.item_name}</td>
                                                                    <td className="text-right pt-7">{object.quantity}</td>
                                                                    <td className="text-right pt-7">
                                                                        <NumberFormat
                                                                            value={parseFloat(object.price)}
                                                                            displayType={'text'}
                                                                            decimalScale={2}
                                                                            fixedDecimalScale={true}
                                                                            thousandSeparator={true}
                                                                            suffix={' บาท'}
                                                                        />
                                                                    </td>
                                                                    <td className="text-primary pr-0 pt-7 text-right">
                                                                        <NumberFormat
                                                                            value={parseFloat(object.gross_price)}
                                                                            displayType={'text'}
                                                                            decimalScale={2}
                                                                            fixedDecimalScale={true}
                                                                            thousandSeparator={true}
                                                                            suffix={' บาท'}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    {orderdetail.orders.payment === 'QR-Code' && orderdetail.orders.status === 'pending' && (
                        <Modal.Footer>
                            <div className='row justify-content-end m-0'>
                                <div className='col-12 p-0'>
                                    <button type='button' className='btn btn-primary rounded-lg' onClick={handleDownload}>
                                        บันทึกคิวอาร์โค้ด
                                    </button>
                                </div>
                            </div>
                        </Modal.Footer>
                    )}
                </Modal>
            )}
        </>
    );
}
