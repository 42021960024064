const config = {
    // url: 'http://192.168.159.83/',
    // url: 'http://192.168.159.44:5003/',
    // url: 'http://192.168.159.44:5005/',
    // url: 'http://localhost:5005/',
    url: 'https://api-cooperative.simk4.com/',
    // url: 'https://beta-api-cooperative.simk4.com/',
    version: 'v1',
    secret: '***',
    maintain: false
};

export default config;
