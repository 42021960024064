import React, { useEffect, useState } from 'react';

import InputMask from 'react-input-mask';

import Select from 'react-select';

import TextareaAutosize from 'react-textarea-autosize';

import { ControlComponent, customStyles } from './dropdown';

import initaddress from '../../../../address';

export function RegisterStepForm(props) {
    const [ amount, setAmount ] = useState(1);
    const [ action, setAction ] = useState(0);
    const [ list, setList ] = useState(props.benefitlist);

    useEffect(
        () => {
            setList(props.benefitlist);
        },
        [ action ]
    );

    const selectProvince = (item) => {
        if (item) {
            props.setMaindistrict([]);
            props.setMainsubdistrict([]);
            props.setProvince(item);
            props.setDistrict('');
            props.setSubdistrict('');
            props.setZipcode('');
            let thisDistrict = [];
            let thisindex = 0;
            for (const thisdistrict in initaddress.district) {
                if (initaddress.district[thisdistrict]['provinceid'] === item['value']) {
                    thisDistrict[thisindex] = initaddress.district[thisdistrict];
                    thisindex += 1;
                }
            }
            props.setMaindistrict(thisDistrict);
        } else {
            props.setMaindistrict([]);
            props.setMainsubdistrict([]);
            props.setProvince('');
            props.setDistrict('');
            props.setSubdistrict('');
            props.setZipcode('');
        }
    };

    const selectDistrict = (item) => {
        if (item) {
            props.setDistrict(item);
            props.setMainsubdistrict([]);
            props.setSubdistrict('');
            props.setZipcode('');
            let thisSubdistrict = [];
            let thisindex = 0;
            for (const thissubdistrict in initaddress.subdistrict) {
                if (initaddress.subdistrict[thissubdistrict]['districtid'] === item['value']) {
                    thisSubdistrict[thisindex] = initaddress.subdistrict[thissubdistrict];
                    thisindex += 1;
                }
            }
            props.setMainsubdistrict(thisSubdistrict);
        } else {
            props.setDistrict('');
        }
    };

    const selectSubdistrict = (item) => {
        props.setSubdistrict(item);
        props.setZipcode('');
        // let thisZipcode = "";
        if (item) {
            props.setZipcode(item['zipcode']);
        } else {
            props.setSubDistrict('');
        }
    };

    const changeList = (index, key, value) => {
        let array = {};
        for (let i = 0; i < Object.keys(props.benefitlist).length; i++) {
            array[i] = props.benefitlist[i];
            if (i === index) {
                if (Array.isArray(value)) {
                    array[index][key] = value.join();
                } else {
                    array[index][key] = value;
                }
            }
        }
        props.setBenefitlist(array);
    };

    const removeBenefit = () => {
        setAmount(amount - 1);
        let array = {};
        for (let i = 0; i < parseInt(amount) - 1; i++) {
            array[i] = {
                contact_firstname: '',
                contact_lastname: '',
                contact_phonenumber: '',
                address1: '',
                address2: '',
                province: '',
                district: '',
                subdistrict: '',
                postalcode: ''
            };
        }
        // console.log(array);
        props.setBenefitlist(array);
        // console.log(props.benefitlist);
        setAction(action + 1);
    };

    const addBenefit = () => {
        setAmount(amount + 1);
        let array = {};
        for (let i = 0; i < parseInt(amount) + 1; i++) {
            array[i] = {
                contact_firstname: '',
                contact_lastname: '',
                contact_phonenumber: '',
                address1: '',
                address2: '',
                province: '',
                district: '',
                subdistrict: '',
                postalcode: ''
            };
        }
        // console.log(array);
        props.setBenefitlist(array);
        // console.log(props.benefitlist);
        setAction(action + 1);
        // console.log('add');
        // if (Object.keys(props.benefitlist).length < 3) {
        //     // console.log('doing');
        //     let object = props.benefitlist;
        //     object[Object.keys(object).length] = props.initBenefitList;
        //     // console.log(object);
        //     props.setBenefitlist(object);
        //     // console.log(props.benefitlist);
        //     setAction(action + 1);
        // }
    };

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength);
        }
    };

    return (
        <div className='form'>
            <div className='mb-3'>
                <h3 className='font-size-lg text-dark-75 font-weight-bold mb-10'>ประวัติสมาชิก</h3>
                <div className='mb-10'>
                    <div className='row m-0'>
                        <div className='col-lg-6 mb-7'>
                            <label>
                                ชื่อ<span className='required-text'>*</span>
                            </label>
                            <input
                                type='text'
                                name='firstname'
                                className='form-control rounded-lg'
                                value={props.firstname}
                                onChange={(e) => props.setFirstname(e.target.value)}
                            />
                        </div>
                        <div className='col-lg-6 mb-7'>
                            <label>
                                นามสกุล<span className='required-text'>*</span>
                            </label>
                            <input
                                type='text'
                                name='lastname'
                                className='form-control rounded-lg'
                                value={props.lastname}
                                onChange={(e) => props.setLastname(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='row m-0'>
                        <div className='col-lg-6 mb-7'>
                            <label>
                                วัน/เดือน/ปีเกิด (ปีคริสต์ศักราช)<span className='required-text'>*</span>
                            </label>
                            <InputMask
                                mask='99/99/9999'
                                alwaysShowMask={true}
                                name='birthdate'
                                inputMode='tel'
                                className='form-control rounded-lg'
                                value={props.birthdate}
                                onChange={(e) => props.setBirthdate(e.target.value)}
                            />
                        </div>
                        <div className='col-lg-6 mb-7'>
                            <label>
                                เบอร์โทรศัพท์ติดต่อ<span className='required-text'>*</span>
                            </label>
                            <input
                                type='text'
                                inputMode='tel'
                                name='contactnumber'
                                className='form-control rounded-lg'
                                value={props.contactnumber}
                                onChange={(e) => props.setContactnumber(e.target.value)}
                                maxLength='10'
                                onInput={maxLengthCheck}
                            />
                        </div>
                    </div>
                    <div className='row m-0'>
                        <div className='col-lg-6 mb-7'>
                            <label>
                                {' '}
                                สัญชาติ <span className='required-text'>*</span>
                            </label>
                            <div className='radio-inline form-control border-0 px-0'>
                                <label className='radio radio-success'>
                                    <input
                                        type='radio'
                                        name='nationality'
                                        value='1'
                                        checked={props.nationality === '1'}
                                        onChange={() => {
                                            props.setNationality('1');
                                            props.setPersonalcode('');
                                        }}
                                    />
                                    <span /> ไทย{' '}
                                </label>
                                {/* <label className='radio radio-success'>
                                    <input
                                        type='radio'
                                        name='nationality'
                                        value='2'
                                        checked={props.nationality === '2'}
                                        onChange={() => {
                                            props.setNationality('2');
                                            props.setPersonalcode('');
                                        }}
                                    />
                                    <span /> ต่างชาติ{' '}
                                </label> */}
                            </div>
                        </div>
                        <div className='col-lg-6 mb-7'>
                            <label>
                                {props.nationality === '1' ? 'หมายเลขบัตรประชาชน' : 'หมายเลขพาสปอร์ต'}
                                <span className='required-text'>*</span>
                            </label>
                            <input
                                type='text'
                                name='personalcode'
                                className='form-control rounded-lg'
                                value={props.personalcode}
                                onChange={(e) => props.setPersonalcode(e.target.value)}
                                maxLength='13'
                                onInput={maxLengthCheck}
                            />
                        </div>
                    </div>
                    <div className='row m-0'>
                        <div className='col-lg-6 mb-7'>
                            <label>
                                จังหวัด<span className='required-text'>*</span>
                            </label>
                            <Select
                                components={{
                                    Control: ControlComponent
                                }}
                                noOptionsMessage={() => 'ไม่พบข้อมูล'}
                                value={props.province}
                                options={props.mainprovince}
                                placeholder='เลือก'
                                name='province'
                                onChange={selectProvince}
                                isClearable={true}
                                menuPortalTarget={document.body}
                                styles={customStyles}
                                // isDisabled={error}
                            />
                        </div>
                        <div className='col-lg-6 mb-7'>
                            <label>
                                {props.province.value === '1' ? 'เขต' : props.province === '' ? 'อำเภอ / เขต' : 'อำเภอ'}
                                <span className='required-text'>*</span>
                            </label>
                            <Select
                                components={{
                                    Control: ControlComponent
                                }}
                                noOptionsMessage={() => 'ไม่พบข้อมูล'}
                                value={props.district}
                                options={props.maindistrict}
                                placeholder='เลือก'
                                name='district'
                                onChange={selectDistrict}
                                isClearable={true}
                                menuPortalTarget={document.body}
                                styles={customStyles}
                                isDisabled={props.province === ''}
                            />
                        </div>
                    </div>
                    <div className='row m-0'>
                        <div className='col-lg-6 mb-7'>
                            <label>
                                {props.province.value === '1' ? 'แขวง' : props.province === '' ? 'ตำบล / แขวง' : 'ตำบล'}
                                <span className='required-text'>*</span>
                            </label>
                            <Select
                                components={{
                                    Control: ControlComponent
                                }}
                                noOptionsMessage={() => 'ไม่พบข้อมูล'}
                                value={props.subdistrict}
                                options={props.mainsubdistrict}
                                placeholder='เลือก'
                                name='district'
                                onChange={selectSubdistrict}
                                isClearable={true}
                                menuPortalTarget={document.body}
                                styles={customStyles}
                                isDisabled={props.district === ''}
                            />
                        </div>
                        <div className='col-lg-6 mb-7'>
                            <label>
                                รหัสไปรษณีย์<span className='required-text'>*</span>
                            </label>
                            <input
                                type='text'
                                inputMode='tel'
                                name='zipcode'
                                className='form-control rounded-lg'
                                value={props.zipcode}
                                onChange={(e) => props.setZipcode(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='row m-0'>
                        <div className='col-lg-12 mb-md-0 mb-7'>
                            <label>
                                บ้านเลขที่ ถนน ซอย<span className='required-text'>*</span>
                            </label>
                            <TextareaAutosize
                                // id="address"
                                name='address'
                                className='form-control rounded-lg'
                                maxLength='255'
                                value={props.address1}
                                onChange={(e) => props.setAddress1(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='row justify-content-between m-0 mb-10'>
                    <div className='mr-auto'>
                        <h3 className='font-size-lg text-dark-75 font-weight-bold mb-0'>
                            รายละเอียดผู้รับโอนผลประโยชน์
                        </h3>
                        <span className='form-text text-muted mb-10'>
                            ผู้รับโอนผลประโยชน์สามารถมีสูงสุดได้ที่ 3 คนเท่านั้น
                        </span>
                    </div>
                    <div className='ml-auto'>
                        <div className='input-group'>
                            <div className='input-group-prepend'>
                                <button
                                    className='btn btn-danger'
                                    type='button'
                                    onClick={removeBenefit}
                                    disabled={amount < 2}
                                >
                                    ลด
                                </button>
                            </div>
                            <input type='text' className='form-control text-center' value={amount} readOnly />
                            <div className='input-group-append'>
                                <button
                                    className='btn btn-success'
                                    type='button'
                                    onClick={addBenefit}
                                    disabled={amount > 2}
                                >
                                    เพิ่ม
                                </button>
                            </div>
                        </div>

                        {/* {Object.keys(props.benefitlist).length < 3 && (
                            <button type='button' className='btn btn-primary rounded-lg' onClick={addBenefit}>
                                เพิ่มผู้รับโอนผลประโยชน์
                            </button>
                        )} */}
                        {/* {props.benefitlist.length > 1 && (
                            <button
                                type='button'
                                className='btn btn-primary rounded-lg ml-auto'
                                onClick={removeBenefit}
                            >
                                ลบผู้รับโอนผลประโยชน์
                            </button>
                        )} */}
                    </div>
                </div>
                {/* <h3 className='font-size-lg text-dark-75 font-weight-bold mb-0'>
                    รายละเอียดผู้รับโอนผลประโยชน์
                    <div className='ml-auto'>
                        <button type='button' className='btn btn-primary'>
                            เพิ่มผู้รับโอนผลประโยชน์
                        </button>
                    </div>
                </h3> */}
                <div className='mb-2'>
                    {/* <span className='form-text text-muted mb-10'>
                        ผู้รับโอนผลประโยชน์สามารถมีสูงสุดได้ที่ 3 คนเท่านั้น
                    </span> */}
                    {Object.values(list).map((object, index) => {
                        // console.log(object);
                        return (
                            <div className='row m-0' key={index}>
                                <div className='row col-12 p-0 m-0'>
                                    <div className='col-lg-6 mb-7'>
                                        <label>
                                            ชื่อ (คนที่ {index + 1})<span className='required-text'>*</span>
                                        </label>
                                        <input
                                            type='text'
                                            name='firstname'
                                            className='form-control rounded-lg'
                                            value={list[index]['contact_firstname']}
                                            onChange={(e) => changeList(index, 'contact_firstname', e.target.value)}
                                        />
                                    </div>
                                    <div className='col-lg-6 mb-7'>
                                        <label>
                                            นามสกุล (คนที่ {index + 1})<span className='required-text'>*</span>
                                        </label>
                                        <input
                                            type='text'
                                            name='lastname'
                                            className='form-control rounded-lg'
                                            value={list[index]['contact_lastname']}
                                            onChange={(e) => changeList(index, 'contact_lastname', e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='row col-12 p-0 m-0'>
                                    <div className='col-lg-6 mb-7'>
                                        <label>
                                            เบอร์โทรศัพท์ติดต่อ (คนที่ {index + 1})<span className='required-text'>*</span>
                                        </label>
                                        <input
                                            type='text'
                                            inputMode='tel'
                                            name='contactnumber'
                                            className='form-control rounded-lg'
                                            value={list[index]['contact_phonenumber']}
                                            onChange={(e) => changeList(index, 'contact_phonenumber', e.target.value)}
                                            maxLength='10'
                                            onInput={maxLengthCheck}
                                        />
                                    </div>
                                </div>
                                <div className='row col-12 p-0 m-0'>
                                    <div className='col-lg-6 mb-7'>
                                        <label>
                                            จังหวัด (คนที่ {index + 1})<span className='required-text'>*</span>
                                        </label>
                                        <input
                                            type='text'
                                            name='province'
                                            className='form-control rounded-lg'
                                            value={list[index]['province']}
                                            onChange={(e) => changeList(index, 'province', e.target.value)}
                                        />
                                    </div>
                                    <div className='col-lg-6 mb-7'>
                                        <label>
                                            อำเภอ / เขต (คนที่ {index + 1})
                                            <span className='required-text'>*</span>
                                        </label>
                                        <input
                                            type='text'
                                            name='district'
                                            className='form-control rounded-lg'
                                            value={list[index]['district']}
                                            onChange={(e) => changeList(index, 'district', e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='row col-12 p-0 m-0'>
                                    <div className='col-lg-6 mb-7'>
                                        <label>
                                            ตำบล / แขวง (คนที่ {index + 1})<span className='required-text'>*</span>
                                        </label>
                                        <input
                                            type='text'
                                            name='subdistrict'
                                            className='form-control rounded-lg'
                                            value={list[index]['subdistrict']}
                                            onChange={(e) => changeList(index, 'subdistrict', e.target.value)}
                                        />
                                    </div>
                                    <div className='col-lg-6 mb-7'>
                                        <label>
                                            รหัสไปรษณีย์ (คนที่ {index + 1})
                                            <span className='required-text'>*</span>
                                        </label>
                                        <input
                                            type='text'
                                            inputMode='tel'
                                            name='postalcode'
                                            className='form-control rounded-lg'
                                            value={list[index]['postalcode']}
                                            onChange={(e) => changeList(index, 'postalcode', e.target.value)}
                                            maxLength='5'
                                            onInput={maxLengthCheck}
                                        />
                                    </div>
                                </div>
                                <div className='row col-12 p-0 mx-0 mb-7'>
                                    <div className='col-lg-12 mb-md-0 mb-7'>
                                        <label>
                                            บ้านเลขที่ ถนน ซอย<span className='required-text'>*</span>
                                        </label>
                                        <TextareaAutosize
                                            // id="address"
                                            name='address'
                                            className='form-control rounded-lg'
                                            maxLength='255'
                                            value={list[index]['address1']}
                                            onChange={(e) => changeList(index, 'address1', e.target.value)}
                                        />
                                    </div>
                                </div>
                                {/* {index !== props.benefitlist.length && (
                                    <div className='separator separator-dashed mt-10 mb-5' />
                                )} */}
                            </div>
                        );
                    })}
                </div>
            </div>
            {/* <div className='separator separator-dashed my-10' />
            <div className='mb-3'>
                <h3 className='font-size-lg text-dark-75 font-weight-bold mb-10'>
                    Billing Address
                    <i
                        data-toggle='tooltip'
                        data-width='auto'
                        className='mb-3__help'
                        title='If different than the corresponding address'
                    />
                </h3>
                <div className='mb-2'>
                    <div className='form-group row'>
                        <div className='col-lg-12'>
                            <label>* Address 1:</label>
                            <input
                                type='text'
                                name='billing_address_1'
                                className='form-control'
                                placeholder
                                defaultValue
                            />
                        </div>
                    </div>
                    <div className='form-group row'>
                        <div className='col-lg-12'>
                            <label>Address 2:</label>
                            <input
                                type='text'
                                name='billing_address_2'
                                className='form-control'
                                placeholder
                                defaultValue
                            />
                        </div>
                    </div>
                    <div className='form-group row'>
                        <div className='col-lg-5'>
                            <label>* City:</label>
                            <input type='text' className='form-control' name='billing_city' placeholder defaultValue />
                        </div>
                        <div className='col-lg-5'>
                            <label>* State:</label>
                            <input type='text' className='form-control' name='billing_state' placeholder defaultValue />
                        </div>
                        <div className='col-lg-2'>
                            <label>* ZIP:</label>
                            <input type='text' className='form-control' name='billing_zip' placeholder defaultValue />
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    );
}
