import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import { Modal } from 'react-bootstrap';

import { QRCode } from 'react-qrcode-logo';

import Moment from 'moment';

import html2canvas from 'html2canvas';

import Swal from 'sweetalert2/dist/sweetalert2.js';

import NumberFormat from 'react-number-format';

import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';

import config from '../../../../config';

const SwalBs = Swal.mixin({
    customClass: {
        popup: 'rounded-lg',
        confirmButton: 'btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto',
        cancelButton: 'btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto'
    },
    buttonsStyling: false
});

export function StockReportMain() {
    const { info } = useSelector((state) => state.auth);
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState(false);
    const [ emptyaccount, setEmptyAccount ] = useState(true);
    const [ select, setSelect ] = useState(null);
    const [ accountlist, setAccountList ] = useState([]);
    const [ transaction, setTransaction ] = useState({});
    const [ modal, setModal ] = useState(false);
    const [ orderdetail, setOrderdetail ] = useState({});

    useEffect(() => {
        getAccountList()
    }, []);

    const handleModalClose = () => setModal(false);
    const handleModalShow = (orderid) => {
        getOrderDetail(orderid);
    };

    const handleDownload = () => {
        let d = new Date();
        html2canvas(document.querySelector('#react-qrcode-logo')).then(function(canvas) {
            const link = document.createElement('a');
            link.download = `payment-${Moment(d).format('YYYYMMDDhhmmss')}.png`;
            link.href = canvas.toDataURL();
            link.click();
        });
    };

    const getOrderDetail = (orderid) => {
        setLoading(true);
        fetch(config.url + 'order/get', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: info.info.token,
                id: orderid.toString()
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    setOrderdetail(result);
                    setModal(true);
                } else {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `${result.responsecode}! ไม่สามารถดึงรายละเอียดเพิ่มเติมได้ กรุณาลองใหม่อีกครั้ง`,
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return false;
                }
            })
            .catch((error) => {
                setLoading(false);
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: 'ไม่สามารถดึงรายละเอียดเพิ่มเติมได้ กรุณาลองใหม่อีกครั้ง',
                    icon: 'error',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return false;
            });
    }

    const getAccountList = () => {
        setAccountList([]);
        setLoading(true);
        fetch(config.url + 'account/get-balance', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: info.info.token
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    setAccountList(Object.values(result.account));
                    for (const key in result.account) {
                        if (result.account[key]['type'] === '1') {
                            setEmptyAccount(false);
                        }
                    }
                } else {
                    setError(true);
                }
            })
            .catch((error) => {
                setLoading(false);
                setError(true);
            });
    }

    const getAccountTransaction = (accountid) => {
        setTransaction({});
        setLoading(true);
        setSelect(accountid);
        fetch(config.url + 'account/get-transaction', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: info.info.token,
                account_number: accountid.toString()
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                // console.log(result);
                setTransaction(result);
            })
            .catch((error) => {
                setLoading(false);
                setTransaction({
                    responsecode: 100
                });
            });
    };
    return (
        <div>
            {loading ? (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    {!error ? (
                        <>
                            <div className='flex-row-fluid col-12 p-0 m-0'>
                                <div className='card card-custom card-stretch' id='stock-page'>
                                    <div className='card-header bg-primary text-white'>
                                        <div className='card-title'>
                                            <span className='card-icon'>
                                                <i className='fas fa-wallet text-white' />
                                            </span>
                                            <h3 className='card-label text-white'>กระเป๋าที่คุณมี</h3>
                                        </div>
                                    </div>
                                    <div className='card-body pt-4'>
                                        {emptyaccount ? (
                                            <div className='balance-info-height'>
                                                <div className='row justify-content-center align-items-center min-h-175px'>
                                                    <h1>ไม่พบกระเป๋าที่คุณมี</h1>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className='d-flex align-items-center'>
                                                <div className='cards-menu w-100'>
                                                    <div className='card-list testimonial-group'>
                                                        <div className='inline-scroll'>
                                                            {accountlist.map((object, index) => {
                                                                if (object.type === '1') {
                                                                    return (
                                                                        <div
                                                                            className='inline-card'
                                                                            key={index}
                                                                            onClick={(e) => getAccountTransaction(object.number)}
                                                                        >
                                                                            <div
                                                                                className={`card cursor-pointer ${select === object.number
                                                                                    ? 'sim-active'
                                                                                    : ''}`}
                                                                            >
                                                                                <div className='symbol symbol-40 symbol-light-white mr-5'>
                                                                                    <div className='symbol-label'>
                                                                                        <i
                                                                                            className={`fas fa-${object.type === '1'
                                                                                                ? 'university'
                                                                                                : 'users'} fa-2x`}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <p className='card-number font-size-h6 m-0'>
                                                                                    {object.type === '1' ? 'หุ้นสหกรณ์' : 'ฌาปนกิจ'}
                                                                                </p>
                                                                                <p className='card-number font-size-h6 m-0 ml-auto'>
                                                                                    <NumberFormat
                                                                                        value={parseFloat(object.balance)}
                                                                                        displayType={'text'}
                                                                                        decimalScale={2}
                                                                                        fixedDecimalScale={true}
                                                                                        thousandSeparator={true}
                                                                                        suffix={' หุ้น'}
                                                                                    />
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            {Object.keys(transaction).length > 0 && (
                                <div className='example example-basic col-12 p-0 mt-7 bg-white rounded-lg mb-7'>
                                    <div className='example-preview' id='transactionpage'>
                                        {transaction.responsecode === 100 || transaction.responsecode === 101 ? (
                                            <div className='balance-info-height'>
                                                <div className='row justify-content-center align-items-center h-100'>
                                                    <h1>มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง</h1>
                                                </div>
                                            </div>
                                        ) : transaction.responsecode === 102 ? (
                                            <div className='balance-info-height'>
                                                <div className='row justify-content-center align-items-center h-100'>
                                                    <h1>ไม่พบรายการธุรกรรม</h1>
                                                </div>
                                            </div>
                                        ) : (
                                            <OverlayScrollbarsComponent className='balance-info-height'>
                                                <div className='timeline timeline-3'>
                                                    <div className='timeline-items'>
                                                        {transaction.info.map((object, index) => {
                                                            return (
                                                                <div className='timeline-item' key={index}>
                                                                    <div className='timeline-media'>
                                                                        <i
                                                                            className={`icon-lg fas ${object.type === 'withdraw'
                                                                                ? 'fa-minus-circle text-danger'
                                                                                : 'fa-plus-circle text-success'}`}
                                                                        />
                                                                    </div>
                                                                    <div className='timeline-content'>
                                                                        <div className='d-flex align-items-center justify-content-between mb-3'>
                                                                            <div className='text-dark'>ซื้อหุ้นจำนวน {object.amount} หุ้น ราคา {object.total} บาท</div>
                                                                            <div className='ml-auto'>
                                                                                <span className='text-muted'>{object.date}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className='d-flex align-items-center justify-content-end mb-3'>
                                                                            <div className='ml-auto'>
                                                                                <button type='button' className='btn btn-primary btn-sm rounded-lg' onClick={(e) => handleModalShow(object.order_id)}>
                                                                                    รายละเอียดเพิ่มเติม
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </OverlayScrollbarsComponent>
                                        )}
                                    </div>
                                </div>
                            )}
                        </>
                    ) : (
                        <div className='h-550px'>
                            <div className='row justify-content-center align-items-center h-100'>
                                <h1>ไม่สามารถดึงยอดหุ้น</h1>
                            </div>
                        </div>
                    )}
                </>
            )}

            {Object.keys(orderdetail).length > 0 && (
                <Modal
                    show={modal}
                    onHide={handleModalClose}
                    size='lg'
                    centered
                    aria-labelledby='qrcode-modal'
                >
                    <Modal.Header closeButton>
                        <Modal.Title id='qrcode-modal' className='col-11 p-0'>
                            รายละเอียด
                        </Modal.Title>
                        <button type='button' className='close col-1 text-right pr-3' onClick={handleModalClose}>
                            <i className='ki ki-close' />
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row justify-content-center'>
                            {orderdetail.orders.payment === 'QR-Code' && orderdetail.orders.status === 'pending' && (
                                <div className='col-12 p-0'>
                                    <div className='text-center'>
                                        <QRCode
                                            className='border'
                                            value={orderdetail.orders.qr}
                                            size={256}
                                            eyeRadius={10}
                                            logoImage={toAbsoluteUrl('/media/logos/cooperative-logo-bg.png')}
                                            logoWidth={100}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="col-12 p-0">
                                <div className="bg-light rounded-lg d-flex flex-column p-3">
                                    <div className="p-2 d-flex">
                                        <div className="col-6">
                                            ชำระเงินผ่านช่องทาง
                                        </div>
                                        <div className="ml-auto text-right">
                                            {orderdetail.orders.payment === 'QR-Code' ? 'คิวอาร์โค้ด' : 'กระเป๋าปันสุข'}
                                        </div>
                                    </div>
                                    <div className="p-2 d-flex">
                                        <div className="col-6">
                                            สถานะการทำรายการ
                                        </div>
                                        <div className="ml-auto text-right">
                                            {orderdetail.orders.status === 'paid' ? 'ดำเนินการเรียบร้อย' : 'รอการชำระเงิน'}
                                        </div>
                                    </div>
                                    <div className="p-2 d-flex">
                                        <div className="col-6">
                                            ยอดรวม
                                        </div>
                                        <div className="ml-auto text-right">
                                            <NumberFormat
                                                value={parseFloat(orderdetail.orders.total_gross_price)}
                                                displayType={'text'}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                                suffix={' บาท'}
                                            />
                                        </div>
                                    </div>
                                    <div className="p-2 d-flex">
                                        <div className="col-12">
                                            รายละเอียดค่าบริการ
                                        </div>
                                    </div>
                                    <div className="p-2 d-flex">
                                        <div className="col-12">
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th className="pl-0 font-weight-bold text-muted text-uppercase">ชื่อบริการ</th>
                                                            <th className="text-right font-weight-bold text-muted text-uppercase">จำนวน</th>
                                                            <th className="text-right font-weight-bold text-muted text-uppercase">ราคาต่ออัน</th>
                                                            <th className="text-right pr-0 font-weight-bold text-muted text-uppercase">ราคารวม</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {orderdetail.orders.order_item.map((object, index) => {
                                                            return(
                                                                <tr className="font-weight-boldest font-size-lg" key={index}>
                                                                    <td className="pl-0 pt-7">{object.item_name}</td>
                                                                    <td className="text-right pt-7">{object.quantity}</td>
                                                                    <td className="text-right pt-7">
                                                                        <NumberFormat
                                                                            value={parseFloat(object.price)}
                                                                            displayType={'text'}
                                                                            decimalScale={2}
                                                                            fixedDecimalScale={true}
                                                                            thousandSeparator={true}
                                                                            suffix={' บาท'}
                                                                        />
                                                                    </td>
                                                                    <td className="text-primary pr-0 pt-7 text-right">
                                                                        <NumberFormat
                                                                            value={parseFloat(object.gross_price)}
                                                                            displayType={'text'}
                                                                            decimalScale={2}
                                                                            fixedDecimalScale={true}
                                                                            thousandSeparator={true}
                                                                            suffix={' บาท'}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    {orderdetail.orders.payment === 'QR-Code' && orderdetail.orders.status === 'pending' && (
                        <Modal.Footer>
                            <div className='row justify-content-end m-0'>
                                <div className='col-12 p-0'>
                                    <button type='button' className='btn btn-primary rounded-lg' onClick={handleDownload}>
                                        บันทึกคิวอาร์โค้ด
                                    </button>
                                </div>
                            </div>
                        </Modal.Footer>
                    )}
                </Modal>
            )}
        </div>
    );
}
