import React from 'react';

import { NavLink } from 'react-router-dom';

export function PageNotFound() {
    return (
        <div className='error404'>
            <div className='center'>
                {/* <div className='error'>
                    <div className='number'>4</div>
                    <div className='illustration'>
                        <div className='circle' />
                        <div className='clip'>
                            <div className='paper'>
                                <div className='face'>
                                    <div className='eyes'>
                                        <div className='eye eye-left' />
                                        <div className='eye eye-right' />
                                    </div>
                                    <div className='rosyCheeks rosyCheeks-left' />
                                    <div className='rosyCheeks rosyCheeks-right' />
                                    <div className='mouth' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='number'>4</div>
                </div> */}
                <div className='text font-size-h3 font-weight-boldest'>ขออภัย ไม่พบหน้าที่คุณต้องการ</div>
                <div className='mt-5 font-weight-bold'>กรุณาตรวจสอบลิ้งค์ที่คุณเข้ามา หรือกลับไปหน้าแรก</div>
                <NavLink className='button rounded-lg btn btn-primary font-size-h6 font-weight-boldest' to='stock'>
                    กลับไปหน้าแรก
                </NavLink>
            </div>
        </div>
    );
}
