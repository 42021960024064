import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';

import Swal from 'sweetalert2/dist/sweetalert2.js';

import { CremationReservResult } from './components';

import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';

import config from '../../../../config';

const isToday = (date) => {
    const today = new Date();
    if (
        today.getFullYear() >= date.getFullYear() ||
        today.getMonth() >= date.getMonth() ||
        today.getDate() >= date.getDate()
    ) {
        return true;
    }
    return false;
};

const SwalBs = Swal.mixin({
    customClass: {
        popup: 'rounded-lg',
        confirmButton: 'btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto',
        cancelButton: 'btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto'
    },
    buttonsStyling: false
});

export function CremationReservationMain() {
    const history = useHistory();

    const { info } = useSelector((state) => state.auth);
    const [ page, setPage ] = useState('main');
    const [ loading, setloading ] = useState(true);
    const [ paymenttype, setPaymenttype ] = useState('1');
    const [ orderres, setOrderres ] = useState({});

    useEffect(() => {
        if (isToday(new Date('2022-09-01'))) {
            history.push('/');
        }
        getReservation({
            token: info.info.token
        });
    }, []);

    const btnNext = () => {
        if (paymenttype === '1') {
            let param = {
                token: info.info.token,
                payment_channel: 'bay-qr'
            };
            reserv(param);
        } else if (paymenttype === '3') {
            let param = {
                token: info.info.token,
                payment_channel: 'credit-card'
            };
            reserv(param);
        }
    };

    const getReservation = (param) => {
        setOrderres({});
        setloading(true);
        fetch(config.url + 'member/reservation-info', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify(param)
        })
            .then((response) => response.json())
            .then((result) => {
                setloading(false);
                // console.log(result);
                if (result['responsecode'] == 0) {
                    if (result['info']['status'] == 'pending') {
                        setOrderres(result);
                        setPage('result');
                    } else if (result['info']['status'] == 'paid') {
                        setPage('paid');
                    }
                }
            })
            .catch((error) => {
                setloading(false);
                setOrderres({
                    responsecode: 99
                });
                setPage('result');
            });
    };
    const reserv = (param) => {
        setOrderres({});
        setloading(true);
        fetch(config.url + 'member/reservation', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify(param)
        })
            .then((response) => response.json())
            .then((result) => {
                if (paymenttype === '2') {
                    if (result.responsecode === 110) {
                        setloading(false);
                        SwalBs.fire({
                            // title: 'ยกเลิกคำสั่ง',
                            html: 'ไม่สามารถชำระค่าบริการด้วยกระเป๋าซิมปันสุขได้ กรุณาลองใหม่ด้วยคิวอาร์โค้ด',
                            icon: 'warning',
                            // showCancelButton: true,
                            confirmButtonText: 'รับทราบ',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        }).then((result) => {
                            if (result.isConfirmed) {
                                setPaymenttype('1');
                                // setDisablewallet(true);
                                setPage('main');
                            }
                        });
                        return;
                    }
                }
                setloading(false);
                setOrderres(result);
                setPage('result');
            })
            .catch((error) => {
                setloading(false);
                setOrderres({
                    responsecode: 99
                });
                setPage('result');
            });
    };

    return (
        <div className='d-block' id='stock-order'>
            {loading && (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            )}

            <div className='text-center mb-10 mb-lg-20'>
                <h3 className='font-size-h1'>จองแท็บเล็ต</h3>
                <p className='text-muted font-weight-bold'>กลุ่มฌาปนกิจ</p>
            </div>

            {page === 'main' ? (
                <div>
                    {!loading && (
                        <div className='card card-custom gutter-b rounded-lg'>
                            <div className='card-body d-flex rounded bg-primary p-md-12 p-0 justify-content-center rounded-lg'>
                                <div className='row m-0'>
                                    <div className='col-lg-7 text-center my-7 my-md-auto'>
                                        <img
                                            className='max-h-md-500px max-h-250px'
                                            src={toAbsoluteUrl('/media/misc/reservation-tablet-220830.png')}
                                        />
                                    </div>
                                    <div className='col-lg-5 mb-7 mb-md-0'>
                                        <div className='card card-custom ml-auto'>
                                            <div className='card-body px-12 py-10'>
                                                <h3 className='font-weight-bolder font-size-h2 mb-1'>
                                                    เป็นเจ้าของ Tablet ได้แล้ววันนี้
                                                </h3>
                                                <div className='font-size-sm mb-8 mt-5'>
                                                    <p>🎉เปิดให้จองแล้วววว‼️</p>
                                                    <p className='mb-0'>🥰เป็นเจ้าของ Tablet ได้แล้ววันนี้</p>
                                                    <p>
                                                        👉สิทธิพิเศษสำหรับสมาชิกสมาคมฌาปนกิจสงเคราะห์สมาชิกกลุ่มเครดิตยูเนี่ยนครอบครัวซิมปันสุข
                                                    </p>
                                                    <p className='mb-0'>
                                                        📌ได้สิทธิ์จอง Tablet พร้อมแพ็กเกจอินเทอร์เน็ตสุดคุ้ม🚀
                                                    </p>
                                                    <p className='mb-0'>กับซิมปันสุข 15 Mbps Unlimited นาน 365 วัน🌟</p>
                                                    <p>😍ในราคาเพียง 1000.- เท่านั้น ‼️</p>
                                                    <p className='mb-0'>
                                                        💕เริ่มต้นผ่อนที่ 500.- / เดือน นานสูงสุด 9 เดือน 🚀
                                                    </p>
                                                    <p>🥰เปิดสิทธิ์จำนวนจำกัดเพียง 500 เซตเท่านั้น‼️</p>
                                                    <p>📆 ตั้งแต่วันนี้เป็นต้นไป - 31 สิงหาคม 2565</p>
                                                    <p className='mb-0'>** เงื่อนไขเป็นไปตามที่บริษัทกำหนด</p>
                                                    <p className='mb-0'>** ภาพใช้ประกอบการโฆษณาเท่านั้น</p>
                                                    <p className='mb-0'>
                                                        **
                                                        สิทธิพิเศษสำหรับสมาชิกสมาคมฌาปนกิจสงเคราะห์สมาชิกกลุ่มเครดิตยูเนี่ยนครอบครัวซิมปันสุขเท่านั้น
                                                    </p>
                                                    <p className='mb-0'>
                                                        ** บริษัทจะทำการคืนเงินเมื่อมียอดสั่งจองไม่ถึง 500 เซต
                                                    </p>
                                                    <p className='mb-0'>
                                                        **
                                                        บริษัทขอสงวนสิทธิ์ในการเปลี่ยนแปลงเงื่อนไขและรายละเอียดโดยไม่ต้องแจ้งให้ทราบล่วงหน้า
                                                    </p>
                                                </div>
                                                <button
                                                    type='button'
                                                    className='btn btn-block btn-primary'
                                                    onClick={btnNext}
                                                >
                                                    จองทันที
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ) : page === 'result' ? (
                <CremationReservResult orderres={orderres} paymenttype={paymenttype} />
            ) : page === 'paid' ? (
                <div>
                    <label>
                        <img
                            alt='thaiqr-logo'
                            src='https://cdn.simk4.com/payment/reservation.jpg'
                            className='full-width'
                        />
                    </label>
                    <center>
                        <h1>จองแล้ว</h1>
                    </center>
                </div>
            ) : page === 'stock-out' ? (
                <div>
                    <label>
                        <img
                            alt='thaiqr-logo'
                            src='https://cdn.simk4.com/payment/reservation.jpg'
                            className='full-width'
                        />
                    </label>
                    <center>
                        <h1 style={{ color: 'red' }}>จำนวนจองเต็มแล้ว</h1>
                    </center>
                </div>
            ) : null}
        </div>
    );
}
