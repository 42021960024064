import React, { Suspense } from 'react';
import { Redirect, Switch, useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LayoutSplashScreen, ContentRoute } from '../_metronic/layout';
// import {BuilderPage} from "./pages/BuilderPage";
// import {MyPage} from "./pages/MyPage";
// import {DashboardPage} from "./pages/DashboardPage";
import { PageNotFound } from './pages/customs/Error/pagenotfound';
import CremationRegisterMain from './pages/customs/Cremation/register/main';
import { CremationQRcodeMain } from './pages/customs/Cremation/qrcode/main';
import { CremationReservationMain } from './pages/customs/Cremation/reservation/main';
import { CremationDepositMain } from './pages/customs/Cremation/deposit/main';
import { CremationReportMain } from './pages/customs/Cremation/report/main';
import { CremationOrderReportMain } from './pages/customs/Cremation/orderreport/main';
import { UserInformationMain } from './pages/customs/User/information/main';
import { StockOrderMain } from './pages/customs/Stock/order/main';
import { StockReportMain } from './pages/customs/Stock/report/main';
import { OrderReportMain } from './pages/customs/Stock/orderreport/main';
import { StockBillMain } from './pages/customs/Stock/bill/main';
import { FundOrderMain } from './pages/customs/Fund/order/main';
import { FundOrderReportMain } from './pages/customs/Fund/orderreport/main';
import { FundReportMain } from './pages/customs/Fund/report/main';
import BankSettingMain from './pages/customs/Bank/setting/main';
import { CashWithdrawMain } from './pages/customs/Cash/withdraw/main';
import { FundOrderMain as Fund2206001OrderMain } from './pages/customs/Fund2206001/order/main';
import { FundOrderReportMain as Fund2206001OrderReportMain } from './pages/customs/Fund2206001/orderreport/main';
import { FundReportMain as Fund2206001ReportMain } from './pages/customs/Fund2206001/report/main';

import config from './config';

// const GoogleMaterialPage = lazy(() =>
//   import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
// );
// const ReactBootstrapPage = lazy(() =>
//   import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
// );
// const ECommercePage = lazy(() =>
//   import("./modules/ECommerce/pages/eCommercePage")
// );

function usePageViews() {
    const { info } = useSelector((state) => state.auth);
    let location = useLocation();
    let history = useHistory();
    React.useEffect(
        () => {
            // console.log([ 'pageview', location.pathname ]);
            if (new Date() >= new Date(info.login_time)) {
                history.push('/logout');
            } else {
                fetch(config.url + 'member/check-status', {
                    method: 'post',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                        // secret: config.secret,
                    },
                    body: JSON.stringify({
                        token: info?.info?.token
                    })
                })
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.responsecode !== 0) {
                            history.push('/logout');
                        }
                    })
                    .catch((error) => {
                        history.push('/logout');
                    });
            }
        },
        [ location ]
    );
}

export default function BasePage() {
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect
    usePageViews();

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from='/' to='/user/information' />
                }
                {/* <ContentRoute path="/dashboard" component={DashboardPage}/> */}
                <ContentRoute path='/user/information' component={UserInformationMain} />
                <ContentRoute path='/cremation/register' component={CremationRegisterMain} />
                {/* <ContentRoute path='/cremation/qrcode' component={CremationQRcodeMain} /> */}
                <ContentRoute path='/cremation/reservation' component={CremationReservationMain} />
                <ContentRoute path='/cremation/deposit' component={CremationDepositMain} />
                <ContentRoute path='/cremation/report' component={CremationReportMain} />
                <ContentRoute path='/cremation/orderreport' component={CremationOrderReportMain} />
                <ContentRoute path='/cooperative/stockorder' component={StockOrderMain} />
                <ContentRoute path='/cooperative/stockreport' component={StockReportMain} />
                <ContentRoute path='/cooperative/orderreport' component={OrderReportMain} />
                <ContentRoute path='/fund/deposit' component={FundOrderMain} />
                <ContentRoute path='/fund/orderreport' component={FundOrderReportMain} />
                <ContentRoute path='/fund/report' component={FundReportMain} />
                <ContentRoute path='/bank/setting' component={BankSettingMain} />
                <ContentRoute path='/cash/withdraw' component={CashWithdrawMain} />
                <ContentRoute path='/fund2206001/deposit' component={Fund2206001OrderMain} />
                <ContentRoute path='/fund2206001/orderreport' component={Fund2206001OrderReportMain} />
                <ContentRoute path='/fund2206001/report' component={Fund2206001ReportMain} />
                {/* <ContentRoute path='/cooperative/bill' component={StockBillMain} /> */}
                <ContentRoute path='/404' component={PageNotFound} />
                {/* <ContentRoute path="/builder" component={BuilderPage}/>
                <ContentRoute path="/my-page" component={MyPage}/>
                <Route path="/google-material" component={GoogleMaterialPage}/>
                <Route path="/react-bootstrap" component={ReactBootstrapPage}/>
                <Route path="/e-commerce" component={ECommercePage}/> */}
                <Redirect to='/404' />
            </Switch>
        </Suspense>
    );
}
