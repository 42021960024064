import React from 'react';

import { components } from 'react-select';

const controlStyles = {
    // borderRadius: '0.85rem',
    // borderColor: '#E4E6EF',
    // borderStyle: 'solid',
    // borderWidth: '1px',
    // padding: '5px',
    // background: '#F3F6F9',
    color: 'white'
};

export const ControlComponent = (props) => (
    <div style={controlStyles}>
        {/* {<p>Custom Control</p>} */}
        <components.Control {...props} />
    </div>
);

export const customStyles = {
    menu: (provided, state) => ({
        ...provided
        // backgroundColor: '#F3F6F9'
    }),
    menuPortal: (base) => ({
        ...base,
        zIndex: 9999
    }),
    control: (provided, state) => ({
        ...provided,
        // borderStyle: 'none',
        backgroundColor: state.isDisabled ? '#F3F6F9' : 'transparent',
        boxShadow: 'none',
        borderRadius: '0.85rem',
        borderColor: state.isFocused ? '#3699FF' : '#E4E6EF',
        borderStyle: 'solid',
        borderWidth: '1px'
        // '&:disabled': { backgroundColor: '#F3F6F9' }
        // borderColor: '#E4E6EF'
    })
};
