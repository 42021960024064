import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import Swal from 'sweetalert2/dist/sweetalert2.js';

import { FundOrderForm, FundOrderResult } from './components';

import config from '../../../../config';

const SwalBs = Swal.mixin({
    customClass: {
        popup: 'rounded-lg',
        confirmButton: 'btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto',
        cancelButton: 'btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto'
    },
    buttonsStyling: false
});

export function FundOrderMain() {
    const { info } = useSelector((state) => state.auth);

    const [ page, setPage ] = useState('main');
    const [ loading, setLoading ] = useState(false);
    const [ amount, setAmount ] = useState(0);
    const [ paymenttype, setPaymenttype ] = useState('1');
    const [ orderres, setOrderres ] = useState({});

    const btnNext = () => {
        if (amount === 0 || amount === '' || amount === '0') {
            SwalBs.fire({
                // title: 'ยกเลิกคำสั่ง',
                html: 'กรุณาใส่จำนวนเงินที่ต้องการลงทุน',
                icon: 'warning',
                // showCancelButton: true,
                confirmButtonText: 'รับทราบ',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
            return false;
        }

        const channel = {'1':'bay-qr','3':'credit-card','2':'punsook-wallet'}
        let param = {
            token: info.info.token,
            quantity: amount.toString(),
            payment_channel: channel[paymenttype],
        };
        order(param);
    };

    const order = (param) => {
        setOrderres({});
        setLoading(true);
        fetch(config.url + 'special-investment/create', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify(param)
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                setOrderres(result);
                setPage('result');
            })
            .catch((error) => {
                setLoading(false);
                setOrderres({
                    responsecode: 99
                });
                setPage('result');
            });
    };
    return (
        <div className='d-block' id='fund-order'>
            {loading && (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            )}

            <div className='text-center mb-10 mb-lg-20'>
                <h3 className='font-size-h1'>ซื้อหน่วยลงทุนพิเศษ</h3>
                <p className='text-muted font-weight-bold'>กลุ่มเครดิตยูเนี่ยนครอบครัวซิมปันสุข</p>
            </div>

            {page === 'main' ? (
                <FundOrderForm
                    amount={amount}
                    setAmount={setAmount}
                    paymenttype={paymenttype}
                    setPaymenttype={setPaymenttype}
                    btnNext={btnNext}
                />
            ) : page === 'result' ? (
                <FundOrderResult orderres={orderres} />
            ) : null}
        </div>
    );
}
