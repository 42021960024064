import React from 'react';

import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import { toAbsoluteUrl } from '../../../../../../_metronic/_helpers';

export function CremationRegisterTerm(props) {
    return (
        <div>
            <OverlayScrollbarsComponent className='h-300px'>
                <div className='row-inner p-5 bg-code-editor'>
                    <div className='story-head text-center'>
                        <h1>ข้อบังคับ</h1>
                        <h1>สมาคมฌาปนกิจสงเคราะห์</h1>
                        <h1>สมาชิกกลุ่มเครดิตยูเนี่ยนครอบครัวซิมปันสุข พ.ศ. 2564</h1>
                        <p>********************************************</p>
                    </div>
                    <div>
                        <div className='row-panel '>
                            <div className='row-inner '>
                                <div>
                                    <p className='m-0'>
                                        <b>ข้อ 1.</b> ข้อบังคับนี้เรียกว่า
                                        “ข้อบังคับสมาคมฌาปนกิจสงเคราะห์สมาชิกกลุ่มเครดิตยูเนี่ยนครอบครัวซิมปันสุข พ.ศ.
                                        2564”
                                    </p>
                                    <p className='m-0'>
                                        <b>ข้อ 2.</b> ข้อบังคับนี้ให้ใช้บังคับ
                                        นับตั้งแต่วันที่นายทะเบียนสมาคมฌาปนกิจสงเคราะห์ประจำท้องที่กรุงเทพมหานครรับจดทะเบียน
                                    </p>
                                    <p>
                                        <b>ข้อ 3.</b>{' '}
                                        เครื่องหมายของสมาคมฌาปนกิจสงเคราะห์สมาชิกกลุ่มเครดิตยูเนี่ยนครอบครัวซิมปันสุข
                                        เป็นรูปลักษณะมีความหมายว่า
                                    </p>
                                    <div className='row justify-content-between m-0'>
                                        <div className='col-lg-6 col-12 p-0 text-center my-auto'>
                                            <img
                                                className='max-h-150px'
                                                alt='cooperative-logo'
                                                src={toAbsoluteUrl('/media/logos/cooperative-logo.png')}
                                            />
                                        </div>
                                        <div className='col-lg-6 col-12 p-0'>
                                            <p>
                                                <b>รูปโลก</b> : เป็นองค์ประกอบของสัญลักษณ์นี้
                                                เพื่อแสดงให้เห็นว่าเป็นองค์กรประเภทหนึ่งที่มีอยู่ ทั่วโลก
                                            </p>
                                            <p>
                                                <b>ภาพเงาคน 4 คน</b> : แสดงถึงหน่วยสถาบันครอบครัวของมนุษยชาติ
                                            </p>
                                            <p>
                                                <b>รูปมือคู่</b> : แสดงถึงคุณลักษณะแห่งการช่วยเหลือ มีอักษรคำว่า
                                                “สมาคมฌาปนกิจสงเคราะห์สมาชิกกลุ่มเครดิตยูเนี่ยนครอบครัวซิมปันสุข”
                                            </p>
                                            <p>
                                                <b>รูปคลื่นด้านล่าง</b> : แสดงถึงรากฐานที่มั่นคงและแสดงถึงความอ่อนโยน
                                            </p>
                                            <p>
                                                <b>รูปแก้วครอบ</b> : แสดงถึงการปกป้องและคุ้มครอง ภายนอกมีวงกลม 2 วง
                                                เป็นเส้นเล็กและเส้นหนาล้อมรอบ
                                            </p>
                                        </div>
                                    </div>
                                    <h3 className='text-center m-0'>หมวด 1</h3>
                                    <h3 className='text-center'>ข้อความทั่วไป</h3>
                                    <p className='m-0'>
                                        <b>ข้อ 4.</b> ในข้อบังคับนี้
                                    </p>
                                    <div className='table-container'>
                                        <table className='table table-borderless'>
                                            <tbody>
                                                <tr>
                                                    <td className='col-2'>
                                                        <b>“สมาคม”</b>
                                                    </td>
                                                    <td className='col-2'>หมายความว่า</td>
                                                    <td className='col-8'>
                                                        สมาคมฌาปนกิจสงเคราะห์ของสมาชิกกลุ่มเครดิตยูเนี่ยน
                                                        ครอบครัวซิมปันสุข
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='col-2'>
                                                        <b>“สมาชิก”</b>
                                                    </td>
                                                    <td className='col-2'>หมายความว่า</td>
                                                    <td className='col-8'>
                                                        สมาชิกของสมาคมฌาปนกิจสงเคราะห์สมาชิกกลุ่มเครดิตยูเนี่ยนครอบครัวซิมปันสุข
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='col-2'>
                                                        <b>“คณะกรรมการ”</b>
                                                    </td>
                                                    <td className='col-2'>หมายความว่า</td>
                                                    <td className='col-8'>
                                                        คณะกรรมการดำเนินกิจการของสมาคมฌาปนกิจสงเคราะห์สมาชิกกลุ่มเครดิตยูเนี่ยนครอบครัวซิมปันสุข
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='col-2'>
                                                        <b>“นายกสมาคม”</b>
                                                    </td>
                                                    <td className='col-2'>หมายความว่า</td>
                                                    <td className='col-8'>
                                                        นายกสมาคมฌาปนกิจสงเคราะห์กลุ่มเครดิตยูเนี่ยน ครอบครัวซิมปันสุข
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='col-2'>
                                                        <b>“กรรมการ”</b>
                                                    </td>
                                                    <td className='col-2'>หมายความว่า</td>
                                                    <td className='col-8'>
                                                        กรรมการดำเนินกิจการของสมาคมฌาปนกิจสงเคราะห์
                                                        สมาชิกกลุ่มเครดิตยูเนี่ยนครอบครัวซิมปันสุข
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='col-2'>
                                                        <b>“เงินค่าสมัคร”</b>
                                                    </td>
                                                    <td className='col-2'>หมายความว่า</td>
                                                    <td className='col-8'>
                                                        เงินที่เรียกเก็บจากผู้ซึ่งสมัครเข้าเป็นสมาชิกของสมาคมฌาปนกิจสงเคราะห์สมาชิกกลุ่มเครดิตยูเนี่ยนครอบครัวซิมปันสุข
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='col-2'>
                                                        <b>“เงินค่าบำรุง”</b>
                                                    </td>
                                                    <td className='col-2'>หมายความว่า</td>
                                                    <td className='col-8'>
                                                        เงินที่เรียกเก็บจากสมาชิกเป็นรายเดือนหรือรายปี
                                                        เพื่อเป็นค่าใช้จ่ายในการดำเนินการของสมาคมฌาปนกิจสงเคราะห์สมาชิกกลุ่มเครดิตยูเนี่ยนครอบครัวซิมปันสุข
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='col-2'>
                                                        <b>“เงินสงเคราะห์”</b>
                                                    </td>
                                                    <td className='col-2'>หมายความว่า</td>
                                                    <td className='col-8'>
                                                        เงินที่สมาชิกร่วมกันออกช่วยเหลือเป็นค่าจัดการศพ หรือ
                                                        ค่าจัดการศพและสงเคราะห์ครอบครัวของสมาชิกซึ่งถึงแก่ ความตาย
                                                        รวมทั้งเป็นค่าใช้จ่ายเพื่อดำเนินกิจการของ
                                                        สมาคมฌาปนกิจสงเคราะห์สมาชิกกลุ่มเครดิตยูเนี่ยนครอบครัวซิมปันสุข
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='col-2'>
                                                        <b>“เงินสงเคราะห์ล่วงหน้า”</b>
                                                    </td>
                                                    <td className='col-2'>หมายความว่า</td>
                                                    <td className='col-8'>
                                                        เงินที่สมาคมฌาปนกิจสงเคราะห์สมาชิกกลุ่มเครดิตยูเนี่ยน
                                                        ครอบครัวซิมปันสุขเรียกเก็บไว้ล่วงหน้าเพื่อสำรองจ่าย
                                                        เป็นเงินสงเคราะห์เมื่อสมาชิกคนใดคนหนึ่งถึงแก่ความตาย
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='col-2'>
                                                        <b>“นายทะเบียน”</b>
                                                    </td>
                                                    <td className='col-2'>หมายความว่า</td>
                                                    <td className='col-8'>
                                                        นายทะเบียนสมาคมฌาปนกิจสงเคราะห์ประจำท้องที่กรุงเทพมหานคร
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='col-2'>
                                                        <b>“นายทะเบียนกลาง”</b>
                                                    </td>
                                                    <td className='col-2'>หมายความว่า</td>
                                                    <td className='col-8'>อธิบดีกรมกิจการสตรีและสถาบันครอบครัว</td>
                                                </tr>
                                                <tr>
                                                    <td className='col-2'>
                                                        <b>“ปลัดกระทรวง”</b>
                                                    </td>
                                                    <td className='col-2'>หมายความว่า</td>
                                                    <td className='col-8'>
                                                        ปลัดกระทรวงการพัฒนาสังคมและความมั่นคงของมนุษย์
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <h3 className='text-center m-0'>หมวด 2</h3>
                                    <h3 className='text-center'>วัตถุประสงค์</h3>
                                    <p>
                                        <b>ข้อ 5.</b> สมาคมมีวัตถุประสงค์เพื่อทำการสงเคราะห์ซึ่งกันและกัน
                                        ในการจัดการศพหรือการจัดการศพ และสงเคราะห์ครอบครัวของสมาชิกซึ่งถึงแก่ความตาย
                                        โดยมิได้ประสงค์จะหากำไรหรือรายได้เพื่อแบ่งปันกัน
                                        การถึงแก่ความตายให้รวมถึงการตกเป็นคนสาบสูญตามคำสั่งศาล
                                    </p>
                                    <h3 className='text-center m-0'>หมวด 3</h3>
                                    <h3 className='text-center'>ที่ตั้งสำนักงาน และวันเวลาเปิดทำการ</h3>
                                    <p className='m-0'>
                                        <b>ข้อ 6.</b> สำนักงานของสมาคมตั้งอยู่เลขที่ 21/111-112 ซอยศูนย์วิจัย ถนนพระราม
                                        9 แขวงบางกะปิ เขตห้วยขวาง กรุงเทพมหานคร รหัสไปรษณีย์ 10310 โทรศัพท์ +66(0)2 114
                                        7069
                                    </p>
                                    <p>
                                        <b>ข้อ 7.</b> สมาคมจะเปิดทำการในวันจันทร์ ถึง วันศุกร์ ระหว่างเวลา 09.00 – 18.00
                                        น. และหยุดทำการในวันเสาร์ วันอาทิตย์ และวันหยุดนักขัตฤกษ์
                                    </p>
                                    <h3 className='text-center m-0'>หมวด 4</h3>
                                    <h3 className='text-center'>วิธีการรับสมาชิกและการขาดจากสมาชิกภาพ</h3>
                                    <p className='m-0'>
                                        <b>ข้อ 8.</b>{' '}
                                        ผู้ที่ประสงค์จะสมัครเข้าเป็นสมาชิกของสมาคมต้องยื่นใบสมัครตามแบบของสมาคมด้วยตนเอง
                                        ณ ที่ตั้งสำนักงานของสมาคม ในวันเวลาเปิดทำการ
                                    </p>
                                    <p className='m-0'>
                                        <b>ข้อ 9.</b> ผู้สมัครเข้าเป็นสมาชิกต้องมีคุณสมบัติ ดังต่อไปนี้
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1)
                                        เป็นสมาชิกกลุ่มเครดิตยูเนี่ยนครอบครัวซิมปันสุข
                                        และให้หมายรวมถึงสมาชิกของกลุ่มเครดิตยูเนี่ยนครอบครัวซิมปันสุขที่ได้รับการจดทะเบียนเป็นสหกรณ์เครดิตยูเนี่ยนครอบครัวซิมปันสุข
                                        จำกัด ตามระเบียบของกรมส่งเสริมสหกรณ์ด้วย
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2)
                                        เป็นผู้บรรลุนิติภาวะ
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(3)
                                        มีอายุในวันสมัครไม่เกิน 75 ปีบริบูรณ์
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(4)
                                        ไม่เป็นโรคติดต่อร้ายแรง หรือเป็นโรคเรื้อรังจนรักษาไม่หาย
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(5)
                                        มีสุขภาพสมบูรณ์แข็งแรง
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(6)
                                        ต้องมีความประพฤติดี และยินยอมปฏิบัติตามข้อบังคับของสมาคม
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(7)
                                        ไม่เป็นบุคคลล้มละลาย หรือเป็นคนไร้ความสามารถ
                                    </p>
                                    <p className='m-0'>
                                        <b>ข้อ 10.</b> ผู้สมัครเข้าเป็นสมาชิกต้องแนบเอกสารหลักฐาน ดังต่อไปนี้
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1)
                                        สำเนาบัตรประจำตัวประชาชน
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2)
                                        สำเนาทะเบียนบ้าน
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(3)
                                        ใบรับรองแพทย์แผนปัจจุบันชั้นหนึ่งจากสถานพยาบาลของรัฐตามกฎหมายว่าด้วยสถานพยาบาล
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(4)
                                        หลักฐานอื่นตามที่สมาคมกำหนด
                                    </p>
                                    <p className='m-0'>
                                        <b>ข้อ 11.</b> ผู้สมัครเข้าเป็นสมาชิกจะมีสมาชิกภาพสมบูรณ์ เมื่อพ้นกำหนด 180 วัน
                                        (หนึ่งร้อยแปดสิบวัน) นับแต่วันที่คณะกรรมการมีมติรับเข้าเป็นสมาชิก
                                        และได้ชำระเงินค่าสมัครแก่สมาคมแล้ว ยกเว้นสมาชิกภาพของผู้เริ่มก่อการจัดตั้งสมาคม
                                        จะเริ่มตั้งแต่วันที่นายทะเบียนรับจดทะเบียนสมาคมและได้ยื่นใบสมัครเข้าเป็นสมาชิกไว้แล้ว
                                        เงินค่าสมัครสมาคมจะไม่คืนให้ผู้สมัคร ไม่ว่ากรณีใด ๆ ทั้งสิ้น
                                        หรือสมาชิกภาพจะสมบูรณ์เมื่อคณะกรรมการมีมติรับเข้าเป็นสมาชิก
                                    </p>
                                    <p className='m-0'>
                                        <b>ข้อ 12.</b>{' '}
                                        สมาคมจะมอบหลักฐานการเป็นสมาชิกในรูปสมุดชำระเงินประจำตัวสมาชิกให้แก่สมาชิกทุกคน
                                        สมาชิกของสมาคมจะมีหมายเลขประจำตัวสมาชิกได้หมายเลขเดียวเท่านั้น
                                    </p>
                                    <p className='m-0'>
                                        <b>ข้อ 13.</b> ผู้สมัครเข้าเป็นสมาชิกจะต้องระบุชื่อผู้จัดการศพ
                                        และผู้ที่ประสงค์ให้ได้รับเงินสงเคราะห์ซึ่งต้องเป็นบุคคลในครอบครัวของตน ตามข้อ
                                        23. ไว้ในใบสมัคร ถ้ามีการเปลี่ยนแปลงผู้จัดการศพ และผู้รับเงินสงเคราะห์ ในภายหลัง
                                        สมาชิกต้องแจ้งให้สมาคมทราบเป็นหนังสือตามแบบที่สมาคมกำหนด
                                    </p>
                                    <p className='m-0'>
                                        <b>ข้อ 14.</b> สมาชิกภาพย่อมสิ้นสุดลง ในกรณีดังต่อไปนี้
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1)
                                        ตาย
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2)
                                        ลาออก เป็นหนังสือตามแบบที่สมาคมกำหนด และให้มีผลตั้งแต่วันที่ยื่นหนังสือขอลาออก
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(3)
                                        ที่ประชุมใหญ่มีมติให้ออก
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(4)
                                        ถูกคัดชื่อออกเนื่องจากขาดส่งเงินสงเคราะห์
                                        โดยสมาคมแจ้งเป็นหนังสือให้ไปชำระเงินสงเคราะห์ ตามข้อ 19. แล้ว จำนวนสองครั้ง
                                        แต่ละครั้งมีระยะเวลาห่างกัน 15 วัน (สิบห้าวัน)
                                        ซึ่งครั้งสุดท้ายได้ทำหนังสือลงทะเบียนตอบรับ
                                        และทางสมาคมได้นำเงินสงเคราะห์ล่วงหน้ามาชำระเป็นเงินสงเคราะห์หมดแล้ว
                                        หรือสมาคมติดต่อหาหลักแหล่งที่อยู่ไม่ได้
                                        และคณะกรรมการพิจารณาแล้วมีมติให้พ้นจากสมาชิกภาพ
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;การสิ้นสุดสมาชิกภาพตามข้อนี้
                                        สมาชิกไม่มีสิทธิเรียกเงินค่าสมัคร เงินค่าบำรุง และเงินสงเคราะห์
                                        ที่ได้ชำระตามข้อบังคับนี้คืนจากสมาคม
                                        เว้นแต่เงินสงเคราะห์ล่วงหน้าที่ยังไม่ตกอยู่ในความผูกพันที่จะต้องจ่าย
                                        เป็นเงินสงเคราะห์ให้แก่สมาชิกที่ถึงแก่ความตาย
                                    </p>
                                    <p>
                                        <b>ข้อ 15.</b> สมาคมจะแจ้งเป็นหนังสือให้สมาชิกที่ขาดจากสมาชิกภาพ ตามข้อ 14. (2)
                                        (3) และ (4) เพื่อทราบ นับแต่วันที่ที่ประชุมมีมติ ภายใน 15 วัน (สิบห้าวัน)
                                        สมาชิกที่พ้นจากสมาชิกภาพ ตามข้อ 14. (4) มีสิทธิอุทธรณ์ต่อสมาคมภายใน 15 วัน
                                        (สิบห้าวัน) นับแต่วันที่ได้รับหนังสือให้คณะกรรมการวินิจฉัยให้แล้วเสร็จ ภายใน 30
                                        วัน (สามสิบวัน) นับแต่วันที่ยื่นอุทธรณ์ คำวินิจฉัยของคณะกรรมการให้เป็นที่สุด
                                    </p>
                                    <h3 className='text-center m-0'>หมวด 5</h3>
                                    <h3 className='text-center'>เงินค่าสมัคร เงินค่าบำรุง และเงินสงเคราะห์</h3>
                                    <p className='m-0'>
                                        <b>ข้อ 16.</b> ผู้สมัครเข้าเป็นสมาชิกของสมาคม ต้องชำระเงินค่าสมัครเป็นเงินคนละ
                                        100 บาท (หนึ่งร้อยบาทถ้วน) และเมื่อสมาคมมีมติรับเข้าเป็นสมาชิก ตามข้อ 12. แล้ว
                                        จะต้องชำระเงินให้แก่สมาคมดังนี้
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1)
                                        ค่าบำรุงปีละ 50 บาท (ห้าสิบบาท)
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2)
                                        เงินสงเคราะห์ล่วงหน้า เป็นเงิน 2,000 บาท (สองพันบาท)
                                    </p>
                                    <p className='m-0'>
                                        <b>ข้อ 17.</b> เมื่อสมาชิกของสมาคมคนใดคนหนึ่งถึงแก่ความตาย
                                        สมาชิกทุกคนมีหน้าที่ต้องชำระเงินสงเคราะห์นั้น รายละ 10 บาท (สิบบาท)
                                    </p>
                                    <p className='m-0'>
                                        <b>ข้อ 18.</b> การชำระเงินค่าสมัคร เงินค่าบำรุง
                                        เงินสงเคราะห์หรือเงินสงเคราะห์ล่วงหน้าให้ชำระได้ในกรณีดังต่อไปนี้
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1)
                                        ชำระกับเจ้าหน้าที่ ณ สำนักงานของสมาคม หรือสถานที่ที่สมาคมกำหนด
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2)
                                        หักจากบัญชีเงินฝากของสมาชิก โดยได้รับความยินยอมจากสมาชิกเจ้าของบัญชีไว้แล้ว
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(3)
                                        โอนเงินเข้าบัญชีเงินฝากของสมาคม
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(4)
                                        ชำระเงินผ่าน QR Code (คิวอาร์โค้ด)
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(4)
                                        วิธีอื่น ๆ
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;การรับเงินทุกประเภท
                                        สมาคมจะออกหลักฐานการรับเงินทุกครั้งตามแบบที่สมาคมกำหนด
                                    </p>
                                    <p className='m-0'>
                                        <b>ข้อ 19.</b> การชำระเงินสงเคราะห์ ตามข้อ 18. สมาชิกต้องชำระให้สมาคมภายใน 15
                                        วัน (สิบห้าวัน)
                                        นับแต่วันที่ได้รับแจ้งการตายของสมาชิกหรือได้รับหนังสือจากสมาคมให้ไปชำระเงิน
                                    </p>
                                    <p>
                                        <b>ข้อ 20.</b>{' '}
                                        สมาคมจะเรียกเก็บเงินสงเคราะห์ล่วงหน้าจากสมาชิกเพื่อสำรองจ่ายเป็นค่าจัดการศพและสงเคราะห์ครอบครัว
                                        จำนวน 200 ศพ (สองร้อยศพ) เป็นเงิน 2,000 บาท (สองพันบาท)
                                        และสมาคมจะคืนให้แก่สมาชิกเท่าที่สมาชิกผู้นั้นยังไม่ตกอยู่ในความผูกพันที่จะต้องจ่ายเงินสงเคราะห์
                                    </p>
                                    <h3 className='text-center m-0'>หมวด 6</h3>
                                    <h3 className='text-center'>สิทธิและหน้าที่ของสมาชิก</h3>
                                    <p className='m-0'>
                                        <b>ข้อ 21.</b> สมาชิกมีสิทธิดังต่อไปนี้
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1)
                                        แสดงความคิดเห็นเกี่ยวกับกิจการของสมาคมต่อคณะกรรมการและเรียกร้องให้คณะกรรมการ
                                        กระทำหรืองดเว้นการกระทำเพื่อประโยชน์ของสมาคม
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2)
                                        เข้าร่วมประชุมใหญ่ของสมาคม และลงสมัครรับเลือกตั้งเป็นกรรมการเพื่อบริหารงานสมาคม
                                        รวมทั้งออกเสียงลงคะแนนทุกครั้ง
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(3)
                                        ขอตรวจสอบบัญชีและเอกสารที่เกี่ยวข้อง เพื่อทราบการดำเนินกิจการของสมาคมในวัน เวลา
                                        เปิดทำการ
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(4)
                                        ลงชื่อร่วมกันไม่น้อยกว่า 50 คน (ห้าสิบคน)
                                        หรือจำนวนไม่น้อยกว่าหนึ่งในห้าของจำนวนสมาชิก
                                        ทั้งหมดร้องขอต่อคณะกรรมการให้เรียกประชุมใหญ่วิสามัญเพื่อการใดการหนึ่งเมื่อใดก็ได้
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(5)
                                        ร้องขอต่อนายทะเบียนให้ส่งเพิกถอนมติของที่ประชุมใหญ่ของสมาคม
                                        ถ้าได้นัดเรียกหรือได้ประชุมกัน
                                        หรือได้ลงมติฝ่าฝืนต่อกฎหมายหรือข้อบังคับของสมาคมและต้องร้องขอ ภายใน 30 วัน
                                        (สามสิบวัน) นับแต่วันที่ ที่ประชุมใหญ่ลงมติ
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(6)
                                        ขอตรวจเอกสาร คัดเอกสาร หรือขอคัดรายการและรับรองสำเนาเอกสารของสมาคมจากนายทะเบียน
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(7)
                                        มอบฉันทะเป็นหนังสือให้ผู้อื่นซึ่งมิให้สมาชิกเข้าร่วมประชุมใหญ่
                                        และออกเสียงลงคะแนนแทนตนได้ ผู้รับมอบฉันทะคนหนึ่งรับมอบฉันทะได้คนเดียว
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(8)
                                        ลงชื่อร่วมกันไม่น้อยกว่าหนึ่งในสี่ของจำนวนสมาชิกทั้งหมดร้องขอนายทะเบียนสั่งให้เลิกสมาคม
                                        พร้อมด้วยเหตุผล หรือร้องขอต่อศาลเพื่อให้ศาลสั่งให้เลิกสมาคม
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(9)
                                        แสดงความจำนงต่อที่ประชุมใหญ่เพื่อเป็นผู้ชำระบัญชีในกรณีที่สมาคมต้องเลิกตามมติที่ประชุมใหญ่
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(10)
                                        ลงชื่อร่วมกันไม่น้อยกว่าสองในสามของจำนวนสมาชิกทั้งหมดร้องขอต่อนายทะเบียนเพื่อแต่งตั้ง
                                        ผู้ชำระบัญชีคนใหม่แทนผู้ชำระบัญชีที่ได้ตั้งไว้
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(11)
                                        อุทธรณ์ต่อสมาคม กรณีถูกคัดชื่อออกตามข้อ 14. (4)
                                    </p>
                                    <p className='m-0'>
                                        <b>ข้อ 22.</b> สมาชิกมีหน้าที่ดังนี้
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1)
                                        ปฏิบัติตามระเบียบ ข้อบังคับและคำสั่งของสมาคม
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2)
                                        ชำระเงินสงเคราะห์ให้เรียบร้อยภายใน 15 วัน (สิบห้าวัน)
                                        นับแต่วันที่ได้รับการแจ้งการตาย
                                        ของสมาชิกหรือได้รับหนังสือแจ้งจากสมาคมให้ไปชำระเงิน
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(3)
                                        เก็บรักษาสมุดชำระเงินประจำตัวสมาชิกหรือบัตรประจำตัวสมาชิก
                                        หากสูญหายหรือชำรุดต้องรีบแจ้งเพื่อขอสมุดชำระเงินประจำตัวสมาชิกหรือบัตรประจำตัวสมาชิกแทนฉบับเดิมจากสมาคม
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(4)
                                        แจ้งการเปลี่ยนแปลงที่อยู่ ชื่อ ชื่อสกุล หรือวัน เดือน ปีเกิดต่อสมาคม ภายใน 15
                                        วัน (สิบห้าวัน) นับแต่วันที่มีการเปลี่ยนแปลง
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(5)
                                        แจ้งความประสงค์เป็นหนังสือตามแบบที่สมาคมกำหนดที่จะเปลี่ยนแปลงบุคคลผู้จัดการศพหรือผู้รับเงินสงเคราะห์
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(5)
                                        ติดตามตรวจสอบข้อมูลข่าวสารการดำเนินกิจการของสมาคมผ่านทางสื่อสิ่งพิมพ์
                                        สื่ออิเล็กทรอนิกส์ สื่อสังคมออนไลน์ หรือช่องทางการสื่อสารอื่นๆ
                                        ของสมาคมอย่างใกล้ชิด และอย่างสม่ำเสมอ
                                    </p>
                                    <h3 className='text-center m-0'>หมวด 7</h3>
                                    <h3 className='text-center'>
                                        วิธีการจ่ายเงินค่าจัดการศพหรือค่าจัดการศพและสงเคราะห์ครอบครัว
                                    </h3>
                                    <p className='m-0'>
                                        <b>ข้อ 23.</b> ในกรณีที่สมาชิกสมาคมฌาปนกิจสงเคราะห์ถึงแก่ความตาย
                                        ให้สมาคมฌาปนกิจสงเคราะห์จ่าย ค่าจัดการศพ
                                        หรือค่าจัดการศพและสงเคราะห์ครอบครัวให้แก่บุคคลที่สมาชิกได้ระบุไว้ในใบสมัครให้เป็นผู้รับเงินสงเคราะห์
                                        และหรือเป็นผู้จัดการศพ ซึ่งต้องเป็นบุคคล ดังต่อไปนี้
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1)
                                        สามี ภริยา บุตร บิดา มารดา
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2)
                                        พี่น้องร่วมบิดามารดาเดียวกัน
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(3)
                                        พี่น้องร่วมบิดาหรือมารดาเดียวกัน
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(4)
                                        ปู่ ย่า ตา ยาย
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(5)
                                        ลุง ป้า น้า อา
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(6)
                                        ผู้อุปการะเลี้ยงดูหรือผู้อยู่ในอุปการะเลี้ยงดู
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ในกรณีที่ไม่มีบุคคลที่สมาชิกระบุไว้ในใบสมัคร
                                        หรือมีบุคคลที่ระบุไว้ในใบสมัคร แต่ไม่อาจจัดการ ตามเจตนาของสมาชิกได้
                                        หรือในกรณีที่สมาชิกไม่ได้ระบุบุคคลใดไว้ในใบสมัคร บุคคลตามวรรคหนึ่งอาจยื่นคำร้อง
                                        ต่อสมาคมฌาปนกิจสงเคราะห์ เพื่อขอเป็นผู้จัดการศพก็ได้
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;เมื่อสมาคมฌาปนกิจสงเคราะห์ได้รับคำร้องตามวรรคสองและได้พิจารณาแล้วเห็นว่าบุคคลนั้นสามารถเป็นผู้จัดการศพของสมาชิกได้
                                        ให้สมาคมฌาปนกิจสงเคราะห์จ่ายค่าจัดการศพให้แก่บุคคลนั้น
                                        แต่ถ้าสมาคมฌาปนกิจสงเคราะห์เห็นว่าบุคคลนั้นไม่สามารถเป็นผู้จัดการศพได้
                                        หรือไม่มีบุคคลตามวรรคหนึ่งยื่นคำร้องภายในระยะเวลาตามที่กำหนดไว้ในข้อบังคับ
                                        ให้สมาคมฌาปนกิจสงเคราะห์จัดการศพให้แก่สมาชิกที่ถึงแก่ความตาย
                                        ตามสมควรแก่ฐานานุรูปและประเพณีทางศาสนาของสมาชิกนั้น
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;เมื่อได้ปฏิบัติตามวรรคสามแล้วยังคงมีเงินค่าจัดการศพหรือค่าจัดการศพและสงเคราะห์ครอบครัวเหลืออยู่
                                        ให้สมาคมฌาปนกิจสงเคราะห์จ่ายเงินส่วนที่เหลืออยู่ให้แก่บุคคลที่สมาชิกได้ระบุไว้ในใบสมัคร
                                        แต่ถ้าไม่มีบุคคล ที่สมาชิกระบุไว้ในใบสมัคร
                                        ให้สมาคมฌาปนกิจสงเคราะห์จ่ายเงินดังกล่าวให้แก่บุคคลในวรรคหนึ่งตามลำดับก่อนหลัง
                                        โดยผู้อยู่ลำดับก่อนย่อมตัดสิทธิผู้อยู่ลำดับหลัง
                                        ถ้ามีผู้อยู่ในลำดับเดียวกันหลายคน ให้สมาคมฌาปนกิจสงเคราะห์แบ่งเงินค่าจัดการศพ
                                        หรือค่าจัดการศพและสงเคราะห์ครอบครัวที่เหลืออยู่ให้แก่ทุกคนในสัดส่วน ที่เท่ากัน
                                        หากไม่อาจแบ่งเงินให้แก่บุคคลใดได้ ให้เงินนั้นตกเป็นของแผ่นดิน
                                    </p>
                                    <p className='m-0'>
                                        <b>ข้อ 24.</b> สมาคมจะจ่ายค่าจัดการศพ
                                        หรือค่าจัดการศพและสงเคราะห์ครอบครัวจำนวนทั้งหมดจากเงินสงเคราะห์ครอบครัวจำนวนทั้งหมดให้แก่ผู้จัดการศพและผู้รับเงินสงเคราะห์
                                        ดังนี้
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1)
                                        จ่ายเงินค่าจัดการศพ
                                        หรือค่าจัดการศพและสงเคราะห์ครอบครัวจำนวนทั้งหมดจากเงินสงเคราะห์ล่วงหน้าที่สมาคมได้เรียกเก็บล่วงหน้าจากสมาชิกตามข้อ
                                        20. โดยคำนวณจากจำนวนสมาชิกที่มีอยู่จริง ณ วันที่สมาชิกผู้นั้นถึงแก่ความตาย
                                        คูณด้วยอัตราเงินสงเคราะห์ตามที่กำหนดในข้อ 17. ภายใน 7 วัน (เจ็ดวัน)
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2)
                                        หากสมาคมยังจ่ายเงินค่าจัดการศพ
                                        หรือค่าจัดการศพและสงเคราะห์ครอบครัวไม่ครบถ้วนตามจำนวนที่คำนวณได้ใน (1)
                                        ให้สมาคมจ่ายเงินส่วนที่เหลือทั้งหมด ภายใน 90 วัน (เก้าสิบวัน)
                                        นับแต่วันที่ได้จ่ายเงินดังกล่าวครั้งแรก
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;เอกสารประกอบคำขอรับเงินสงเคราะห์
                                        มีดังนี้
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1)
                                        ใบมรณบัตรฉบับจริง พร้อมสำเนาที่รับรองสำเนาถูกต้อง จำนวน 2 ฉบับ (สองฉบับ)
                                        กรณีใบมรณบัตรสูญหายให้ใช้หนังสือรับรองการตายที่ออกโดยพนักงานเจ้าหน้าที่ตามกฎหมายว่าด้วยการทะเบียนราษฎรแทนได้
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2)
                                        ทะเบียนบ้าน (ทร.14) ฉบับจริงของสมาชิกที่เสียชีวิตที่ระบุว่า “ตาย”
                                        พร้อมสำเนาจำนวน 2 ฉบับ (สองฉบับ)
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(3)
                                        บัตรประจำตัวประชาชน หรือบัตรประจำตัวเจ้าหน้าที่ของรัฐของผู้จัดการศพ
                                        หรือผู้รับเงินสงเคราะห์ พร้อมสำเนาที่รับรองความถูกต้อง จำนวน 2 ฉบับ (สองฉบับ)
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(4)
                                        สำเนาใบสมัครสมาชิกที่ระบุชื่อบุคคลที่เป็นผู้จัดการศพหรือผู้รับเงินสงเคราะห์
                                        หรือใบแจ้งเปลี่ยนแปลงชื่อผู้จัดการศพหรือผู้รับเงินสงเคราะห์ฉบับล่าสุด
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(5)
                                        หลักฐานอื่น ๆ
                                    </p>
                                    <p>
                                        <b>ข้อ 25.</b>{' '}
                                        สมาคมจะหักเงินสงเคราะห์ไว้เป็นค่าใช้จ่ายในการดำเนินกิจการในอัตราร้อยละ 4 (สี่)
                                        ของเงิน ที่เรียกเก็บได้เป็นไปตามมติที่ประชุมใหญ่ แต่ทั้งนี้
                                        ต้องไม่เกินอัตราที่กำหนดไว้ในกฎกระทรวงกำหนดอัตราหักเงินสงเคราะห์ พ.ศ. 2547
                                    </p>
                                    <h3 className='text-center m-0'>หมวด 8</h3>
                                    <h3 className='text-center'>การใช้จ่าย และการเก็บรักษาเงิน</h3>
                                    <p className='m-0'>
                                        <b>ข้อ 26.</b> สมาคมจะใช้จ่ายเงินในบัญชีเงินสะสมของสมาคมในการดำเนินกิจการ ดังนี้
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1)
                                        ค่าเบี้ยประชุมสำหรับกรรมการที่มาประชุมคณะกรรมการ และค่าเบี้ยเลี้ยง ค่าพาหนะ
                                        ค่าเช่าที่พัก ของกรรมการ
                                        กรณีไปปฏิบัติงานของสมาคมในฐานะกรรมการสมาคมในสถานที่อื่นนอกที่ตั้งสมาคม ตามจำนวน
                                        ที่กำหนดในระเบียบของสมาคม
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2)
                                        เงินเดือนค่าจ้าง และค่าล่วงเวลาของเจ้าหน้าที่สมาคม
                                        ตามจำนวนที่กำหนดในระเบียบของสมาคม
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(3)
                                        ค่าเบี้ยเลี้ยง ค่าพาหนะ และค่าเช่าที่พักของเจ้าหน้าที่สมาคม
                                        กรณีไปปฏิบัติงานของสมาคมในสถานที่อื่นนอกที่ตั้งสมาคมตามที่ได้รับมอบหมาย
                                        ตามจำนวนที่กำหนดในระเบียบของสมาคม
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(4)
                                        เงินสมทบในฐานะนายจ้างตามพระราชบัญญัติประกันสังคม พ.ศ. 2533 และค่าเครื่องแบบ
                                        ของเจ้าหน้าที่สมาคม ตามจำนวนที่กำหนดในระเบียบของสมาคม
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(5)
                                        ค่าใช้จ่ายที่จำเป็นในการจัดประชุมใหญ่ของสมาชิก
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(6)
                                        ค่าลงทะเบียนในการเข้าร่วมประชุม ฝึกอบรม สัมมนา ของกรรมการและเจ้าหน้าที่สมาคม
                                        กรณีที่ได้เข้าร่วมในการประชุม ฝึกอบรม
                                        สัมมนาที่เกี่ยวข้องโดยตรงกับการดำเนินกิจการของสมาคม
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(7)
                                        ค่าใช้จ่ายในการจัดซื้อที่ดิน อาคารและครุภัณฑ์
                                        หรือการจัดจ้างก่อสร้างต่อเติมอาคารสำนักงาน ของสมาคม
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(8)
                                        ค่าเช่าที่ดิน และอาคารสำนักงานของสมาคม
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(9)
                                        ค่าใช้จ่ายในการจัดซื้อวัสดุใช้สิ้นเปลือง ค่าซ่อมแซม ค่าบำรุงรักษา
                                        และค่าเสื่อมราคา
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(10)
                                        ค่าใช้จ่ายด้านสาธารณูปโภค ได้แก่ ค่าน้ำประปา ค่าไฟฟ้า ค่าโทรศัพท์ และค่าไปรษณีย์
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(11)
                                        ค่าใช้จ่ายภาษีเงินได้ และค่าธรรมเนียมที่เกี่ยวกับการดำเนินกิจการของสมาคม
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(12)
                                        ค่าใช้จ่ายสำหรับผู้ทำบัญชี ผู้ตรวจบัญชี ผู้สอบบัญชี ผู้ชำระบัญชี
                                        และค่าใช้จ่ายในการเลิกสมาคม
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(13)
                                        ค่าพวงหรีด และค่าพิธีกรรมทางศาสนาของสมาชิกที่ถึงแก่ความตาย
                                        ตามจำนวนที่กำหนดในระเบียบของสมาคม
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(14)
                                        ค่าใช้จ่ายอื่น ๆ ตามที่ได้รับความเห็นชอบจากนายทะเบียนกลาง
                                    </p>
                                    <p className='m-0'>
                                        <b>ข้อ 27.</b> ค่าใช้จ่ายตามข้อ 26. (1) (2) (3) (4) และ (13)
                                        สมาคมต้องวางเป็นระเบียบ และนำเสนอที่ประชุมใหญ่พิจารณาอนุมัติ
                                        และส่งให้นายทะเบียนพิจารณาให้ความเห็นชอบภายใน 30 วัน (สามสิบวัน)
                                        และเมื่อนายทะเบียนอนุมัติแล้วจึงจะถือปฏิบัติได้
                                    </p>
                                    <p className='m-0'>
                                        <b>ข้อ 28.</b> เงินทุกประเภทที่สมาคมได้รับต้องนำฝากธนาคารของรัฐ
                                        หรือธนาคารพาณิชย์ หรือสหกรณ์ตามกฏหมายว่าด้วยสหกรณ์ในนามของสมาคม
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;งินที่อยู่ในสมาคมให้แยกนำฝากเป็น
                                        2 บัญชี (สองบัญชี) และห้ามมิให้มีการใช้จ่ายเงินข้ามบัญชี ดังนี้
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1)
                                        บัญชีเงินสะสมของสมาคม โดยเป็นเงินที่สมาคมได้รับจากเงินค่าสมัคร เงินค่าบำรุง
                                        เงินที่หักจาก เงินสงเคราะห์ ดอกเบี้ย และเงินบริจาค
                                        และมีไว้เพื่อเป็นค่าใช้จ่ายในการดำเนินกิจการของสมาคม ตามข้อ 26. เท่านั้น
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2)
                                        บัญชีเงินสงเคราะห์ของสมาชิก
                                        โดยเป็นเงินที่สมาคมรับจากสมาชิกเพื่อเป็นเงินสงเคราะห์ล่วงหน้า
                                        และรวมถึงเงินสงเคราะห์ค้างจ่ายที่ผู้รับเงินสงเคราะห์ของสมาชิกที่เสียชีวิตยังไม่ได้มาขอรับไป
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;การใช้จ่ายและการเก็บรักษาเงินสดของสมาคม
                                        ให้สมาคมเก็บรักษาเงินสดไว้เพื่อเป็นค่าใช้จ่ายที่จำเป็นเร่งด่วน ไม่เกิน 30,000
                                        บาท (สามหมื่นบาทถ้วน) โดยค่าใช้จ่ายทั้งหมดของสมาคมให้จ่ายเป็นเช็คของสมาคม
                                        หรือโอนจากบัญชีของสมาคมเข้าบัญชีของเจ้าหนี้ เว้นแต่กรณีที่ไม่อาจจ่ายเป็นเช็ค
                                        หรือโอนเข้าบัญชี จึงจะให้จ่ายเป็นเงินสดได้
                                    </p>
                                    <p className='m-0'>
                                        <b>ข้อ 29.</b> การอนุมัติใช้จ่ายเงิน
                                        และการเบิกถอนเงินจากบัญชีของสมาคมทุกครั้งทุกกรณีให้นายกสมาคมและกรรมการอื่นไม่น้อยกว่า
                                        2 คน (สองคน) ในตำแหน่งอุปนายก เลขานุการ เหรัญญิก หรือกรรมการอื่น
                                        ลงนามร่วมกันในการอนุมัติค่าใช้จ่าย และการเบิกถอนเงินจากบัญชีของสมาคมตามข้อ 28.
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;กรณีนายกสมาคมไม่อยู่หรือไม่สามารถปฏิบัติหน้าที่ได้
                                        ให้อุปนายกสมาคมหรือกรรมการอื่นที่ได้รับ
                                        มอบอำนาจจากนายกสมาคมปฏิบัติหน้าที่นายกสมาคมแทน
                                    </p>
                                    <p className='m-0'>
                                        <b>ข้อ 30.</b> เงินหรือผลประโยชน์ของสมาคมที่ได้รับมา หรือมีผู้บริจาค
                                        หรือดอกเบี้ยที่เกิดจากเงินฝากธนาคารในนามของสมาคม ต้องตกเป็นของสมาคมทั้งสิ้น
                                    </p>
                                    <p>
                                        <b>ข้อ 31.</b> สมาคมจะแต่งตั้งบุคคลที่เหมาะสมเป็นผู้ตรวจสอบบัญชี
                                        โดยความเห็นชอบของที่ประชุมใหญ่ และต้องรายงานผลให้คณะกรรมการทราบทุกเดือน
                                        ผู้ตรวจสอบบัญชีจะได้รับค่าตอบแทน ตามที่สมาคมกำหนด
                                    </p>
                                    <h3 className='text-center m-0'>หมวด 9</h3>
                                    <h3 className='text-center'>การประชุมใหญ่</h3>
                                    <p className='m-0'>
                                        <b>ข้อ 32.</b> นับแต่จดทะเบียนจัดตั้งสมาคม
                                        ผู้เริ่มก่อการจะต้องนัดสมาชิกมาประชุมใหญ่สามัญครั้งแรกภายใน 90 วัน (เก้าสิบวัน)
                                        เพื่อ
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1)
                                        รับรองข้อบังคับทั้งฉบับ
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2)
                                        เลือกตั้งคณะกรรมการและมอบหมายงาน
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(3)
                                        เลือกตั้งคณะกรรมการและมอบหมายงาน
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(4)
                                        กำหนดอัตราการหักเงินค่าจัดการศพ
                                        หรือค่าจัดการศพและสงเคราะห์ครอบครัวไว้เป็นค่าใช้จ่าย
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(5)
                                        กำหนดจำนวนเงินเบี้ยเลี้ยง เบี้ยประชุม ค่าพาหนะ
                                        เงินหรือประโยชน์อย่างอื่นทำนองเดียวกันให้แก่กรรมการ
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(6)
                                        เรื่องอื่น ๆ
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ในระหว่างที่ยังไม่ได้มีการประชุมใหญ่สามัญครั้งแรก
                                        ผู้เริ่มก่อการจัดตั้งสมาคมมีอำนาจหน้าที่ และความรับผิดชอบเช่นเดียวกับคณะกรรมการ
                                    </p>
                                    <p className='m-0'>
                                        <b>ข้อ 33.</b> สมาคมจะต้องเรียกประชุมใหญ่สามัญประจำปี ปีละ 1 ครั้ง (หนึ่งครั้ง)
                                        ภายใน 120 วัน (หนึ่งร้อยยี่สิบวัน) นับแต่วันสิ้นปีปฏิทิน เพื่อ
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1)
                                        รับทราบรายงานกิจการในรอบปีที่ผ่านมา
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2)
                                        พิจารณาอนุมัติบัญชีรายได้ รายจ่ายและบัญชีงบดุล
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(3)
                                        เลือกตั้งคณะกรรมการชุดใหม่แทนคณะกรรมการเมื่อครบวาระหรือทดแทนตำแหน่งที่ว่าง
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(4)
                                        พิจารณาแก้ไขหรือเพิ่มเติมข้อบังคับ
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(5)
                                        เรื่องอื่น ๆ
                                    </p>
                                    <p className='m-0'>
                                        <b>ข้อ 34.</b> สมาคมจะเรียกประชุมใหญ่วิสามัญ
                                        ในกรณีที่ต้องขอความเห็นชอบหรือมติจากที่ประชุมในการดำเนินกิจการของสมาคม
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1)
                                        คณะกรรมการเรียกประชุม
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2)
                                        สมาชิกจำนวนไม่น้อยกว่าหนึ่งในห้าของจำนวนสมาชิกทั้งหมด หรือไม่น้อยกว่า 50 คน
                                        (ห้าสิบคน)
                                        ลงชื่อร่วมกันร้องขอต่อสมาคมให้เรียกประชุมใหญ่วิสามัญเพื่อการใดการหนึ่ง
                                        ในกรณีที่สมาชิกเป็นผู้ร้องขอ ให้สมาคมเรียกประชุมภายใน 30 วัน (สามสิบวัน)
                                        นับแต่วันที่ได้รับคำร้องขอ ถ้าสมาคมไม่เรียกประชุม ในระยะเวลาดังกล่าว
                                        ให้นายทะเบียนเรียกประชุมได้
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(3)
                                        นายทะเบียนสั่งให้เรียกประชุม
                                    </p>
                                    <p className='m-0'>
                                        <b>ข้อ 35.</b>{' '}
                                        สมาคมจะส่งหนังสือนัดประชุมใหญ่ไปยังสมาชิกทุกคนก่อนวันนัดประชุมไม่น้อยกว่า 7 วัน
                                        (เจ็ดวัน) หนังสือเชิญประชุมต้องระบุสถานที่ วัน เวลา และระเบียบวาระการประชุม
                                        พร้อมทั้งรายละเอียดและเอกสาร ที่เกี่ยวข้องไปด้วย
                                    </p>
                                    <p className='m-0'>
                                        <b>ข้อ 36.</b>{' '}
                                        การประชุมใหญ่ต้องมีสมาชิกมาร่วมประชุมร่วมกันไม่น้อยกว่ากึ่งหนึ่งของจำนวนสมาชิกทั้งหมด
                                        หรือ ไม่น้อยกว่า 100 คน (หนึ่งร้อยคน) จึงจะเป็นองค์ประชุม
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ถ้าในการประชุมนัดแรก
                                        สมาชิกหรือผู้แทนสมาชิกมาไม่ครบองค์ประชุม หากการประชุมนั้นได้นัด โดยสมาชิกร้องขอ
                                        ให้เลิกประชุม ถ้าการประชุมนั้นมิใช่โดยสมาชิกร้องขอ ให้นัดประชุมใหญ่อีกครั้งหนึ่ง
                                        ภายใน 30 วัน (สามสิบวัน)
                                        การประชุมครั้งหลังนี้มีสมาชิกหรือผู้แทนสมาชิกมาประชุมไม่น้อยกว่าหนึ่งในสิบ
                                        ของจํานวนสมาชิกทั้งหมดหรือไม่น้อยกว่า 30 คน (สามสิบคน) ก็ให้ถือว่าเป็นองค์ประชุม
                                    </p>
                                    <p className='m-0'>
                                        <b>ข้อ 37.</b> ในการประชุมใหญ่ สมาชิกคนหนึ่งมีเสียงหนึ่งในการลงคะแนน
                                        การวินิจฉัยชี้ขาดให้ถือเสียงข้างมาก ถ้าคะแนนเสียงเท่ากัน
                                        ให้ประธานในที่ประชุมออกเสียงเพิ่มขึ้นอีกเสียงหนึ่งเป็นเสียงชี้ขาด
                                    </p>
                                    <p className='m-0'>
                                        <b>ข้อ 38.</b> การออกเสียงลงคะแนนในที่ประชุม
                                        โดยเลือกปฏิบัติได้ในแต่ละวาระการประชุม
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1)
                                        ออกเสียงลงคะแนนเปิดเผย ให้ใช้วิธียกมือ
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2)
                                        ออกเสียงลงคะแนนลับ ให้ใช้วิธีกาบัตรลงคะแนน
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;การนับคะแนนเสียง
                                        ให้ที่ประชุมเลือกสมาชิกที่เข้าร่วมประชุมเป็นกรรมการนับคะแนนจำนวนไม่น้อยกว่า 3 คน
                                        (สามคน)
                                    </p>
                                    <p className='m-0'>
                                        <b>ข้อ 39.</b> สมาชิกจะมอบฉันทะเป็นหนังสือให้ผู้อื่น ซึ่งมิใช่สมาชิกมาประชุมใหญ่
                                        และออกเสียงแทนตนได้ ผู้รับมอบฉันทะคนหนึ่งรับมอบฉันทะได้คนเดียว
                                    </p>
                                    <p className='m-0'>
                                        <b>ข้อ 40.</b> ในกรณีที่จะมีมติเรื่องใด
                                        ถ้าส่วนได้เสียของกรรมการหรือสมาชิกของสมาคมผู้ใดขัดกับประโยชน์ได้เสีย ของสมาคม
                                        กรรมการหรือสมาชิกของสมาคมผู้นั้นจะออกเสียงลงคะแนนในเรื่องนั้นไม่ได้
                                    </p>
                                    <p>
                                        <b>ข้อ 41.</b> ให้นายกสมาคมเป็นประธานในที่ประชุม
                                        ถ้านายกสมาคมไม่อยู่หรือไม่อาจปฏิบัติหน้าที่ได้ ให้อุปนายกสมาคมปฏิบัติหน้าที่แทน
                                        ถ้าทั้งนายกและอุปนายกสมาคมไม่อยู่หรือไม่อาจปฏิบัติหน้าที่ได้
                                        ให้ที่ประชุมเลือกกรรมการคนใดคนหนึ่งขึ้นเป็นประธานในที่ประชุมเฉพาะการประชุมคราวนั้น
                                    </p>
                                    <h3 className='text-center m-0'>หมวด 10</h3>
                                    <h3 className='text-center'>คณะกรรมการ</h3>
                                    <p className='m-0'>
                                        <b>ข้อ 42.</b> คณะกรรมการผู้ดำเนินกิจการของสมาคม
                                        โดยได้รับการเลือกตั้งระหว่างสมาชิกด้วยกันในที่ประชุมใหญ่ มีจำนวนไม่น้อยกว่า 7 คน
                                        (เจ็ดคน) และไม่เกิน 15 คน (สิบห้าคน) ประกอบด้วยตำแหน่งนายกสมาคม เลขานุการ
                                        เหรัญญิก และกรรมการอื่น ๆ ตามที่เห็นสมควร และต้องมีคุณสมบัติ
                                        และไม่มีลักษณะต้องห้าม ดังต่อไปนี้
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1)
                                        มีสัญชาติไทย
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2)
                                        มีอายุไม่ต่ำกว่ายี่สิบห้าปีบริบูรณ์
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(3)
                                        มีวุฒิการศึกษาไม่ต่ำกว่าชั้นประถมศึกษาปีที่ 4
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(4)
                                        มีอาชีพเป็นหลักแหล่งและฐานะมั่นคง
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(5)
                                        เป็นสมาชิกของสมาคมฌาปนกิจสงเคราะห์สมาชิกกลุ่มเครดิตยูเนี่ยนครอบครัวซิมปันสุข
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(6)
                                        ไม่เป็นกรรมการของสมาคมฌาปนกิจสงเคราะห์อื่น
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(7)
                                        ไม่เป็นผู้มีความประพฤติเสื่อมเสียหรือบกพร่องในศีลธรรมอันดี
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(8)
                                        ไม่เป็นภิกษุ สามเณร นักพรต หรือนักบวชในศาสนาใด
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(9)
                                        ไม่เคยถูกนายทะเบียนสมาคมฌาปนกิจสงเคราะห์ประจำท้องที่สั่งให้พ้นจากตำแหน่งกรรมการสมาคม
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(10)
                                        ไม่เคยถูกที่ประชุมใหญ่สมาคมฌาปนกิจสงเคราะห์ถอดถอนให้ออกจากตำแหน่งกรรมการเพราะเหตุทุจริตต่อหน้าที่
                                        หรือไม่เคยต้องโทษตามกฎหมายว่าด้วยการฌาปนกิจสงเคราะห์
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(11)
                                        ไม่เคยต้องโทษจำคุกโดยคำพิพากษาถึงที่สุดให้จำคุก
                                        เว้นแต่เป็นโทษสำหรับความผิดได้กระทำ โดยประมาทหรือความผิดลหุโทษ
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(12)
                                        ไม่เป็นคนไร้ความสามารถ หรือคนเสมือนไร้ความสามารถ
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(13)
                                        ไม่เป็นบุคคลที่มีหนี้สินล้นพ้นตัว หรือบุคคลล้มละลาย
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(14)
                                        ไม่เคยถูกไล่ออก ปลดออก หรือให้ออกจากส่วนราชการ หน่วยงานของรัฐ หรือรัฐวิสาหกิจ
                                        หรือไม่เคยถูกนายจ้างเลิกจ้างเพราะทุจริตต่อหน้าที่
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;หน้าที่ของกรรมการแต่ละตำแหน่ง
                                    </p>
                                    <div className='table-container'>
                                        <table className='table table-borderless'>
                                            <tbody>
                                                <tr>
                                                    <td className='col-4'>นายกสมาคม</td>
                                                    <td className='col-8'>
                                                        ทำหน้าที่เป็นหัวหน้าในการบริหารกิจการของสมาคมให้เป็นไปด้วย
                                                        ความเรียบร้อย ถูกต้องตามกฎหมาย ระเบียบ ข้อบังคับ และวัตถุประสงค์
                                                        ของสมาคม เป็นผู้แทนสมาคมในการติดต่อกับบุคคลภายนอก และเป็นประธาน
                                                        ในที่ประชุมคณะกรรมการ และการประชุมของสมาคม
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='col-4'>อุปนายกสมาคม</td>
                                                    <td className='col-8'>
                                                        ทำหน้าที่เป็นผู้ช่วยนายกสมาคมในการบริหารกิจการสมาคมปฏิบัติตามที่
                                                        นายกสมาคมได้มอบหมาย และทำหน้าที่แทนนายกสมาคมเมื่อนายกสมาคม
                                                        ไม่อยู่ หรือไม่สามารถปฏิบัติหน้าที่ได้
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='col-4'>เลขานุการ</td>
                                                    <td className='col-8'>
                                                        ทำหน้าที่เกี่ยวกับงานธุรการของสมาคม นัดการประชุมกรรมการ
                                                        การประชุมใหญ่ จดและรักษารายงานการประชุม
                                                        ดำเนินการจดทะเบียนกรรมการ
                                                        จดทะเบียนแก้ไขหรือเพิ่มเติมข้อบังคับและรายงานทะเบียนสมาชิก
                                                        บัญชีงบดุล ต่อนายทะเบียน และปฏิบัติตามคำสั่งของนายกสมาคม
                                                        ตลอดจนทำหน้าที่เป็นเลขานุการในการประชุมต่าง ๆ ของสมาคม
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='col-4'>เหรัญญิก</td>
                                                    <td className='col-8'>
                                                        มีหน้าที่เกี่ยวกับการเงินทั้งหมดของสมาคม เป็นผู้จัดทำบัญชี
                                                        รายได้ - รายจ่าย บัญชีงบดุลของสมาคม และเก็บเอกสารหลักฐานต่าง ๆ
                                                        ทางการเงิน บัญชี ของสมาคมไว้
                                                        เพื่อการตรวจสอบรายงานฐานะการเงินและงบดุลของสมาคม ต่อนายทะเบียน
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='col-4'>นายทะเบียนสมาคม</td>
                                                    <td className='col-8'>
                                                        มีหน้าที่จัดทำและรักษาทะเบียนสมาชิก ตลอดจนหลักฐานเอกสารต่าง ๆ
                                                        เกี่ยวกับทะเบียนสมาชิก
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='col-4'>กรรมการ</td>
                                                    <td className='col-8'>มีหน้าที่ตามที่นายกสมาคมมอบหมาย</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <p className='m-0'>
                                        <b>ข้อ 43.</b> วิธีการเลือกตั้งคณะกรรมการ
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;การเลือกตั้งกรรมการเป็นรายบุคคลตามตำแหน่งที่มีกำหนดในข้อบังคับอันได้แก่
                                        นายกสมาคม อุปนายกสมาคม เหรัญญิก นายทะเบียน เลขานุการ และกรรมการอื่น ๆ
                                        จนครบจำนวนที่กำหนดไว้ในข้อบังคับ โดยสมาชิกเสนอชื่อสมาชิกที่สมควรเป็นนายกสมาคม
                                        อุปนายกสมาคม กรรมการ และมีสมาชิกอื่นรับรองไม่น้อยกว่า 5 คน (ห้าคน)
                                        ถ้ามีการเสนอชื่อสมาชิกที่สมควรเป็นนายกสมาคมเพียงคนเดียว
                                        ก็ให้ถือเป็นมติของที่ประชุมใหญ่รับรอง ผู้นั้นเป็นนายกสมาคม
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;หากมีการเสนอหลายคน
                                        ให้ประธานในที่ประชุมขอมติที่ประชุมโดยการออกเสียงลงคะแนนการวินิจฉัย
                                        ชี้ขาดให้ถือเสียงข้างมาก และให้เลือกตั้งไปเช่นนี้จนครบจำนวนกรรมการทุกตำแหน่ง
                                    </p>
                                    <p className='m-0'>
                                        <b>ข้อ 44.</b> กรรมการของสมาคมไม่มีสิทธิได้รับเงินค่าจ้าง
                                        หรือค่าเบี้ยเลี้ยงในการมาปฏิบัติงานที่สำนักงานสมาคม
                                        และไม่มีสิทธิได้รับประโยชน์อย่างอื่นทำนองเดียวกันกับเงินค่าจ้าง
                                        หรือค่าเบี้ยเลี้ยงในการมาปฏิบัติงานที่สำนักงานสมาคมจากสมาคม
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;กรรมการที่มาประชุมอาจได้รับเงินค่าเบี้ยประชุมในวันที่มาร่วมประชุมคณะกรรมการ
                                        หรือได้รับเงิน ค่าเบี้ยเลี้ยง ค่าพาหนะ และค่าที่พัก
                                        กรณีไปปฏิบัติงานของสมาคมในสถานที่อื่นนอกที่ตั้งสำนักงานสมาคม
                                        ตามอัตราที่กำหนดไว้ในระเบียบโดยความเห็นชอบตามมติของที่ประชุมใหญ่
                                        และส่งให้นายทะเบียนเพื่อพิจารณาให้ความเห็นชอบก่อน ทั้งนี้ ภายในกำหนด 30 วัน
                                        (สามสิบวัน)
                                    </p>
                                    <p className='m-0'>
                                        <b>ข้อ 45.</b> คณะกรรมการที่ได้รับเลือกตั้งตามข้อ 43. ให้อยู่ในตำแหน่งคราวละ 2
                                        ปี (สองปี) นับแต่วันที่นายทะเบียนรับจดทะเบียน และจะดำรงตำแหน่งเกิน 2 วาระ
                                        (สองวาระ) ติดต่อกันไม่ได้
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;กรณีที่ไม่มีสมาชิกผู้ใดแสดงความจำนงอาสาเสนอตัวเข้าเป็นกรรมการ
                                        ให้ที่ประชุมโดยมติไม่น้อยกว่า
                                        สองในสามของผู้มาประชุมให้ความเห็นชอบกรรมการที่ดำรงตำแหน่งครบ 2 วาระ (สองวาระ)
                                        แล้ว เป็นกรรมการต่อไปก็ได้
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;คณะกรรมการที่ดำรงตำแหน่งครบตามวาระแล้ว
                                        ให้ปฏิบัติหน้าที่ต่อไปจนกว่านายทะเบียนจะรับจดทะเบียนคณะกรรมการชุดใหม่
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;กรรมการที่พ้นจากตำแหน่งก่อนครบวาระ
                                        และนายทะเบียนได้แต่งตั้งผู้ดำรงตำแหน่งแทนให้อยู่
                                        ในตำแหน่งเท่ากับวาระที่เหลืออยู่ของผู้ซึ่งตนแทน
                                    </p>
                                    <p className='m-0'>
                                        <b>ข้อ 46.</b> ให้กรรมการของสมาคมย่อมพ้นจากตำแหน่ง ในกรณีดังต่อไปนี้
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1)
                                        ครบกำหนดตามวาระ
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2)
                                        ตาย
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(3)
                                        ลาออกโดยทำเป็นหนังสือต่อนายกสมาคม
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(4)
                                        ขาดคุณสมบัติตามข้อ 42.
                                    </p>
                                    <p className='m-0'>
                                        <b>ข้อ 47.</b> ให้มีการประชุมคณะกรรมการทุกวันอังคารแรกของเดือน
                                        ในกรณีจำเป็นเร่งด่วน
                                        นายกสมาคมหรือกรรมการไม่น้อยกว่าครึ่งหนึ่งของจำนวนกรรมการทั้งหมดจะเรียกประชุมก็ได้
                                    </p>
                                    <p className='m-0'>
                                        <b>ข้อ 48.</b> การประชุมคณะกรรมการ
                                        จะต้องมีกรรมการมาประชุมไม่น้อยกว่าครึ่งหนึ่งของจำนวนกรรมการทั้งหมดจึงจะนับเป็นองค์ประชุม
                                    </p>
                                    <p className='m-0'>
                                        <b>ข้อ 49.</b> ให้นายกสมาคมเป็นประธานในที่ประชุม
                                        ถ้านายกสมาคมไม่อยู่หรือไม่อาจปฏิบัติหน้าที่ได้
                                        ให้อุปนายกสมาคมเป็นประธานในที่ประชุม
                                        ถ้าทั้งนายกสมาคมและอุปนายกสมาคมไม่อยู่หรือไม่อาจปฏิบัติหน้าที่ได้
                                        ให้ที่ประชุมเลือกกรรมการคนใดคนหนึ่งทำหน้าที่ประธานในที่ประชุม
                                        การวินิจฉัยชี้ขาดให้ถือเสียงข้างมาก
                                        ถ้าคะแนนเสียงเท่ากันให้ประธานในที่ประชุมออกเสียงเพิ่มขึ้นอีกหนึ่งเสียงเป็นเสียงชี้ขาด
                                    </p>
                                    <p className='m-0'>
                                        <b>ข้อ 50.</b> คณะกรรมการมีอำนาจและหน้าที่ในการดำเนินกิจการของสมาคม และให้รวมถึง
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1)
                                        ดำเนินกิจการของสมาคมให้เป็นไปตามพระราชบัญญัติการฌาปนกิจสงเคราะห์ พ.ศ.
                                        2545กฎกระทรวง ประกาศ ระเบียบ ข้อบังคับ และมติของที่ประชุมใหญ่ หรือกฎหมายอื่น
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2)
                                        วางระเบียบในการปฏิบัติงานของสมาคมให้เป็นไปตามข้อบังคับ
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(3)
                                        ว่าจ้างแต่งตั้ง ถอดถอนเจ้าหน้าที่สมาคม
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(4)
                                        จัดทำและเก็บรักษาเอกสาร หลักฐานทางการเงินและบัญชี ทะเบียนสมาชิก
                                        และทรัพย์สินของสมาคม
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(5)
                                        กำหนดอำนาจหน้าที่ของกรรมการแต่ละตำแหน่ง
                                    </p>
                                    <h3 className='text-center m-0'>หมวด 11</h3>
                                    <h3 className='text-center'>การเลิกสมาคม และการชำระบัญชี</h3>
                                    <p className='m-0'>
                                        <b>ข้อ 51.</b> สมาคมย่อมเลิกได้ด้วยเหตุใดเหตุหนึ่ง ดังต่อไปนี้
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1)
                                        ที่ประชุมใหญ่ลงมติให้เลิก
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2)
                                        นายทะเบียนสั่งให้เลิก
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(3)
                                        ศาลสั่งให้เลิก
                                    </p>
                                    <p className='m-0'>
                                        <b>ข้อ 52.</b> ในกรณีที่นายทะเบียนสั่งให้เลิก
                                        กรรมการจำนวนไม่น้อยกว่ากึ่งหนึ่งของจำนวนกรรมการทั้งหมด ของสมาคม
                                        สามารถยื่นอุทธรณ์ต่อปลัดกระทรวง โดยทำเป็นหนังสือยื่นต่อนายทะเบียนภายใน 30 วัน
                                        (สามสิบวัน) นับแต่วันที่ได้รับคำสั่งให้เลิก
                                    </p>
                                    <p className='m-0'>
                                        <b>ข้อ 53.</b> เมื่อสมาคมต้องเลิกไม่ว่าด้วยเหตุใด ๆ
                                        ให้มีการชำระบัญชีให้ที่ประชุมใหญ่พิจารณาตั้งผู้ชำระบัญชี
                                        โดยได้รับความเห็นชอบจากนายทะเบียนภายใน 30 วัน (สามสิบวัน) นับแต่วันที่เลิก
                                        หรือนับแต่วันที่ปลัดกระทรวงมีคำสั่งให้ยกอุทธรณ์
                                    </p>
                                    <p className='m-0'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ในกรณีที่ที่ประชุมใหญ่ไม่อาจตั้งผู้ชำระบัญชี
                                        หรือนายทะเบียนไม่ให้ความเห็นชอบ
                                        ให้นายทะเบียนแต่งตั้งผู้ชำระบัญชีขึ้นทำการชำระบัญชีได้เมื่อนายทะเบียนเห็นสมควรหรือเมื่อสมาชิกจำนวนไม่น้อยกว่าสองในสาม
                                        ของจำนวนสมาชิกทั้งหมดร้องขอต่อนายทะเบียน
                                        นายทะเบียนจะแต่งตั้งผู้ชำระบัญชีคนใหม่แทนผู้ชำระบัญชี ที่ได้ตั้งไว้ก็ได้
                                    </p>
                                    <p className='m-0'>
                                        <b>ข้อ 54.</b>{' '}
                                        ให้คณะกรรมการและเจ้าหน้าที่ของสมาคมจัดการรักษาทรัพย์สินทั้งหมดของสมาคมไว้จนกว่าผู้ชำระบัญชีจะเรียกให้ส่งมอบ
                                    </p>
                                    <p className='m-0'>
                                        <b>ข้อ 55.</b>{' '}
                                        ผู้ชำระบัญชีต้องทำงบดุลของสมาคมส่งให้ผู้สอบบัญชีเพื่อตรวจรับรองว่าถูกต้อง
                                        และอาจร้องขอให้ นายทะเบียนตั้งผู้สอบบัญชีเพื่อตรวจสอบงบดุลได้
                                        เมื่อผู้สอบบัญชีรับรองงบดุลแล้ว ให้ผู้ชำระบัญชีเสนองบดุล ต่อที่ประชุมใหญ่
                                        เพื่ออนุมัติและเสนอต่อนายทะเบียนต่อไป
                                    </p>
                                    <p className='m-0'>
                                        <b>ข้อ 56.</b> เมื่อได้ชำระบัญชีแล้วถ้ามีทรัพย์สินเหลืออยู่
                                        ให้โอนไปให้แก่มูลนิธิซิมปันบุญ
                                        หรือสมาคมฌาปนกิจสงเคราะห์อื่นที่มีสำนักงานตั้งอยู่ในเขตพื้นที่เขตห้วยขวางทุกสมาคม
                                        หากไม่มีให้โอนให้มูลนิธิหรือสมาคมที่มีวัตถุประสงค์เกี่ยวกับการกุศลสาธารณะ
                                        หากไม่มีให้เป็นไปตามมติของที่ประชุมใหญ่ ถ้ามิได้ระบุไว้ในข้อบังคับ
                                        หรือที่ประชุมใหญ่มิได้มีมติไว้ ให้ทรัพย์สินนั้นตกเป็นของแผ่นดิน
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </OverlayScrollbarsComponent>

            <div className='row mt-7 px-5'>
                <div className='text-center'>
                    <div className='radio-inline'>
                        <label className='radio radio-success'>
                            <input
                                type='radio'
                                name='agree-btn'
                                checked={!props.disable}
                                onClick={(e) => props.setDisable(!props.disable)}
                            />
                            <span />
                            ยอมรับข้อกำหนดและเงื่อนไข
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
}
