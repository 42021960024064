import React from 'react';

import { QRCode } from 'react-qrcode-logo';

import Moment from 'moment';

import html2canvas from 'html2canvas';

import { toAbsoluteUrl } from '../../../../../../_metronic/_helpers';

export function CremationRegisterResult(props) {
    const handleDownload = () => {
        let d = new Date();
        html2canvas(document.querySelector('#react-qrcode-logo')).then(function(canvas) {
            const link = document.createElement('a');
            link.download = `payment-${Moment(d).format('YYYYMMDDhhmmss')}.png`;
            link.href = canvas.toDataURL();
            link.click();
        });
    };

    return (
        <div className='row justify-content-center'>
            {props.paymenttype === 'bay-qr' ? (
                <div className='col-lg-10 col-12 p-0'>
                    {props.registerres.responsecode === 0 ? (
                        <div className='col-12 p-0'>
                            <div className='text-center'>
                                <QRCode
                                    className='border'
                                    value={props.registerres.qr}
                                    size={256}
                                    eyeRadius={10}
                                    logoImage={toAbsoluteUrl('/media/logos/funeral-logo-bg.png')}
                                    logoWidth={100}
                                />
                                <div className='mt-7'>
                                    <button
                                        type='button'
                                        className='btn btn-primary rounded-lg'
                                        onClick={handleDownload}
                                    >
                                        บันทึกคิวอาร์โค้ด
                                    </button>
                                </div>
                                <h2 className='mt-7'>เตรียมลงทะเบียนเรียบร้อย</h2>
                                <p className='m-0'>
                                    กรุณานำคิวอาร์โค้ดนี้ไปชำระเงินให้เรียบร้อยเพื่อดำเนินการยืนยันในการสมัครสมาชิก
                                </p>
                                <p>
                                    คิวอาร์โค้ดนี้มีอายุ 1 วัน หากเกิน 1
                                    วันกรุณาติดต่อเจ้าหน้าที่เพื่อดำเนินการให้เรียบร้อย
                                </p>
                            </div>
                        </div>
                    ) : (
                        <div className='col-12 p-0'>
                            <div className='text-center'>
                                <img
                                    className='max-h-200px'
                                    alt='qr-failed'
                                    src={toAbsoluteUrl('/media/assets/register/qr-failed.png')}
                                />
                                <h2 className='mt-7'>ไม่สามารถสร้างคิวอาร์โค้ดได้</h2>
                                <p className='m-0'>กรุณาลองใหม่อีกครั้ง</p>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <div className='col-lg-8 col-12 p-0'>
                    {props.registerres.responsecode === 0 ? (
                        <div className='col-12 p-0'>
                            <div className='text-center'>
                                <img
                                    className='max-h-200px'
                                    alt='success-icon'
                                    src={toAbsoluteUrl('/media/assets/register/success-icon.png')}
                                />
                                <h2 className='mt-7'>ลงทะเบียนสำเร็จ</h2>
                                <p className='m-0'>
                                    ขอบคุณที่เข้าร่วมเป็นสมาชิกสมาคมฌาปนกิจสงเคราะห์กลุ่มเครดิตยูเนี่ยนครอบครัวซิมปันสุข
                                </p>
                                <p>ท่านสามารถใช้งานระบบสมาคมฌาปนกิจสงเคราะห์หลังจากออกจากระบบแล้วหนึ่งรอบ</p>
                            </div>
                        </div>
                    ) : (
                        <div className='col-12 p-0'>
                            <div className='text-center'>
                                <img
                                    className='max-h-200px'
                                    alt='failed-icon'
                                    src={toAbsoluteUrl('/media/assets/register/failed-icon.png')}
                                />
                                <h2 className='mt-7'>ลงทะเบียนไม่สำเร็จ</h2>
                                <p className='m-0'>
                                    ไม่สามารถสมัครสมาชิกสมาคมฌาปนกิจสงเคราะห์กลุ่มเครดิตยูเนี่ยนครอบครัวซิมปันสุขได้
                                </p>
                                <p>
                                    กรุณาติดต่อเจ้าหน้าที่ ที่
                                    <a
                                        className='btn btn-link'
                                        href={`https://line.me/ti/p/~@dealersim`}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        เจ้าหน้าที่
                                    </a>
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            )}
            {/* <div className='col-12 text-center p-3'>
                <button type='button' className='btn btn-primary text-white rounded-lg'>
                    กลับไปหน้าแรก
                </button>
            </div> */}
        </div>
    );
}
