import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import NumberFormat from 'react-number-format';

import Swal from 'sweetalert2/dist/sweetalert2.js';

import { CremationDepositOtp, CremationDepositPhonenumber, CremationDepositResult } from './components';

import config from '../../../../config';

const SwalBs = Swal.mixin({
    customClass: {
        popup: 'rounded-lg',
        confirmButton: 'btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto',
        cancelButton: 'btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto'
    },
    buttonsStyling: false
});

export function CremationDepositMain() {
    const { info } = useSelector((state) => state.auth);
    const [ page, setPage ] = useState('main');
    const [ loading, setLoading ] = useState(true);
    const [ loading2, setLoading2 ] = useState(false);
    const [ error, setError ] = useState(false);
    const [ productcode, setProductcode ] = useState('');
    const [ phonenumber, setPhonenumber ] = useState('');
    const [ amount, setAmount ] = useState(0);
    const [ price, setPrice ] = useState(0);
    const [ paymenttype, setPaymenttype ] = useState('1');
    const [ refcode, setRefcode ] = useState('');
    const [ otpcode, setOtpcode ] = useState('');
    const [ disable, setDisable ] = useState(true);
    const [ expiretime, setExpiretime ] = useState('');
    const [ orderres, setOrderres ] = useState({});

    useEffect(() => {
        getProductDetail();
    }, []);

    const handleOtp = (code) => {
        setOtpcode(code);
        if (code.length >= 6) {
            setDisable(false);
        } else {
            setDisable(true);
        }
    };

    const getProductDetail = () => {
        fetch(config.url + 'product/list', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({})
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                // console.log(result);
                if (result.responsecode === 0) {
                    for (const object in result.info) {
                        if (result.info[object]['name'] === 'กองทุนฌาปนกิจ') {
                            // console.log(result.info[object]['price']);
                            setPrice(result.info[object]['price']);
                            setProductcode(result.info[object]['code'])
                        }
                    }
                }
            })
            .catch((error) => {
                setLoading(false);
                setError(true);
            });
    };

    const btnNext = () => {
        if (amount === 0 || amount === '' || amount === '0') {
            SwalBs.fire({
                // title: 'ยกเลิกคำสั่ง',
                html: 'กรุณาใส่จำนวนเงินที่ต้องการฝาก',
                icon: 'warning',
                // showCancelButton: true,
                confirmButtonText: 'รับทราบ',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
            return false;
        }

        if (paymenttype === '1') {
            let param = {
                token: info.info.token,
                payment_channel: 'bay-qr',
                items: [
                    {
                        product_code: productcode,
                        quantity: amount.toString()
                    }
                ]
            }
            order(param);
        
        } else if(paymenttype === '2') {
            if (page === 'main') {
                setPage('phonenumber');
            } else if (page === 'phonenumber') {
                generateOTP();
            } else if (page === 'otp') {
                let param = {
                    token: info.info.token,
                    payment_channel: 'punsook-wallet',
                    items: [
                        {
                            product_code: productcode,
                            quantity: amount.toString()
                        }
                    ],
                    phonenumber: phonenumber,
                    otp: otpcode,
                    ref: refcode
                }
                order(param);
            }
        } else if (paymenttype === '3') {
            let param = {
                token: info.info.token,
                payment_channel: 'credit-card',
                items: [
                    {
                        product_code: productcode,
                        quantity: amount.toString()
                    }
                ]
            }
            order(param);
        }
    }

    const generateOTP = () => {
        setLoading2(true);
        setOtpcode('');
        // setRefcode('');
        // setExpiretime('');
        fetch(config.url + 'sim/otp-generate', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                phonenumber: phonenumber
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading2(false);
                if (result.responsecode === 0) {
                    setRefcode(result.refcode);
                    setExpiretime(result.timeout);
                    setPage('otp');
                    setDisable(true);
                } else {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `${result.responsecode}! มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง`,
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                }
            })
            .catch((error) => {
                setLoading2(false);
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    icon: 'error',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                // setInit(true);
            });
    }

    const order = (param) => {
        setOrderres({});
        setLoading2(true);
        fetch(config.url + 'order/create', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify(param)
        })
            .then((response) => response.json())
            .then((result) => {
                if (paymenttype === '2') {
                    if (result.responsecode === 110) {
                        setLoading2(false);
                        SwalBs.fire({
                            // title: 'ยกเลิกคำสั่ง',
                            html: 'ไม่สามารถชำระค่าบริการด้วยกระเป๋าซิมปันสุขได้ กรุณาลองใหม่ด้วยคิวอาร์โค้ด',
                            icon: 'warning',
                            // showCancelButton: true,
                            confirmButtonText: 'รับทราบ',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        }).then((result) => {
                            if (result.isConfirmed) {
                                setPaymenttype('1');
                                // setDisablewallet(true);
                                setPage('main');
                            }
                        });
                        return;
                    }
                }
                setLoading2(false);
                setOrderres(result);
                setPage('result');
            })
            .catch((error) => {
                setLoading2(false);
                setOrderres({
                    responsecode: 99
                });
                setPage('result');
            });
    }

    const Completionist = () => {
        return (
            <div className='row justify-content-center col-12 p-0 m-0'>
                <button className='btn btn-primary rounded-lg' onClick={generateOTP}>
                    ขอ OTP ใหม่
                </button>
            </div>
        );
    };

    const renderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            // Render a complete state
            // ("2020-01-02 11:00:00");
            return <Completionist />;
        } else {
            // Render a countdown
            return (
                <div className='row col-12 p-0 m-0 text-center'>
                    <p className='col-12 m-0'>กรุณาลองใหม่ในอีก</p>
                    <span className='col-12'>
                        {(minutes < 10 ? '0' : '') + minutes}:
                        {(seconds < 10 ? '0' : '') + seconds}
                    </span>
                </div>
            );
        }
    };

    return (
        <div className='d-block' id='stock-order'>
            {(loading || loading2) && (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            )}

            <div className='text-center mb-10 mb-lg-20'>
                <h3 className='font-size-h1'>ฝากเงิน</h3>
                <p className='text-muted font-weight-bold'>กลุ่มฌาปนกิจ</p>
            </div>

            {page === 'main' ? (
                <>
                    {!loading &&
                        <>
                            {!error ? (
                                <div className='row justify-content-center mb-10'>
                                    <div className='col-lg-5'>
                                        <div className='form-group'>
                                            <label>จำนวนเงิน</label>
                                            <div className='input-group'>
                                                <input
                                                    type='text'
                                                    inputMode='tel'
                                                    className='form-control'
                                                    value={amount}
                                                    onChange={(e) => setAmount(e.target.value)}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            if (event.key !== 'Enter') {
                                                                event.preventDefault();
                                                            }
                                                        }
                                                    }}
                                                />
                                                <div className='input-group-append'>
                                                    <span className='input-group-text'>บาท</span>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className='d-flex col-xl-12 p-0'>
                                            <div className='p-2'>ราคาหุ้น</div>
                                            <div className='ml-auto p-2 text-right'>
                                                <NumberFormat
                                                    value={parseFloat(price * amount)}
                                                    displayType={'text'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}
                                                    suffix={' บาท'}
                                                />
                                            </div>
                                        </div> */}
                                        <div className='title p-2'>วิธีการชำระเงิน</div>
                                        <div className='row d-flex justify-content-center text-center mx-auto mb-5'>
                                            <div className='payment-icon col-xl-5 col-6 d-inline'>
                                                <label>
                                                    <img
                                                        alt='thaiqr-logo'
                                                        src='https://cdn.simk4.com/payment/thatqr.png'
                                                        className={`payment-img${paymenttype === '1'
                                                            ? '-active'
                                                            : ''} max-h-120px max-w-120px`}
                                                    />
                                                    <input
                                                        type='radio'
                                                        name='payment-method'
                                                        value='1'
                                                        checked={paymenttype === '1'}
                                                        onChange={() => setPaymenttype('1')}
                                                        // className="ng-untouched ng-pristine ng-valid"
                                                    />
                                                </label>
                                            </div>
                                            <div className='payment-icon col-xl-5 col-6 d-inline'>
                                                <label>
                                                    <img
                                                        alt='wallet-logo'
                                                        src='https://cdn.simk4.com/payment/credit_card.png'
                                                        className={`payment-img${paymenttype === '3'
                                                            ? '-active'
                                                            : ''} max-h-120px max-w-120px`}
                                                    />
                                                    <input
                                                        type='radio'
                                                        name='payment-method'
                                                        value='3'
                                                        checked={paymenttype === '3'}
                                                        onChange={() => setPaymenttype('3')}
                                                    />
                                                </label>
                                            </div>
                                            <div className='payment-icon col-xl-5 col-6 d-inline'>
                                                <label>
                                                    <img
                                                        alt='wallet-logo'
                                                        src='https://cdn.simk4.com/payment/wallet2.png'
                                                        className={`payment-img${paymenttype === '2'
                                                            ? '-active'
                                                            : ''} max-h-120px max-w-120px`}
                                                    />
                                                    <input
                                                        type='radio'
                                                        name='payment-method'
                                                        value='2'
                                                        checked={paymenttype === '2'}
                                                        onChange={() => setPaymenttype('2')}
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                        <div className='form-group d-flex flex-wrap flex-center mt-10'>
                                            <button
                                                type='button'
                                                className='btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4 rounded-lg'
                                                onClick={btnNext}
                                            >
                                                <span>{paymenttype === '1' || paymenttype === '3'? 'ชำระเงิน' : 'ถัดไป'}</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className='h-400px'>
                                    <div className='row justify-content-center align-items-center h-100'>
                                        <h1>ไม่สามารถดึงราคาหุ้นได้ กรุณาลองใหม่อีกครั้ง</h1>
                                    </div>
                                </div>
                            )}
                        </>
                    }
                </>
            ) : page === 'phonenumber' ? (
                <CremationDepositPhonenumber
                    phonenumber={phonenumber}
                    setPhonenumber={setPhonenumber}
                    btnNext={btnNext}
                />
            ) : page === 'otp' ? (
                <CremationDepositOtp
                    loading={loading2}
                    otpcode={otpcode}
                    refcode={refcode}
                    expiretime={expiretime}
                    handleOtp={handleOtp}
                    renderer={renderer}
                    disable={disable}
                    btnNext={btnNext}
                />
            ) : page === 'result' ? (
                <CremationDepositResult orderres={orderres} paymenttype={paymenttype}/>
            ) : null}
        </div>
    );
}
