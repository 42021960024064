import React from 'react';

import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import NumberFormat from 'react-number-format';

export function RegisterStepSummary(props) {
    return (
        <div className='row d-flex justify-content-center pb-5'>
            <div className='col-sm-5 col-md-6 mb-5 mb-md-0'>
                <div className='py-4 d-flex flex-row'>
                    {/* <img
                        alt='Logo'
                        src={toAbsoluteUrl('/media/logos/logo_sim_punsuk_final.png')}
                        className='logo-sticky max-h-35px'
                    /> */}
                    <h5 className='my-auto'>
                        <b>สรุปรายละเอียด</b>
                    </h5>
                    {/* <span className='pl-2 my-auto'>ซิม</span> */}
                </div>
                <OverlayScrollbarsComponent className='bg-light'>
                    <div className='p-5'>
                        <div className='d-flex row m-0 p-2'>
                            <div className='col-xl-12 d-flex'>
                                <div>
                                    <p>
                                        {/* <i className='fas fa-truck mr-3' /> */}
                                        <b>ข้อมูลสมาชิก</b>
                                    </p>
                                </div>
                            </div>
                            <div className='col-xl-12 p-0'>
                                <div className='rounded bg-white row m-0 p-3'>
                                    <div className='d-flex col-xl-12'>
                                        <div className='p-2'>ชื่อ</div>
                                        <div className='ml-auto p-2 text-right'>{props.firstname}</div>
                                    </div>
                                    <div className='d-flex col-xl-12'>
                                        <div className='p-2'>นามสกุล</div>
                                        <div className='ml-auto p-2 text-right'>{props.lastname}</div>
                                    </div>
                                    <div className='d-flex col-xl-12'>
                                        <div className='p-2'>วัน/เดือน/ปีเกิด</div>
                                        <div className='ml-auto p-2 text-right'>{props.birthdate}</div>
                                    </div>
                                    <div className='d-flex col-xl-12'>
                                        <div className='p-2'>เบอร์โทรศัพท์ติดต่อ</div>
                                        <div className='ml-auto p-2 text-right'>{props.contactnumber}</div>
                                    </div>
                                    <div className='d-flex col-xl-12'>
                                        <div className='p-2'>สัญชาติ</div>
                                        <div className='ml-auto p-2 text-right'>
                                            {props.nationality === '1' ? 'ไทย' : 'ต่างชาติ'}
                                        </div>
                                    </div>
                                    <div className='d-flex col-xl-12'>
                                        <div className='p-2'>
                                            {props.nationality === '1' ? 'หมายเลขบัตรประชาชน' : 'หมายเลขพาสปอร์ต'}
                                        </div>
                                        <div className='ml-auto p-2 text-right'>{props.personalcode}</div>
                                    </div>
                                    <div className='d-flex col-xl-12'>
                                        <div className='p-2'>จังหวัด</div>
                                        <div className='ml-auto p-2 text-right'>{props.province.label}</div>
                                    </div>
                                    <div className='d-flex col-xl-12'>
                                        <div className='p-2'>อำเภอ / เขต</div>
                                        <div className='ml-auto p-2 text-right'>{props.district.label}</div>
                                    </div>
                                    <div className='d-flex col-xl-12'>
                                        <div className='p-2'>ตำบล / แขวง</div>
                                        <div className='ml-auto p-2 text-right'>{props.subdistrict.label}</div>
                                    </div>
                                    <div className='d-flex col-xl-12'>
                                        <div className='p-2'>รหัสไปรษณีย์</div>
                                        <div className='ml-auto p-2 text-right'>{props.zipcode}</div>
                                    </div>
                                    <div className='d-flex col-xl-12'>
                                        <div className='p-2'>บ้านเลขที่ ถนน ซอย</div>
                                        <div className='ml-auto p-2 text-right'>{props.address1}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </OverlayScrollbarsComponent>
            </div>
            <div className='col-sm-3 col-md-6 mobile'>
                <div className='bg-light rounded d-flex flex-column p-3'>
                    <div className='p-2 ml-3'>
                        <h4>รายละเอียดค่าใช้จ่ายและช่องทางการชำระเงิน</h4>
                    </div>
                    <div className='mx-5 p-2 d-flex bg-white rounded'>
                        <div className='row m-0'>
                            <div className='col-7 my-2'>
                                <b>ค่าธรรมเนียมแรกเข้า</b>
                            </div>
                            <div className='ml-auto my-2 text-right'>
                                <NumberFormat
                                    value={100}
                                    displayType={'text'}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandSeparator={true}
                                    suffix={' บาท'}
                                />
                            </div>
                            <div className='col-7 my-2'>
                                <b>ค่าหุ้น</b>
                            </div>
                            <div className='ml-auto my-2 text-right'>
                                <NumberFormat
                                    value={100}
                                    displayType={'text'}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandSeparator={true}
                                    suffix={' บาท'}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='separator separator-dashed mt-10 mb-5' />
                    <div className='d-flex flex-row align-content-center p-4'>
                        <div className='pt-2 pr-2 col-xl-12'>
                            <div className='radio-inline'>
                                <label className='radio radio-primary col-xl-12'>
                                    <input
                                        type='radio'
                                        name='radio'
                                        value='2'
                                        checked={props.paymenttype === 'bay-qr'}
                                        onChange={() => props.setPaymenttype('bay-qr')}
                                    />
                                    <span />
                                    <div className='rounded border d-flex w-100 px-2 bg-white'>
                                        <div className='row p-0 m-0'>
                                            <p className='my-auto'>
                                                <i className='fas fa-qrcode text-primary pr-2' />
                                                ชำระเงินผ่านคิวอาร์โค้ด
                                            </p>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex flex-row align-content-center p-4'>
                        <div className='pt-2 pr-2 col-xl-12'>
                            <div className='radio-inline'>
                                <label className='radio radio-primary col-xl-12'>
                                    <input
                                        type='radio'
                                        name='radio'
                                        value='3'
                                        checked={props.paymenttype === 'punsook-wallet'}
                                        onChange={() => props.setPaymenttype('punsook-wallet')}
                                        disabled={props.disablewallet}
                                    />
                                    <span />
                                    <div className='rounded border d-flex w-100 px-2 bg-white'>
                                        <div className='row p-0 m-0'>
                                            <p className='my-auto'>
                                                <i className='fas fa-wallet text-primary pr-2' />
                                                กระเป๋าซิมปันสุข
                                            </p>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='p-2 d-flex pt-3 font-size-h4'>
                        <div className='col-8'>
                            <b>ยอดรวม</b>
                        </div>
                        <div className='ml-auto'>
                            <b className='green'>
                                <NumberFormat
                                    value={200}
                                    displayType={'text'}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandSeparator={true}
                                    suffix={' บาท'}
                                />
                            </b>
                        </div>
                    </div>
                    <div className='m-3'>
                        <button type='button' onClick={props.btnNext} className='btn btn-primary btn-block rounded-lg'>
                            ชำระเงิน
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
