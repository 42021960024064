import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import NumberFormat from 'react-number-format';

import initaddress from '../../../../address';

import config from '../../../../config';

export function UserInformationMain() {
    const { info } = useSelector((state) => state.auth);
    const [ loading, setLoading ] = useState(true);
    const [ error, setError ] = useState(false);
    const [ province, setProvince ] = useState('');
    const [ district, setDistrict ] = useState('');
    const [ subdistrict, setSubdistrict ] = useState('');
    const [ account, setAccount ] = useState([]);

    useEffect(() => {
        for (const thisprovince in initaddress.province) {
            if (initaddress.province[thisprovince]['value'] === info.info.province) {
                setProvince(initaddress.province[thisprovince]['label']);
                break;
            }
        }
        for (const thisdistrict in initaddress.district) {
            if (initaddress.district[thisdistrict]['value'] === info.info.district) {
                setDistrict(initaddress.district[thisdistrict]['label']);
                break;
            }
        }
        for (const thissubdistrict in initaddress.subdistrict) {
            if (initaddress.subdistrict[thissubdistrict]['value'] === info.info.subdistrict) {
                setSubdistrict(initaddress.subdistrict[thissubdistrict]['label']);
                break;
            }
        }
        getAccount();
    }, []);

    const getAccount = () => {
        setLoading(true);
        fetch(config.url + 'account/get-balance', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: info.info.token
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    setAccount(Object.values(result.account));
                } else {
                    setError(true);
                }
            })
            .catch((error) => {
                setLoading(false);
                setError(true);
                // setInit(true);
            });
    };

    return (
        <div className='d-flex row mb-7'>
            <div className='col-lg-8 col-12'>
                <div className='card card-custom card-stretch'>
                    <div className='card-header py-3'>
                        <div className='card-title align-items-start flex-column'>
                            <h3 className='card-label font-weight-bolder text-dark'>ข้อมูลสมาชิก</h3>
                        </div>
                    </div>
                    <div className='card-body'>
                        <div className='form-group row'>
                            <label className='col-xl-3 col-lg-3 col-form-label'>ชื่อ</label>
                            <div className='col-lg-9 col-xl-6'>
                                <input
                                    className='form-control form-control-lg rounded-lg border-0'
                                    type='text'
                                    value={info.info.firstname}
                                    readOnly
                                    // defaultValue='Nick'
                                />
                            </div>
                        </div>
                        <div className='form-group row'>
                            <label className='col-xl-3 col-lg-3 col-form-label'>นามสกุล</label>
                            <div className='col-lg-9 col-xl-6'>
                                <input
                                    className='form-control form-control-lg rounded-lg border-0'
                                    type='text'
                                    value={info.info.lastname}
                                    readOnly
                                    // defaultValue='Bold'
                                />
                            </div>
                        </div>
                        <div className='form-group row'>
                            <label className='col-xl-3 col-lg-3 col-form-label'>
                                วัน/เดือน/ปีเกิด (ปีคริสต์ศักราช)
                            </label>
                            <div className='col-lg-9 col-xl-6'>
                                <input
                                    className='form-control form-control-lg rounded-lg border-0'
                                    type='text'
                                    value={info.info.birthdate}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className='form-group row'>
                            <label className='col-xl-3 col-lg-3 col-form-label'>เบอร์โทรศัพท์ติดต่อ</label>
                            <div className='col-lg-9 col-xl-6'>
                                <input
                                    className='form-control form-control-lg rounded-lg border-0'
                                    type='text'
                                    value={info.info.contact_number}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className='form-group row'>
                            <label className='col-xl-3 col-lg-3 col-form-label'>สัญชาติ</label>
                            <div className='col-lg-9 col-xl-6'>
                                <input
                                    className='form-control form-control-lg rounded-lg border-0'
                                    type='text'
                                    value={info.info.nationality === 'Thai' ? 'ไทย' : 'ต่างชาติ'}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className='form-group row'>
                            <label className='col-xl-3 col-lg-3 col-form-label'>
                                {info.info.nationality === 'Thai' ? 'หมายเลขบัตรประชาชน' : 'หมายเลขพาสปอร์ต'}
                            </label>
                            <div className='col-lg-9 col-xl-6'>
                                <input
                                    className='form-control form-control-lg rounded-lg border-0'
                                    type='text'
                                    value={info.info.id_card}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className='form-group row'>
                            <label className='col-xl-3 col-lg-3 col-form-label'>จังหวัด</label>
                            <div className='col-lg-9 col-xl-6'>
                                <input
                                    className='form-control form-control-lg rounded-lg border-0'
                                    type='text'
                                    value={province}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className='form-group row'>
                            <label className='col-xl-3 col-lg-3 col-form-label'>
                                {info.info.province === '1' ? 'เขต' : 'อำเภอ'}
                            </label>
                            <div className='col-lg-9 col-xl-6'>
                                <input
                                    className='form-control form-control-lg rounded-lg border-0'
                                    type='text'
                                    value={district}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className='form-group row'>
                            <label className='col-xl-3 col-lg-3 col-form-label'>
                                {info.info.province === '1' ? 'แขวง' : 'ตำบล'}
                            </label>
                            <div className='col-lg-9 col-xl-6'>
                                <input
                                    className='form-control form-control-lg rounded-lg border-0'
                                    type='text'
                                    value={subdistrict}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className='form-group row'>
                            <label className='col-xl-3 col-lg-3 col-form-label'>รหัสไปรษณีย์</label>
                            <div className='col-lg-9 col-xl-6'>
                                <input
                                    className='form-control form-control-lg rounded-lg border-0'
                                    type='text'
                                    value={info.info.zipcode}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className='form-group row'>
                            <label className='col-xl-3 col-lg-3 col-form-label'>บ้านเลขที่ ถนน ซอย</label>
                            <div className='col-lg-9 col-xl-6'>
                                <input
                                    className='form-control form-control-lg rounded-lg border-0'
                                    type='text'
                                    value={info.info.address}
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-lg-4 col-12 mt-5 mt-md-0'>
                <div className='card card-custom card-stretch'>
                    <div className='card-header border-0'>
                        <h3 className='card-title font-weight-bolder text-dark'>กระเป๋าที่คุณมี</h3>
                    </div>
                    {loading ? (
                        <div className='card-body pt-0'>
                            <div className='row justify-content-center align-content-center m-0 h-100'>
                                <div className='col-12 text-center p-0'>
                                    <i className='fas fa-spinner fa-pulse fa-3x' />
                                    <h6 className='mt-5'>กรุณารอสักครู่</h6>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            {!error ? (
                                <div className='card-body pt-0'>
                                    {account.map((object, index, arr) => {
                                        return (
                                            <div
                                                className={`inline-card border-solid rounded-lg ${index ===
                                                arr.length - 1
                                                    ? ''
                                                    : 'mb-5'}`}
                                                key={index}
                                            >
                                                <div className='card border-0 p-5'>
                                                    <div className='symbol symbol-40 symbol-light-white mr-5'>
                                                        <div className='symbol-label'>
                                                            <i
                                                                className={`fas fa-${object.type === '1'
                                                                    ? 'university'
                                                                    : object.type === '2' ? 'users' : object.type === '3' ||  object.type === '5' ? 'coins' : 'wallet'} fa-2x`}
                                                            />
                                                        </div>
                                                    </div>
                                                    <p className='card-number font-size-h6 m-0'>
                                                        {object.type === '1' ? (
                                                            'หุ้นสหกรณ์'
                                                        ) : object.type === '2' ? (
                                                            'ฌาปนกิจ'
                                                        ) : object.type === '3' ? (
                                                            'หน่วยลงทุน'
                                                        ) : object.type === '4' ? (
                                                            'กระเป๋าเงิน'
                                                        ) : object.type === '5' ? (
                                                            'หน่วยลงทุนพิเศษ'
                                                        ) : null}
                                                    </p>
                                                    <p className='card-number font-size-h6 m-0 ml-auto'>
                                                        <NumberFormat
                                                            value={parseFloat(object.balance)}
                                                            displayType={'text'}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            thousandSeparator={true}
                                                            suffix={object.type === '1' ? ' หุ้น' : ' บาท'}
                                                        />
                                                    </p>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : (
                                <div className='card-body pt-0'>
                                    <div className='row justify-content-center align-content-center m-0 h-100'>
                                        <div className='col-12 text-center p-0'>
                                            {/* <i className='fas fa-spinner fa-pulse fa-3x' /> */}
                                            <h6 className='mt-5'>ไม่สามารถดึงข้อมูลได้</h6>
                                            <h6>กรุณาลองใหม่อีกครั้ง</h6>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
