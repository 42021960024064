import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import { injectIntl } from 'react-intl';

import { connect } from 'react-redux';

import Swal from 'sweetalert2/dist/sweetalert2.js';

import {
    CremationRegisterTerm,
    CremationRegisterSummary,
    CremationRegisterPhonenumber,
    CremationRegisterOtp,
    CremationRegisterResult
} from './components';

import * as auth from '../../../../modules/Auth/_redux/authRedux';

import config from '../../../../config';

const SwalBs = Swal.mixin({
    customClass: {
        popup: 'rounded-lg',
        confirmButton: 'btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto',
        cancelButton: 'btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto'
    },
    buttonsStyling: false
});

function CremationRegisterMain(props) {
    const { info } = useSelector((state) => state.auth);
    const [ loading, setLoading ] = useState(false);
    const [ page, setPage ] = useState('terms');
    const [ disable, setDisable ] = useState(true);
    const [ paymenttype, setPaymenttype ] = useState('bay-qr');
    const [ phonenumber, setPhonenumber ] = useState('');
    const [ refcode, setRefcode ] = useState('');
    const [ otpcode, setOtpcode ] = useState('');
    const [ expiretime, setExpiretime ] = useState('');
    const [ registerres, setRegisterres ] = useState({});

    const handleOtp = (code) => {
        setOtpcode(code);
        if (code.length >= 6) {
            setDisable(false);
        } else {
            setDisable(true);
        }
    };

    const btnNext = () => {
        if (page === 'terms') {
            setPage('summary');
        } else if (page === 'summary') {
            if (paymenttype === 'bay-qr') {
                let param = {
                    token: info.info.token,
                    payment_channel: 'bay-qr'
                };
                register(param);
            } else {
                setPage('phonenumber');
            }
        } else if (page === 'phonenumber') {
            generateOTP();
        } else if (page === 'otp') {
            let param = {
                token: info.info.token,
                payment_channel: 'punsook-wallet',
                phonenumber: phonenumber,
                otp: otpcode,
                ref: refcode
            };
            register(param);
        }
    };

    const generateOTP = () => {
        setLoading(true);
        setOtpcode('');
        // setRefcode('');
        // setExpiretime('');
        fetch(config.url + 'sim/otp-generate', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                phonenumber: phonenumber
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    setRefcode(result.refcode);
                    setExpiretime(result.timeout);
                    setPage('otp');
                    setDisable(true);
                } else {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `${result.responsecode}! มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง`,
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    icon: 'error',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                // setInit(true);
            });
    };

    const register = (param) => {
        setLoading(true);
        fetch(config.url + 'funeral/register', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify(param)
        })
            .then((response) => response.json())
            .then((result) => {
                if (paymenttype === 'bay-qr') {
                    if (result.responsecode === 0) {
                        // console.log('success qr');
                        let newinfo = info;
                        newinfo['group']['role_id'] = 3;
                        // console.log(newinfo);
                        props.login(newinfo);
                    }
                } else {
                    if (result.responsecode === 0) {
                        // console.log('success payment');
                        let newinfo = info;
                        newinfo['group']['role_id'] = 4;
                        // console.log(newinfo);
                        props.login(newinfo);
                    } else if (result.responsecode === 108) {
                        setLoading(false);
                        SwalBs.fire({
                            // title: 'ยกเลิกคำสั่ง',
                            html: 'ไม่สามารถชำระค่าบริการด้วยกระเป๋าซิมปันสุขได้ กรุณาลองใหม่ด้วยคิวอาร์โค้ด',
                            icon: 'warning',
                            // showCancelButton: true,
                            confirmButtonText: 'รับทราบ',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        }).then((result) => {
                            if (result.isConfirmed) {
                                setPaymenttype('bay-qr');
                                // setDisablewallet(true);
                                setPage('summary');
                            }
                        });
                        return;
                    }
                }
                setLoading(false);
                setRegisterres(result);
                setPage('result');
                // if (result.responsecode === 0) {
                //     setPage('result');
                // }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const Completionist = () => {
        return (
            <div className='row justify-content-center col-12 p-0 m-0'>
                <button className='btn btn-primary rounded-lg' onClick={generateOTP}>
                    ขอ OTP ใหม่
                </button>
            </div>
        );
    };

    const renderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            // Render a complete state
            // ("2020-01-02 11:00:00");
            return <Completionist />;
        } else {
            // Render a countdown
            return (
                <div className='row col-12 p-0 m-0 text-center'>
                    <p className='col-12 m-0'>กรุณาลองใหม่ในอีก</p>
                    <span className='col-12'>
                        {(minutes < 10 ? '0' : '') + minutes}:
                        {(seconds < 10 ? '0' : '') + seconds}
                    </span>
                </div>
            );
        }
    };

    return (
        <div className='row justify-content-center m-0 mb-md-7 mb-0'>
            {loading && (
                <div className='col-12 p-0'>
                    <div className='d-flex flex-column flex-root' id='loading-section'>
                        <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                            <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                                <div className='bounce col-12'>
                                    <div className='bounce1' />
                                    <div className='bounce2' />
                                    <div className='bounce3' />
                                </div>
                                <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className='col-lg-8 bg-white rounded-lg p-7'>
                <div className='text-center mb-10 mb-lg-20'>
                    <h3 className='font-size-h1'>แบบฟอร์มใบสมัครสมาชิก</h3>
                    <p className='text-muted font-weight-bold'>ฌาปนกิจ</p>
                </div>

                <div className='mb-10'>
                    {page === 'terms' ? (
                        <CremationRegisterTerm disable={disable} setDisable={setDisable} />
                    ) : page === 'summary' ? (
                        <CremationRegisterSummary
                            paymenttype={paymenttype}
                            setPaymenttype={setPaymenttype}
                            btnNext={btnNext}
                        />
                    ) : page === 'phonenumber' ? (
                        <CremationRegisterPhonenumber
                            phonenumber={phonenumber}
                            setPhonenumber={setPhonenumber}
                            btnNext={btnNext}
                        />
                    ) : page === 'otp' ? (
                        <CremationRegisterOtp
                            otpcode={otpcode}
                            refcode={refcode}
                            expiretime={expiretime}
                            handleOtp={handleOtp}
                            renderer={renderer}
                            disable={disable}
                            btnNext={btnNext}
                        />
                    ) : page === 'result' ? (
                        <CremationRegisterResult paymenttype={paymenttype} registerres={registerres} />
                    ) : null}
                </div>

                <div className='form-group d-flex flex-wrap flex-center'>
                    {page === 'terms' && (
                        <button
                            type='button'
                            className='btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4 rounded-lg'
                            onClick={btnNext}
                            disabled={disable}
                        >
                            <span>ถัดไป</span>
                            {/* {loading && <span className="ml-3 spinner spinner-white"></span>} */}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(CremationRegisterMain));
