import React, { useState } from 'react';

import { Link } from 'react-router-dom';

// import Moment from 'moment';

import Swal from 'sweetalert2/dist/sweetalert2.js';

import {
    RegisterStep1,
    RegisterStepPhone,
    RegisterStepOtp,
    RegisterStepOtp2,
    RegisterStepForm,
    RegisterStepSummary,
    RegisterStepResult
} from './components';

import initaddress from '../../../address';

import config from '../../../config';

const SwalBs = Swal.mixin({
    customClass: {
        popup: 'rounded-lg',
        confirmButton: 'btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto',
        cancelButton: 'btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto'
    },
    buttonsStyling: false
});

const initBenefitList = {
    contact_firstname: '',
    contact_lastname: '',
    contact_phonenumber: '',
    address1: '',
    address2: '',
    province: '',
    district: '',
    subdistrict: '',
    postalcode: ''
};

export function RegisterMainPage() {
    const [ page, setPage ] = useState('terms');
    const [ loading, setLoading ] = useState(false);
    const [ agreement, setAgreement ] = useState(false);
    const [ disable, setDisable ] = useState(false);
    const [ phonenumber, setPhonenumber ] = useState('');
    const [ otpcode, setOtpcode ] = useState('');
    const [ refcode, setRefcode ] = useState('');
    const [ expiretime, setExpiretime ] = useState('');
    const [ firstname, setFirstname ] = useState('');
    const [ lastname, setLastname ] = useState('');
    const [ birthdate, setBirthdate ] = useState('');
    const [ nationality, setNationality ] = useState('1');
    const [ personalcode, setPersonalcode ] = useState('');
    const [ contactnumber, setContactnumber ] = useState('');
    const [ address1, setAddress1 ] = useState('');
    const [ address2, setAddress2 ] = useState('');
    const [ mainprovince, setMainprovince ] = useState(initaddress.province);
    const [ province, setProvince ] = useState('');
    const [ maindistrict, setMaindistrict ] = useState([]);
    const [ district, setDistrict ] = useState('');
    const [ mainsubdistrict, setMainsubdistrict ] = useState([]);
    const [ subdistrict, setSubdistrict ] = useState('');
    const [ zipcode, setZipcode ] = useState('');
    const [ benefitlist, setBenefitlist ] = useState({ 0: initBenefitList });
    const [ paymenttype, setPaymenttype ] = useState('bay-qr');
    const [ disablewallet, setDisablewallet ] = useState(false);
    const [ registerres, setRegisterres ] = useState({});
    const [ disableotp, setDisableotp ] = useState(false);

    const dobValidate = (data) => {
        let datelist = data.split('-');
        if (parseInt(datelist[2]) === 0 || parseInt(datelist[1]) === 0) {
            if (parseInt(datelist[0]) === 0) {
                return true;
            }
            let today = new Date();
            today.toLocaleString('th-TH', { timeZone: 'Asia/Bangkok' });
            let nowyear = today.getFullYear();
            let birth = new Date(datelist[0] + '-' + '01-01');
            birth.toLocaleString('th-TH', { timeZone: 'Asia/Bangkok' });
            let birthyear = birth.getFullYear();
            let age = nowyear - birthyear;
            if (age > 150) {
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: 'อายุของคุณเกินกำหนด กรุณาติดต่อเจ้าหน้าที่',
                    icon: 'warning',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return false;
                // return false;
            }
            if (age < 18) {
                // alert("Age should be more than 18 years.Please enter a valid Date of Birth");
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: 'อายุของคุณต่ำกว่า 18 ปี ไม่สามารถเข้าร่วมกลุ่มเครดิตยูเนี่ยนได้',
                    icon: 'warning',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return false;
                // return false;
            }
            return true;
        }
        let today = new Date();
        today.toLocaleString('th-TH', { timeZone: 'Asia/Bangkok' });
        // console.log(today);
        let nowyear = today.getFullYear();
        let nowmonth = today.getMonth();
        let nowday = today.getDate();
        let birth = new Date(data);
        birth.toLocaleString('th-TH', { timeZone: 'Asia/Bangkok' });
        // console.log(birth);
        let birthyear = birth.getFullYear();
        let birthmonth = birth.getMonth();
        let birthday = birth.getDate();
        let age = nowyear - birthyear;
        // var age_month = nowmonth - birthmonth;
        // var age_day = nowday - birthday;
        // console.log(age);
        // console.log(age_month);
        // console.log(age_day);
        if (age > 150) {
            SwalBs.fire({
                // title: 'ยกเลิกคำสั่ง',
                html: 'อายุของคุณเกินกำหนด กรุณาติดต่อเจ้าหน้าที่',
                icon: 'warning',
                // showCancelButton: true,
                confirmButtonText: 'รับทราบ',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
            return false;
            // return false;
        }
        if (age < 18) {
            // alert("Age should be more than 18 years.Please enter a valid Date of Birth");
            SwalBs.fire({
                // title: 'ยกเลิกคำสั่ง',
                html: 'อายุของคุณต่ำกว่า 18 ปี ไม่สามารถเข้าร่วมกลุ่มเครดิตยูเนี่ยนได้',
                icon: 'warning',
                // showCancelButton: true,
                confirmButtonText: 'รับทราบ',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
            return false;
            // return false;
        }
        if (age === 18) {
            if (nowmonth < birthmonth) {
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: 'อายุของคุณต่ำกว่า 18 ปี ไม่สามารถเข้าร่วมกลุ่มเครดิตยูเนี่ยนได้',
                    icon: 'warning',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return false;
            }
            if (nowmonth === birthmonth) {
                if (nowday < birthday) {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: 'อายุของคุณต่ำกว่า 18 ปี ไม่สามารถเข้าร่วมกลุ่มเครดิตยูเนี่ยนได้',
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return false;
                }
            }
        }
        return true;
    };

    const handleOtp = (code) => {
        setOtpcode(code);
        if (code.length >= 6) {
            setDisable(false);
        } else {
            setDisable(true);
        }
    };

    const btnNext = () => {
        if (page === 'terms') {
            setPage('punsooknumberstep');
        } else if (page === 'punsooknumberstep') {
            if (phonenumber === '') {
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: 'กรุณากรอกเบอร์ซิมปันสุขให้เรียบร้อย',
                    icon: 'warning',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            }
            sendOtp('otpinfo');
        } else if (page === 'otpinfo') {
            getInfo();
        } else if (page === 'form') {
            if (firstname === '') {
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: 'กรุณากรอกชื่อให้เรียบร้อย',
                    icon: 'warning',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            }
            if (lastname === '') {
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: 'กรุณากรอกนามสกุลให้เรียบร้อย',
                    icon: 'warning',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            }
            if (birthdate === '') {
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: 'กรุณากรอกวัน/เดือน/ปีเกิดให้เรียบร้อย',
                    icon: 'warning',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            } else {
                // console.log(birthdate);
                // console.log(birthdate.length);
                if (birthdate.length !== 10 || birthdate.includes('_')) {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: 'กรุณากรอกวัน/เดือน/ปีเกิดให้ครบถ้วน',
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                } else {
                    let birthdatelist = birthdate.split('/');
                    let newBirthday = birthdatelist[2] + '-' + birthdatelist[1] + '-' + birthdatelist[0];
                    if (parseInt(birthdatelist[1]) > 12 || parseInt(birthdatelist[1]) < 0) {
                        SwalBs.fire({
                            // title: 'ยกเลิกคำสั่ง',
                            html: 'กรุณากรอกเดือนเกิดให้ถูกต้อง',
                            icon: 'warning',
                            // showCancelButton: true,
                            confirmButtonText: 'รับทราบ',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        });
                        return;
                    }
                    let maxdate = new Date(parseInt(birthdatelist[2]), parseInt(birthdatelist[1]), 0).getDate();
                    if (parseInt(birthdatelist[0]) > maxdate || parseInt(birthdatelist[0]) < 0) {
                        SwalBs.fire({
                            // title: 'ยกเลิกคำสั่ง',
                            html: 'กรุณากรอกวันเกิดให้ถูกต้อง',
                            icon: 'warning',
                            // showCancelButton: true,
                            confirmButtonText: 'รับทราบ',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        });
                        return;
                    }
                    let checkBirthday = dobValidate(newBirthday);
                    if (checkBirthday === false) {
                        //     SwalBs.fire({
                        //         // title: 'ยกเลิกคำสั่ง',
                        //         html: 'อายุไม่ถึง 18 ปี',
                        //         icon: 'warning',
                        //         // showCancelButton: true,
                        //         confirmButtonText: 'รับทราบ',
                        //         allowOutsideClick: false,
                        //         allowEscapeKey: false,
                        //         allowEnterKey: false
                        //     });
                        return;
                    }
                }
            }
            if (contactnumber === '') {
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: 'กรุณากรอกเบอร์โทรศัพท์ติดต่อให้เรียบร้อย',
                    icon: 'warning',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            }
            if (personalcode === '') {
                let text = nationality === '1' ? 'หมายเลขบัตรประชาชน' : 'หมายเลขพาสปอร์ต';
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: 'กรุณากรอก' + text + 'ให้เรียบร้อย',
                    icon: 'warning',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            }
            if (province === '') {
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: 'กรุณาเลือกจังหวัดให้เรียบร้อย',
                    icon: 'warning',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            }
            if (district === '') {
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: 'กรุณาเลือกอำเภอ / เขตให้เรียบร้อย',
                    icon: 'warning',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            }
            if (subdistrict === '') {
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: 'กรุณาเลือกตำบล / แขวงให้เรียบร้อย',
                    icon: 'warning',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            }
            if (zipcode === '') {
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: 'กรุณากรอกรหัสไปรษณีย์ให้เรียบร้อย',
                    icon: 'warning',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            }
            if (address1 === '') {
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: 'กรุณากรอกบ้านเลขที่ ถนน ซอยให้เรียบร้อย',
                    icon: 'warning',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            }
            if (Object.keys(benefitlist).length > 0) {
                for (const index in Object.values(benefitlist)) {
                    if (Object.values(benefitlist)[index]['contact_firstname'] === '') {
                        SwalBs.fire({
                            // title: 'ยกเลิกคำสั่ง',
                            html: `กรุณากรอกชื่อผู้รับโอนผลประโยชน์คนที่ ${parseInt(index) + 1} ให้เรียบร้อย`,
                            icon: 'warning',
                            // showCancelButton: true,
                            confirmButtonText: 'รับทราบ',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        });
                        return;
                    }
                    if (benefitlist[index]['contact_lastname'] === '') {
                        SwalBs.fire({
                            // title: 'ยกเลิกคำสั่ง',
                            html: `กรุณากรอกนามสกุลผู้รับโอนผลประโยชน์คนที่ ${parseInt(index) + 1} ให้เรียบร้อย`,
                            icon: 'warning',
                            // showCancelButton: true,
                            confirmButtonText: 'รับทราบ',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        });
                        return;
                    }
                    if (benefitlist[index]['contact_phonenumber'] === '') {
                        SwalBs.fire({
                            // title: 'ยกเลิกคำสั่ง',
                            html: `กรุณากรอกเบอร์โทรศัพท์ติดต่อผู้รับโอนผลประโยชน์คนที่ ${parseInt(index) +
                                1} ให้เรียบร้อย`,
                            icon: 'warning',
                            // showCancelButton: true,
                            confirmButtonText: 'รับทราบ',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        });
                        return;
                    }
                    if (benefitlist[index]['province'] === '') {
                        SwalBs.fire({
                            // title: 'ยกเลิกคำสั่ง',
                            html: `กรุณากรอกจังหวัดผู้รับโอนผลประโยชน์คนที่ ${parseInt(index) + 1} ให้เรียบร้อย`,
                            icon: 'warning',
                            // showCancelButton: true,
                            confirmButtonText: 'รับทราบ',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        });
                        return;
                    }
                    if (benefitlist[index]['district'] === '') {
                        SwalBs.fire({
                            // title: 'ยกเลิกคำสั่ง',
                            html: `กรุณากรอกอำเภอ / เขตผู้รับโอนผลประโยชน์คนที่ ${parseInt(index) + 1} ให้เรียบร้อย`,
                            icon: 'warning',
                            // showCancelButton: true,
                            confirmButtonText: 'รับทราบ',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        });
                        return;
                    }
                    if (benefitlist[index]['subdistrict'] === '') {
                        SwalBs.fire({
                            // title: 'ยกเลิกคำสั่ง',
                            html: `กรุณากรอกตำบล / แขวงผู้รับโอนผลประโยชน์คนที่ ${parseInt(index) + 1} ให้เรียบร้อย`,
                            icon: 'warning',
                            // showCancelButton: true,
                            confirmButtonText: 'รับทราบ',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        });
                        return;
                    }
                    if (benefitlist[index]['postalcode'] === '') {
                        SwalBs.fire({
                            // title: 'ยกเลิกคำสั่ง',
                            html: `กรุณากรอกรหัสไปรษณีย์ผู้รับโอนผลประโยชน์คนที่ ${parseInt(index) + 1} ให้เรียบร้อย`,
                            icon: 'warning',
                            // showCancelButton: true,
                            confirmButtonText: 'รับทราบ',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        });
                        return;
                    }
                }
            }
            // console.log(benefitlist);
            setPage('summary');
        } else if (page === 'summary') {
            if (paymenttype === 'bay-qr') {
                let birthdatelist = birthdate.split('/');
                let newBirthday = birthdatelist[2] + '-' + birthdatelist[1] + '-' + birthdatelist[0];
                let param = {
                    username: personalcode,
                    password: phonenumber,
                    firstname: firstname,
                    lastname: lastname,
                    birthdate: newBirthday,
                    nationality: nationality,
                    contactnumber: contactnumber,
                    address: {
                        address1: address1,
                        // "address2": "XXXX",
                        province: province.value,
                        district: district.value,
                        subdistrict: subdistrict.value,
                        postalcode: zipcode
                    },
                    beneficiary: Object.values(benefitlist),
                    phonenumber: phonenumber,
                    payment_channel: paymenttype
                    // otp: otpcode,
                    // ref: refcode
                };
                register(param);
            } else {
                sendOtp('otppayment');
            }
        } else if (page === 'otppayment') {
            let birthdatelist = birthdate.split('/');
            let newBirthday = birthdatelist[2] + '-' + birthdatelist[1] + '-' + birthdatelist[0];
            let param = {
                username: personalcode,
                password: phonenumber,
                firstname: firstname,
                lastname: lastname,
                birthdate: newBirthday,
                nationality: nationality,
                contactnumber: contactnumber,
                address: {
                    address1: address1,
                    // "address2": "XXXX",
                    province: province.value,
                    district: district.value,
                    subdistrict: subdistrict.value,
                    postalcode: zipcode
                },
                beneficiary: Object.values(benefitlist),
                phonenumber: phonenumber,
                payment_channel: paymenttype,
                otp: otpcode,
                ref: refcode
            };
            register(param);
        }
    };

    const sendOtp = (step) => {
        setLoading(true);
        setOtpcode('');
        setDisableotp(true);
        // setRefcode('');
        // setExpiretime('');
        fetch(config.url + 'sim/otp-generate', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                phonenumber: phonenumber
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                setDisableotp(false);
                if (result.responsecode === 0) {
                    setRefcode(result.refcode);
                    setExpiretime(result.timeout);
                    setPage(step);
                    setDisable(true);
                } else if (result.responsecode === 100) {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `หมายเลข ${phonenumber} ไม่ใช่เบอร์ในโครงการซิมปันสุข ไม่สามารถเข้าร่วมกลุ่มเครดิตยูเนี่ยนครอบครัวซิมปันสุขได้`,
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                } else {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `${result.responsecode}! มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง`,
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                setDisableotp(false);
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    icon: 'error',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                // setInit(true);
            });
    };

    const getInfo = () => {
        setLoading(true);
        fetch(config.url + 'member/info', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                phonenumber: phonenumber,
                otp: otpcode,
                ref: refcode
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                // console.log(result);
                if (result.responsecode === 0) {
                    if (result.profile.name !== '') {
                        setFirstname(result.profile.name.split(' ')[0]);
                        setLastname(result.profile.name.split(' ')[1]);
                    }
                    if (result.profile.national !== '') {
                        if (result.profile.national === 'ไทย') {
                            setNationality('1');
                        } else {
                            setNationality('2');
                        }
                    }
                    if (result.profile.contact_mobile !== '') {
                        setContactnumber(result.profile.contact_mobile);
                    }
                    if (result.profile.id_card !== '') {
                        setPersonalcode(result.profile.id_card);
                    }
                    if (result.profile.province_id !== '') {
                        for (const thisprovince in initaddress.province) {
                            if (initaddress.province[thisprovince]['value'] === result.profile.province_id.toString()) {
                                setProvince(initaddress.province[thisprovince]);
                                let thisDistrict = [];
                                let provinceindex = 0;
                                for (const thisdistrict in initaddress.district) {
                                    if (
                                        initaddress.district[thisdistrict]['provinceid'] ===
                                        result.profile.province_id.toString()
                                    ) {
                                        thisDistrict[provinceindex] = initaddress.district[thisdistrict];
                                        provinceindex += 1;
                                    }
                                }
                                setMaindistrict(thisDistrict);
                                break;
                            }
                        }
                        // setProvince(result.profile.province_id);
                    }
                    if (result.profile.amphur_id !== '') {
                        for (const thisdistrict in initaddress.district) {
                            if (initaddress.district[thisdistrict]['value'] === result.profile.amphur_id.toString()) {
                                setDistrict(initaddress.district[thisdistrict]);
                                let thisSubdistrict = [];
                                let districtindex = 0;
                                for (const thissubdistrict in initaddress.subdistrict) {
                                    if (
                                        initaddress.subdistrict[thissubdistrict]['districtid'] ===
                                        result.profile.amphur_id.toString()
                                    ) {
                                        thisSubdistrict[districtindex] = initaddress.subdistrict[thissubdistrict];
                                        districtindex += 1;
                                    }
                                }
                                setMainsubdistrict(thisSubdistrict);
                                break;
                            }
                        }
                    }
                    if (result.profile.district_id !== '') {
                        for (const thissubdistrict in initaddress.subdistrict) {
                            if (
                                initaddress.subdistrict[thissubdistrict]['value'] ===
                                result.profile.district_id.toString()
                            ) {
                                setSubdistrict(initaddress.subdistrict[thissubdistrict]);
                                break;
                            }
                        }
                    }
                    if (result.profile.zip !== '') {
                        setZipcode(result.profile.zip);
                    }
                    if (result.profile.address !== '') {
                        setAddress1(result.profile.address);
                    }
                } else if (result.responsecode === 101) {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: 'รหัสOTP ที่ส่งไปยังเบอร์ที่ต้องการสมัครสมาชิกไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง',
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                } else if (result.responsecode === 102) {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: 'รหัสOTP ที่ส่งไปยังเบอร์ที่ต้องการสมัครสมาชิกหมดอายุแล้ว กรุณาลองใหม่อีกครั้ง',
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                }
                setPage('form');
            })
            .catch((error) => {
                setLoading(false);
                setPage('form');
                // SwalBs.fire({
                //     // title: 'ยกเลิกคำสั่ง',
                //     html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                //     icon: 'error',
                //     // showCancelButton: true,
                //     confirmButtonText: 'รับทราบ',
                //     allowOutsideClick: false,
                //     allowEscapeKey: false,
                //     allowEnterKey: false
                // });
                // return;
            });
    };

    const register = (param) => {
        setLoading(true);
        fetch(config.url + 'member/register', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify(param)
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                // console.log(result);
                if (result.responsecode === 106) {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: 'รหัสOTP ที่ส่งไปยังเบอร์ที่ต้องการสมัครสมาชิกไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง',
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                } else if (result.responsecode === 107) {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: 'รหัสOTP ที่ส่งไปยังเบอร์ที่ต้องการสมัครสมาชิกหมดอายุแล้ว กรุณาลองใหม่อีกครั้ง',
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                } else if (result.responsecode === 101) {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html:
                            'เบอร์ซิมปันสุขเป็นสมาชิกกลุ่มเครดิตยูเนี่ยนครอบครัวซิมปันสุขแล้ว ไม่สามารถสมัครสมาชิกได้อีก',
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                } else if (result.responsecode === 109) {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: 'ไม่สามารถชำระค่าบริการด้วยกระเป๋าซิมปันสุขได้ กรุณาลองใหม่ด้วยคิวอาร์โค้ด',
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            setPaymenttype('bay-qr');
                            // setDisablewallet(true);
                            setPage('summary');
                        }
                    });
                    // return;
                } else if (result.responsecode === 0) {
                    setRegisterres(result);
                    setPage('result');
                } else {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `${result.responsecode}! มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง`,
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                }
                // setPage('result');
            })
            .catch((error) => {
                setLoading(false);
                // console.log(error);
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    icon: 'error',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            });
    };

    const Completionist = () => {
        return (
            <div className='row justify-content-center col-12 p-0 m-0'>
                <button className='btn btn-primary rounded-lg' onClick={(e) => sendOtp(page)} disabled={disableotp}>
                    ขอ OTP ใหม่
                </button>
            </div>
        );
    };

    const renderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            // Render a complete state
            // ("2020-01-02 11:00:00");
            return <Completionist />;
        } else {
            // Render a countdown
            return (
                <div className='row col-12 p-0 m-0 text-center'>
                    <p className='col-12 m-0'>กรุณาลองใหม่ในอีก</p>
                    <span className='col-12'>
                        {(minutes < 10 ? '0' : '') + minutes}:
                        {(seconds < 10 ? '0' : '') + seconds}
                    </span>
                </div>
            );
        }
    };

    return (
        <div
            className={`login-form login-signin d-block p-15 bg-white rounded-lg ${page === 'summary'
                ? ' max-w-800px'
                : 'max-w-600px'}${page === 'terms' ? ' pt-30' : ''}`}
        >
            <div className='text-center mb-10 mb-lg-20'>
                <h3 className='font-size-h1'>แบบฟอร์มใบสมัครสมาชิก</h3>
                <p className='text-muted font-weight-bold'>กลุ่มเครดิตยูเนี่ยนครอบครัวซิมปันสุข</p>
            </div>

            <div className='mb-10'>
                {page === 'terms' ? (
                    <RegisterStep1 agreement={agreement} setAgreement={setAgreement} />
                ) : page === 'punsooknumberstep' ? (
                    <RegisterStepPhone
                        phonenumber={phonenumber}
                        setPhonenumber={setPhonenumber}
                        setRefcode={setRefcode}
                    />
                ) : page === 'otpinfo' ? (
                    <RegisterStepOtp
                        loading={loading}
                        otpcode={otpcode}
                        refcode={refcode}
                        expiretime={expiretime}
                        handleOtp={handleOtp}
                        renderer={renderer}
                    />
                ) : page === 'form' ? (
                    <RegisterStepForm
                        firstname={firstname}
                        setFirstname={setFirstname}
                        lastname={lastname}
                        setLastname={setLastname}
                        birthdate={birthdate}
                        setBirthdate={setBirthdate}
                        nationality={nationality}
                        setNationality={setNationality}
                        personalcode={personalcode}
                        setPersonalcode={setPersonalcode}
                        contactnumber={contactnumber}
                        setContactnumber={setContactnumber}
                        address1={address1}
                        setAddress1={setAddress1}
                        address2={address2}
                        setAddress2={setAddress2}
                        mainprovince={mainprovince}
                        setMainprovince={setMainprovince}
                        province={province}
                        setProvince={setProvince}
                        maindistrict={maindistrict}
                        setMaindistrict={setMaindistrict}
                        district={district}
                        setDistrict={setDistrict}
                        mainsubdistrict={mainsubdistrict}
                        setMainsubdistrict={setMainsubdistrict}
                        subdistrict={subdistrict}
                        setSubdistrict={setSubdistrict}
                        zipcode={zipcode}
                        setZipcode={setZipcode}
                        initBenefitList={initBenefitList}
                        benefitlist={benefitlist}
                        setBenefitlist={setBenefitlist}
                    />
                ) : page === 'summary' ? (
                    <RegisterStepSummary
                        btnNext={btnNext}
                        firstname={firstname}
                        lastname={lastname}
                        birthdate={birthdate}
                        contactnumber={contactnumber}
                        nationality={nationality}
                        personalcode={personalcode}
                        province={province}
                        district={district}
                        subdistrict={subdistrict}
                        zipcode={zipcode}
                        address1={address1}
                        setAddress1={setAddress1}
                        paymenttype={paymenttype}
                        setPaymenttype={setPaymenttype}
                        disablewallet={disablewallet}
                    />
                ) : page === 'otppayment' ? (
                    <RegisterStepOtp2
                        loading={loading}
                        otpcode={otpcode}
                        refcode={refcode}
                        expiretime={expiretime}
                        handleOtp={handleOtp}
                        renderer={renderer}
                    />
                ) : page === 'result' ? (
                    <RegisterStepResult registerres={registerres} paymenttype={paymenttype} />
                ) : null}
            </div>

            <div className='form-group d-flex flex-wrap flex-center'>
                {page === 'terms' && (
                    <Link to='/auth/login'>
                        <button
                            type='button'
                            className='btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4 rounded-lg'
                        >
                            ยกเลิก
                        </button>
                    </Link>
                )}
                {page === 'terms' && (
                    <button
                        type='button'
                        className='btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4 rounded-lg'
                        onClick={btnNext}
                        disabled={!agreement}
                    >
                        <span>ถัดไป</span>
                        {/* {loading && <span className="ml-3 spinner spinner-white"></span>} */}
                    </button>
                )}
                {page !== 'terms' &&
                page !== 'summary' &&
                page !== 'result' && (
                    <button
                        type='button'
                        className='btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4 rounded-lg'
                        onClick={btnNext}
                        disabled={disable}
                    >
                        <span>ถัดไป</span>
                        {/* {loading && <span className="ml-3 spinner spinner-white"></span>} */}
                    </button>
                )}
            </div>

            {loading && (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
