import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import Swal from 'sweetalert2/dist/sweetalert2.js';

import { CashWithdrawForm, CashWithdrawOtp, CashWithdrawResult } from './components';

import config from '../../../../config';

const SwalBs = Swal.mixin({
    customClass: {
        popup: 'rounded-lg',
        confirmButton: 'btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto',
        cancelButton: 'btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto'
    },
    buttonsStyling: false
});

export function CashWithdrawMain() {
    const { info } = useSelector((state) => state.auth);

    const [ loading, setLoading ] = useState(false);
    const [ btnloading, setBtnLoading ] = useState(false);
    const [ page, setPage ] = useState('main');
    const [ banklist, setBankList ] = useState({});
    const [ amount, setAmount ] = useState(0);
    const [ withdrawres, setWithdrawRes ] = useState({});

    const [ refcode, setRefcode ] = useState('');
    const [ otpcode, setOtpcode ] = useState('');
    const [ disable, setDisable ] = useState(true);
    const [ expiretime, setExpiretime ] = useState('');

    const handleOtp = (code) => {
        setOtpcode(code);
        if (code.length >= 6) {
            setDisable(false);
        } else {
            setDisable(true);
        }
    };

    const Completionist = () => {
        return (
            <div className='row justify-content-center col-12 p-0 m-0'>
                <button className='btn btn-primary rounded-lg' onClick={generateOTP}>
                    ขอ OTP ใหม่
                </button>
            </div>
        );
    };

    const renderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            // Render a complete state
            // ("2020-01-02 11:00:00");
            return <Completionist />;
        } else {
            // Render a countdown
            return (
                <div className='row col-12 p-0 m-0 text-center'>
                    <p className='col-12 m-0'>กรุณาลองใหม่ในอีก</p>
                    <span className='col-12'>
                        {(minutes < 10 ? '0' : '') + minutes}:
                        {(seconds < 10 ? '0' : '') + seconds}
                    </span>
                </div>
            );
        }
    };

    const btnNext = () => {
        if (page === 'main') {
            if (amount === 0) {
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: 'กรุณากรอกจำนวนเงินให้เรียบร้อย',
                    icon: 'warning',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return false;
            } else {
                setBtnLoading(true);
                fetch(config.url + 'account/get-balance', {
                    method: 'post',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                        // secret: config.secret,
                    },
                    body: JSON.stringify({
                        token: info.info.token
                    })
                })
                    .then((response) => response.json())
                    .then((result) => {
                        setBtnLoading(false);
                        if (result.responsecode === 0) {
                            let status = 'not found';
                            for (const key in result.account) {
                                if (result.account[key]['type'] === '4') {
                                    if (result.account[key]['balance'] >= amount) {
                                        status = 'true';
                                    } else {
                                        status = 'false';
                                    }
                                }
                            }
                            if (status === 'not found') {
                                SwalBs.fire({
                                    // title: 'ยกเลิกคำสั่ง',
                                    html: 'ยังไม่มีกระเป๋าเงินไม่สามารถทำรายการได้',
                                    icon: 'warning',
                                    // showCancelButton: true,
                                    confirmButtonText: 'รับทราบ',
                                    allowOutsideClick: false,
                                    allowEscapeKey: false,
                                    allowEnterKey: false
                                });
                                return false;
                            } else if (status === 'false') {
                                SwalBs.fire({
                                    // title: 'ยกเลิกคำสั่ง',
                                    html: 'จำนวนเงินที่มีไม่เพียงพอกับยอดที่ต้องการถอน',
                                    icon: 'warning',
                                    // showCancelButton: true,
                                    confirmButtonText: 'รับทราบ',
                                    allowOutsideClick: false,
                                    allowEscapeKey: false,
                                    allowEnterKey: false
                                });
                                return false;
                            } else {
                                generateOTP();
                            }
                        } else {
                            SwalBs.fire({
                                // title: 'ยกเลิกคำสั่ง',
                                html: 'ไม่สามารถตรวจสอบจำนวนเงินที่มีได้ กรุณาลองใหม่อีกครั้ง',
                                icon: 'warning',
                                // showCancelButton: true,
                                confirmButtonText: 'รับทราบ',
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                                allowEnterKey: false
                            });
                            return false;
                        }
                    })
                    .catch((error) => {
                        setBtnLoading(false);
                        SwalBs.fire({
                            // title: 'ยกเลิกคำสั่ง',
                            html: 'ไม่สามารถตรวจสอบจำนวนเงินที่มีได้ กรุณาลองใหม่อีกครั้ง',
                            icon: 'error',
                            // showCancelButton: true,
                            confirmButtonText: 'รับทราบ',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        });
                        return false;
                    });
            }
        } else if (page === 'otp') {
            withdrawCash();
        }
    };

    useEffect(() => {
        getBank();
    }, []);

    const checkAmount = (amount) => {
        console.log(amount);
        setBtnLoading(true);
        fetch(config.url + 'account/get-balance', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: info.info.token
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setBtnLoading(false);
                if (result.responsecode === 0) {
                    let status = 'not found';
                    for (const key in result.account) {
                        console.log(status);
                        if (result.account[key]['type'] === '4') {
                            console.log(result.account[key]['balance']);
                            if (result.account[key]['balance'] >= amount) {
                                status = 'true';
                            } else {
                                status = 'false';
                            }
                        }
                    }
                    console.log(status);
                    return status;
                } else {
                    return 'error';
                }
            })
            .catch((error) => {
                setBtnLoading(false);
                return 'strong error';
            });
    };

    const getBank = () => {
        setLoading(true);
        fetch(config.url + 'bank/bank-list', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: info.info.token
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    let initbanklist = {};
                    for (const index in result.bank) {
                        initbanklist[result.bank[index]['bank_code']] = result.bank[index]['bank_name'];
                    }
                    setBankList(initbanklist);
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const generateOTP = () => {
        // console.log(info.password);
        setLoading(true);
        setOtpcode('');
        fetch(config.url + 'sim/otp-generate', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                phonenumber: info.password
                // phonenumber: '0976954777'
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    setRefcode(result.refcode);
                    setExpiretime(result.timeout);
                    setPage('otp');
                    setDisable(true);
                } else {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `${result.responsecode}! มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง`,
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    icon: 'error',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                // setInit(true);
            });
    };

    const withdrawCash = () => {
        setLoading(true);
        fetch(config.url + 'account/withdraw-bank', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: info.info.token,
                balance: amount,
                // phonenumber: '66' + info.password.slice(1),
                phonenumber: info.password,
                otp: otpcode,
                ref: refcode
                // phonenumber: '0976954777'
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                setWithdrawRes(result);
                setPage('result');
            })
            .catch((error) => {
                setLoading(false);
                setPage('result');
                // setInit(true);
            });
    };

    return (
        <div>
            {loading ? (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    {page === 'main' ? (
                        <CashWithdrawForm
                            info={info}
                            btnloading={btnloading}
                            banklist={banklist}
                            amount={amount}
                            setAmount={setAmount}
                            btnNext={btnNext}
                        />
                    ) : page === 'otp' ? (
                        <CashWithdrawOtp
                            otpcode={otpcode}
                            refcode={refcode}
                            expiretime={expiretime}
                            handleOtp={handleOtp}
                            renderer={renderer}
                            disable={disable}
                            btnNext={btnNext}
                        />
                    ) : page === 'result' ? (
                        <div className='row h-550px'>
                            <div className='col-12 my-auto'>
                                <CashWithdrawResult withdrawres={withdrawres} />
                            </div>
                        </div>
                    ) : null}
                </div>
            )}
        </div>
    );
}
