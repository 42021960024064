import React, { useEffect } from 'react';

import pdfMake from 'pdfmake/build/pdfmake';

import pdfFonts from 'pdfmake/build/vfs_fonts';

import { Pdf } from './components';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
    THSarabunNew: {
        normal: 'THSarabunNew.ttf',
        bold: 'THSarabunNew-Bold.ttf',
        italics: 'THSarabunNew-Italic.ttf',
        bolditalics: 'THSarabunNew-BoldItalic.ttf'
    },
    Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-MediumItalic.ttf'
    }
};

const mockupData = [ '1' ];

export function StockBillMain() {
    useEffect(() => {
        genaratePdf();
    }, []);

    const genaratePdf = () => {
        let docDefinition = new Pdf(mockupData);
        var doc = pdfMake.createPdf(docDefinition.pdfContent());
        var f = document.getElementById('pdfview');
        var callback = function(url) {
            f.setAttribute('src', url);
        };
        doc.getDataUrl(callback, doc);
        // this.state.pdfdata = docDefinition.pdfContent();
    };

    return (
        <div className='row m-0'>
            <div className='col-12 p-0'>
                <iframe className='w-100 vh-100' title='invoice' id='pdfview' />
            </div>
        </div>
    );
}
